import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate ,useParams} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import Swal from "sweetalert2";
import moment from 'moment';
import { requestObject } from "../../../../utility/requestObject";
import services from "../../../../services/services";
import DatePicker from "react-datepicker";
import { SelectAllinspections } from "../../customer/CustomerContactList";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const UpdateUpcomingAndoverdue=({Id,vendorId,vehicleId,originalDate,setOriginalDate,inspectiondisable,notes,inspectionNames,setInspecchecked})=>
{
    
    var inspectionArray = [];
    var inspectionData = [];
    var inspect = {};
    var inspectionJson = [];
    
   const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });
    const closeModalPopup = () => {

        document.getElementById("closeModal").click();
    }
    const cancelassign_Garage = () => {
        document.getElementById("closeModal").click();
     
      

        Swal.fire({
            title: 'Do you really want to cancel the order?',
            position: 'center',
             icon: 'error',
             confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
             showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
         }).then(function (results) {
            // Called if you click Yes.
            if (results.isConfirmed) {
                var vechileReq =
                {
                    "id":Id,
                    "vendorId": vendorId,
        
        
                }
                $("#loadingforsubmit").show();
        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.cancelAssignToGarage(requestOptions).then((res) => 
        {
            $("#loadingforsubmit").hide();
            if (res.success) {

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: res.message==="Successfully cancelled assigned Vehicle"?"Successfully cancelled the order":res.message

                }).then((result) => {

                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: res.message

                }).then((result) => {

                    if (result.isConfirmed) {
                    }
                });
            }

        });
    } 
    },
    function (no) {
       // Called if you click No.
       if (no == 'cancel') {
        $("#loadingforsubmit").hide();
       }
    });
    }
    const validateDate = () => {
        let form_is_valid = true;


        var workOrder = $("#updateworkorder").val();
        if (workOrder == '') {
            $('#error-workOrderMessage').html("Work order number is required");
            $("#updateworkorder").focus();
            form_is_valid = false;
        } else {
            $('#error-workOrderMessage').html('');
        }

        // var garage = $("#garageName").val();
        // if (garage == '') {
        //     $('#error-message-garage').html("Vendor is required");
        //     $("#garageName").focus();
        //     form_is_valid = false;
        // } else {
        //     $('#error-message-garage').html('');
        // }

        var assignDate = $("#assignDate").val();
        if (assignDate == '') {
            $('#error-message').html("Assign date is required");
            $("#assignDate").focus();
            form_is_valid = false;
        } else {
            $('#error-message').html('');
        }



        // $('input:checkbox[name=inspectionIds]:checked').each(function () {
        //     // alert($(this).next('span').text());
        //     inspectionArray.push($(this).val());
        //     inspectionData.push($(this).next('span').text());
        //     // inspectionData.push($(this).text().toString())
        //     inspect.id = $(this).val();
        //     inspect.name = $(this).next('span').text().split(' ').join(' ').toString();
        //     // inspect.name = $(this).next("label").text().split(' ').join(' ').toString();
        //     inspectionJson.push({ ...inspect });

        // });

        /////////////////////Canceled by customer function/////////////////
        var count = 0;
        $('input:checkbox[name=inspectionIds]:checked').each(function () {

            // console.log(JSON.stringify(inspectiondisable))
            // console.log(inspectionNames[count]);
            // console.log("compare -"+ $(this).next('span').text());

            inspectionArray.push($(this).val());
            inspectionData.push($(this).next('span').text());
            // inspectionData.push($(this).text().toString())
            // if(inspectiondisable[count].name.match($(this).next('span').text())){
            if (inspectionNames[count] == $(this).next('span').text()) {

                inspect.id = $(this).val();
                inspect.name = $(this).next('span').text().split(' ').join(' ').toString();
          
                inspectionJson.push({ ...inspect });
            } else if (inspectionNames[count] != $(this).next('span').text()) {
                inspect.id = $(this).val();
                inspect.name = $(this).next('span').text();
     
                inspectionJson.push({ ...inspect });
            }
            else {
                if (inspectionNames[count] == undefined) {
                    inspect.id = $(this).val();
                    inspect.name = $(this).next('span').text();
            
                    inspectionJson.push({ ...inspect });
                } else {
                    inspect.id = $(this).val();
                    inspect.name = inspectionNames[count];
                
                    inspectionJson.push({ ...inspect });
                }
            }
            count++;
        });
        /////////////////////Ending Canceled by customer function/////////////////






        if (inspectionArray.length > 0) {

            // $('#error-messageIn').html('');
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please choose the atleast one inspection",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {
                }
            });
            // $('#error-messageIn').css("color", "#d33232").html("*Inspection is required");
            form_is_valid = false;
        }



        return form_is_valid;
    }
    const submitValue = () => {

        if (validateDate()) {


            closeModalPopup();
            $("#loadingforsubmit").show();

            var vechileReq =
            {
                "id": Id,

                "vendorId": vendorId,
                "vehicleId": vehicleId,
                // "inspectionIdList":[1],
                "inspectionIdList": inspectionArray,
               // "inspectionData": JSON.stringify(inspectionJson),
                "workOrderNumber": $("#updateworkorder").val() != '' ? $("#updateworkorder").val() : '',
                "assignDate": originalDate != '' ? moment(originalDate).format('YYYY-MM-DD') : '',
                "notes": $("#notes").val() != '' ? $("#notes").val() : ''



                //    "imageName":cropper.getCroppedCanvas()?parseInt(Math.random() * 1000000000):"",
                //    "imageExtension":cropper.getCroppedCanvas()?"png":"",
                //    "imageByteString":cropper.getCroppedCanvas()?cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", ""):"",
                //    "documentList": documentList
            }

            var requestOptions = requestObject.RequestHeader(vechileReq);
            services.Updateassigntogarage(requestOptions).then((res) => {
                $("#loadingforsubmit").hide();
                if (res.message == "Successfully assigned Vehicle") {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                } else {

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                        }
                    });

                }

            });

        }

    }
    const cancel = () => {
    setInspecchecked([]);
    }
    return(<>
       <div id="updateModal" class="modal fade" role="dialog">
                    <div class="modal-dialog">

                        <form onSubmit={handleSubmit(submitValue)}>
                            <div class="modal-content" style={{ maxHeight: "550px" }}>

                                <div class="modal-header">
                                    <h5 class="modal-title">Update</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => (closeModalPopup())}><span aria-hidden="true">×</span></button>
                                </div>

                                <div class="modal-body scroll-auto">

                                    <div className="row">
                                        <div class="col-md-12">

                                            <div class="form-group mb-3">

                                                <img src="images/icons/workorder-icon.svg" alt="" />
                                                {/* <span class="mr-2 ml-2">Work order Number : </span> */}
                                                <label for="wqes" class="w-100">Work order Number <span class="star">*</span></label>
                                                <input type="input" class="form-control" placeholder="Enter W/O" id="updateworkorder" />
                                                <span id="error-workOrderMessage" class="error"></span>
                                            </div>
                                            <div class="form-group mb-3">

                                                <label>Vendor Name</label>
                                                {/* <AutoSuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsClearRequested={() => setSuggestions([])}
                                                    onSuggestionsFetchRequested={({ value }) => {

                                                        setValue(value ? value : "");
                                                        setSuggestions(getSuggestions(value));
                                                    }}
                                                    onSuggestionSelected={(_, { suggestion }) =>
                                                        setgarageId(suggestion.id)

                                                    }
                                                    getSuggestionValue={suggestion => suggestion.name}
                                                    renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                                                    inputProps={{
                                                        placeholder: "",
                                                        value: value,
                                                        onChange: (_, { newValue, method }) => {
                                                            setValue(newValue);
                                                        }
                                                    }}
                                                    highlightFirstSuggestion={true}
                                                    readOnly={true}
                                                /> */}
                                                <input type="input" class="form-control" placeholder="Garage Name" id="garageName" disabled />

                                                <span id="error-message-garage"></span>
                                                {/* <ReactSearchAutocomplete
                                                                items={listVendors}
                                                                fuseOptions={{ keys: ["id", "name"] }} // Search on both fields
                                                                resultStringKeyName="name" // String to display in the results
                                                                onSearch={handleOnSearch}
                                                                onHover={handleOnHover}
                                                                onSelect={handleOnSelect}
                                                                onFocus={handleOnFocus}

                                                                showIcon={false}
                                                                styling={{
                                                                    height: "34px",
                                                                    // border: "1px solid darkgreen",
                                                                    borderRadius: "4px",
                                                                    // backgroundColor: "white",
                                                                    boxShadow: "none",
                                                                    // hoverBackgroundColor: "lightgreen",
                                                                    // color: "darkgreen",
                                                                    fontSize: "12px",
                                                                    fontFamily: "Montserrat",
                                                                    iconColor: "black",
                                                                    // lineColor: "lightgreen",
                                                                    // placeholderColor: "darkgreen",
                                                                    clearIconMargin: "3px 8px 0 0",
                                                                    zIndex: 2,
                                                                }}
                                                            /> */}

                                                {/* {errors.vendorId && errors.vendorId.type === "required" && <span class="error">Vendor  is required</span>} */}
                                                {/* <a href="#" style={{ color: "#022e5f", marginTop: "10px", textDecoration: "underline", float: "right", margin: ".25rem 0" }} data-toggle="modal" data-target="#addGarrage"
                                                                onClick={() =>
                                                                    addNewGarage()
                                                                }>Add New Garage</a> */}

                                            </div>
                                            <div class="form-group mb-3">
                                                <label for="wqes" class="w-100">Assign Date <span class="star">*</span></label>
                                                {/* <input type="text" class="form-control" id="qwes"  defaultValue={orderDate}/> */}
                                                <DatePicker id="assignDate" minDate={moment(originalDate ? originalDate : "").toDate()} selected={originalDate ? originalDate : new Date()} onChange={(date) => setOriginalDate(date)} name="originalDate" class="form-control" id="originalDate" placeholderText="MM/DD/YYYY" />
                                                {/* <span class="error" id="originDateError">Assign date is required</span> */}

                                                <br />
                                            </div>
                                            <div class="form-group">
                                                <label for="wqes" class="w-100">Inspection List <span class="star">*</span></label>
                                                <div class="col-md-12" style={{ marginTop: "16px" }}><input type="checkbox" class="form-check-input" name="contactSelectAll" onClick={(e) => SelectAllinspections(e, "")} />
                                                    <label style={{ marginLeft: "26px", fontWeight: "bold", marginBottom: "0px" }}   >Select All</label></div>

                                                {

                                                    inspectiondisable ? inspectiondisable.sort((a, b) => a.name.localeCompare(b.name)).map((inspection, index) => {

                                                        return (<>

                                                            {/* {
                                                                inspectionNames.includes(inspection.name) ?
                                                                    <div class="form-check rem-shadow"> <div class="col-md-12"><input type="checkbox" class="form-check-input " defaultChecked={inspection.inspectionStatus==="Cancelled"?false:true} value={inspection.id} name="inspectionIds"  /><span class="form-check-label checkedinspectionNames">{inspection.name}</span></div> </div> :
                                                                    <div class="form-check rem-shadow"> <div class="col-md-12"><input type="checkbox" defaultChecked={false} class="form-check-input " name="inspectionIds" value={inspection.id}  /><span class="form-check-label checkedinspectionNames">{inspection.name}</span></div></div>
                                                            } */}
                                                            {(() => {



if (inspection.name === "WET Test Certification") {
    if (sessionStorage.getItem("vehicleTypeName") === "Trailer" && sessionStorage.getItem("tankerSubTypeName") === "Tanker") {

        return (
            inspectionNames.includes(inspection.name) ?
            <div class="form-check rem-shadow"> <div class="col-md-12"><input type="checkbox" class="form-check-input " defaultChecked={inspection.inspectionStatus==="Cancelled"?false:true} value={inspection.id} name="inspectionIds"  /><span class="form-check-label checkedinspectionNames">{inspection.name}</span></div> </div> :
            <div class="form-check rem-shadow"> <div class="col-md-12"><input type="checkbox" defaultChecked={false} class="form-check-input " name="inspectionIds" value={inspection.id}  /><span class="form-check-label checkedinspectionNames">{inspection.name}</span></div></div>

        )
    } else {

    }

} else {
    return (
        inspectionNames.includes(inspection.name) ?
        <div class="form-check rem-shadow"> <div class="col-md-12"><input type="checkbox" class="form-check-input " defaultChecked={inspection.inspectionStatus==="Cancelled"?false:true} value={inspection.id} name="inspectionIds"  /><span class="form-check-label checkedinspectionNames">{inspection.name}</span></div> </div> :
        <div class="form-check rem-shadow"> <div class="col-md-12"><input type="checkbox" defaultChecked={false} class="form-check-input " name="inspectionIds" value={inspection.id}  /><span class="form-check-label checkedinspectionNames">{inspection.name}</span></div></div>

    )
}

})()}
                                                        </>)

                                                    }) : ""}
                                                <span id="error-messageIn" class="error"></span>


                                                <br />
                                                <label for="addnotes" className="mt-2">Add Note </label>

                                                <textarea id="notes" name="addnotes" rows="4" cols="50" maxLength="300" defaultValue={notes}>

                                                </textarea>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="modal-footer px-3">
                                    <button type="button" class="btn btn-danger border mr-auto" onClick={cancelassign_Garage}>Cancel Order</button>
                                    <button type="button" class="btn btn-secondary border" data-dismiss="modal" id="closeModal" onClick={cancel} >Cancel</button>
                                    <button type="submit" class="btn btn-primary"  >Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
    </>)
}

UpdateUpcomingAndoverdue.propTypes = {};

UpdateUpcomingAndoverdue.defaultProps = {};

export default UpdateUpcomingAndoverdue;