import React, { history, useEffect, useState, useMemo } from "react";
import Search from "../../../utility/search";
import Tableheader from "../../../utility/tableheader";
import Pagination from "../../../utility/pagination";
import services from "../../../services/services";
import GetVehicleType from "../../../utility/getvehicleType";
import Getcontactrole from "../../../utility/getcontactrole";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import $ from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import { useLoading } from 'react-hook-loading';
import contactTab from "./contactTab";
import { useSelector, useDispatch } from 'react-redux';
import customerVehicle from "../vehicle/customer_vehicle";
import { fetchCustomer_List } from "./store/actions";
// import customer_doc_api from "../document/customer_doc_api";
import Getstates from "../../../utility/getstates";
import customer_doc_api from "../document/customer_doc_api";
import { loadingComponent } from "../../../utility/Loading";
import { faxvalidation } from "../../../utility/validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import moment from 'moment'
import getCroppedImg from "../../../utility/cropImage";
import { requestObject } from "../../../utility/requestObject";
import CheckAuthentication from "../../../utility/checkAuthentication";
import { Sendtocustomer } from "../../../utility/sendtocustomer";
import { sendtocustomerCheck } from "../../../utility/sendtocustomer";
import Checkbox from "../../../utility/Checkbox";
import { KeyboardReturnRounded, LeakRemoveTwoTone } from "@material-ui/icons";
import CustomerContactList from "./CustomerContactList";
import ListOfContacts from "./CustomerContactList";
import { CustomercontactId } from "./CustomerContactList";
import { SelectAllContactList } from "./CustomerContactList";
import isURL from 'validator/lib/isURL';
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import { document_details } from "../../../utility/DocumentDetails";
import { Manager, Owner } from "../../constant";
import { deleteDocuments } from "../../../utility/sendtocustomer";
import AddRemoveInputField from "../../../utility/AddRemoveInputField";
import ToggleSwitch from "../ToggleSwitch";
import { LoadingComponentfordocuments } from "../../../utility/LoadingForDoc";
const CustomerDetails = () => {

   const [states, setState] = useState([]);
   const [emailforsendtoct, setInputEmailList] = useState([{}]);
   const inlineCss =
   {
      textAlign: "left"
   }
   const inlinepr15 =
   {
      paddingRight: "15px",
      whiteSpace: "break-spaces"
   }
   const inlinepr10 =
   {
      paddingRight: "10px",
      // whiteSpace: "break-spaces"
   }
   const psA = {
      position: "relative",
      top: 0,
      // borderBottom: 0,
      maxHeight: 35,
      padding: "0px !important",
      textAlign: "center"

   }
   const [roles, setRoles] = useState("");
   const [loading, setLoading] = useLoading("Please wait...");
   const { id } = useParams();
   const [customerDetails, setcustomerDetails] = useState();
   const [vehicleType, setvehicles] = useState();

   var [sucessMessage, setCallBackMessage] = useState("");
   const [roleslected, setSelectedRole] = useState([]);
   const [phoneNumber2, setPhoneNumber] = useState([]);
   const [itemName, setFieldName] = useState('');
   const dispatch = useDispatch();
   const [vechileLists, setVehicleList] = useState([]);
   const [listNotes, setNotes] = useState([]);
   const [totalRecors, setTotalRecord] = useState([]);
   const [totalDocsRecors, setTotalDocsRecord] = useState();
   const [contactRole, setContactRole] = useState([]);
   const [totalItems, setTotalItems] = useState(0);
   const [totalDocsItems, setTotalDocsItems] = useState(0);
   const [currentPage, setCurrentPage] = useState(5);
   const [currentDocsPage, setCurrentDocsPage] = useState(5);
   const [totalpages, settotalPages] = useState();
   const [totaldocspages, settotalDocsPages] = useState();
   const [displayPageForNote, setNotedisplayPage] = useState([]);
   const [displayPage, setdisplayPage] = useState([]);
   const [displayDocsPage, setdisplayDocsPage] = useState([]);
   const [contactLists, setContactList] = useState([]);
   const [editcontactId, setContactId] = useState([]);
   const [primaryContactList, setContactPrimary] = useState([]);
   const [vehicleTypeId, setVehicleTypes] = useState("");
   const [selectedOption, setSelectedOption] = useState("");
   const [vehicleName, setVehicleName] = useState("");
   const [ITEMS_CONTACT_PER_PAGE, setcontactitemperpage] = useState(25);
   const [currentNotPage, setitemperpageNotes] = useState(1);
   const [totalPageNotes, settotalNotesPage] = useState(25);
   const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
   const [ITEMS_PER_PAGE_NOTES, setitemperpagenotes] = useState(25);
   const [ITEMS_PER_PAGE1, setitemperpage1] = useState(25);
   const [ITEMS_PER_PAGE_DOCS, setitemperdocspage] = useState(25);
   const [documentList, setDocumentList] = useState([]);
   const [CustomerContactList, setCustomerContactList] = useState([]);
   const [addressLine2Value, setaddressLine2Value] = useState([]);
   const [stateIdC, setStateIdC] = useState([]);
   const [checked, setChecked] = useState([]);
   const [image, setImage] = useState();
   const [cropper, setCropper] = useState();
   const [cropData, setCropData] = useState("");
   const [sortingField, setSortingField] = useState("");
   const [sortingOrder, setSortingOrder] = useState("");
   const [totalContactItems, setTotalContactItem] = useState([]);
   const [currentContactPage, setContactCurrentPage] = useState(1);
   const [contactNames, setContactName] = useState("");
   const [contactRoleIds, setContactRoleId] = useState("");
   const [inactiveContact, setInactive] = useState("true");
   const [val, setVal] = useState('');
   const [err, setErr] = useState('');
   const [extension, setfileExtension] = useState("");
   const [inactiveStatus, setInactiveStatus] = useState(true);

   var hour_format=localStorage.getItem('time_format_os');

   $("#faxerror").hide();

   $(document).ready(function () {
      $('#zipcode_e,#yourphone2,#contcat_deskphone,#contact_mobile,#edit_phone,#edit_mobile,#emailAddress,#websiteURL').keypress(function( e ) {
         if(e.which === 32){
             return false;
         }
     });

      $("#zipcode_e").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
         }

      });
   });

   const docheaders =
      [
         { name: <Checkbox from='global' />, field: "documentId", inlineStyle: psA },
         { name: "Type", field: "Doc category", sortable: true, inlineStyle: inlineCss },
         { name: "Title", field: "Title", sortable: true, inlineStyle: inlineCss },
         //  { name: "Doc category", field: "Doc category",sortable: true,inlineStyle:inlineCss },
         // { name: "Doc Date", field: "Document Date", sortable: true, inlineStyle: inlineCss, inlineStyle: inlinepr15 },
         { name: "Expiry Date", field: "Expiry Date", sortable: true, inlineStyle: inlineCss, inlineStyle: inlinepr10 },
         { name: "Sent to Customer", field: "Unit Number", sortable: false, inlineStyle: inlineCss },
         { name: "Actions" }
      ];
   const getCropData = () => {
      if (typeof cropper !== "undefined") {
         setCropData(cropper.getCroppedCanvas().toDataURL());
         document.getElementById("btnclose").click();
      }
   };
   const onSortingChangeVehicle = (field) => {
      const order =
         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

      setSortingField(field);
      setSortingOrder(order);
      // vehicle_api("1","1",ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder);
      customerVehicle(id, currentPage, ITEMS_PER_PAGE, vehicleName, vehicleTypeId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, field ? field : "", order, "",inactiveStatus);

   };
   const contactList_store = useSelector(state => state.customerList);
   const navigate = useNavigate();
   // const onChange = (e) => {
   //    e.preventDefault();
   //    let files;
   //    if (e.dataTransfer) {
   //       files = e.dataTransfer.files;
   //    } else if (e.target) {
   //       files = e.target.files;
   //    }
   //    const reader = new FileReader();
   //    reader.onload = () => {
   //       setImage(reader.result);
   //    };
   //    reader.readAsDataURL(files[0]);
   // };

      // function activeClient(){
         const activeCustomer = (status) =>
         {
            // var active = $("#activeClient").val();
            // if ($('#activeClient').prop('checked',true)) {
               var request =
               {

                  "isActive": status,
                  "id": id,


               };

            //let checkState = $("#activeClient").is(":checked") ? "true" : "false";
            Swal.fire({
               title: 'Do you really want to change the status of this customer?',
               // text: unitNumber+" : "+title,

               icon: 'error',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: 'Yes',
               cancelButtonText: 'No'
           }).then(function (results) {
               // Called if you click Yes.
               if(results.isConfirmed) {





                  // setLoading(true);
                  $("#loadingforsubmit").show();
                 var requestOptions = requestObject.RequestHeader(request);
                  services.inActivateCustomer(requestOptions).then((res) =>
                  {
                     // setLoading(false);
                     $("#loadingforsubmit").hide();
                     if (res.success) {
                        Swal.fire({
                           position: 'center',
                           icon: 'success',
                           title: "",
                           text: res.message,

                           showConfirmButton: true

                        }).then((result) =>
                        {
                           if (result.isConfirmed)
                           {
                             // window.location.reload();
                              // IfitIsCustomer();
                              navigate("/customer");
                           }
                        });

                     } else {

                        Swal.fire({
                           position: 'center',
                           icon: 'error',
                           title: "",
                           text: res.message,

                           showConfirmButton: true

                        }).then((result) =>
                        {
                           if (result.isConfirmed)
                           {

                           }
                        });

                     }

                  });

               }
           },
           function (no) {
               // Called if you click No.
               if (no == 'cancel') {

               }
           });


         }
   const onChange = (e) => {
      const MIN_FILE_SIZE = 5120 // 5MB
      const fileSizeKiloBytes=Math.floor(e.target.files[0].size/1024); //

      if(fileSizeKiloBytes <= MIN_FILE_SIZE){
      e.preventDefault();
      let files;
      var ext = e.target.value.split('.').pop();
      var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

      if (!allowedExtensions.exec(ext)) {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Invalid file type",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {

               e.target.value = '';
               return false;
            }
         });

      } else {
         $('#selectedImage').show();
         $('#DB_Image').hide();
         // uploadImageDisable
         $('#uploadImageDisable').prop('disabled', false);
         if (e.dataTransfer) {
            files = e.dataTransfer.files;
         } else if (e.target) {
            files = e.target.files;
         }
         const reader = new FileReader();
         reader.onload = () => {
            setImage(reader.result);
         };
         reader.readAsDataURL(files[0]);

      }
      }else{
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Max file size is 5MB",
            showConfirmButton: true

         }).then((result) => {
         });
      }

   };
   const headers =
      [
         { name: "Unit Number", field: "Unit number", sortable: true, thStyle: 'width="40%"', inlineStyle: inlineCss },
         { name: "VIN Number", field: "Vin number", sortable: true, inlineStyle: inlineCss },
         { name: "Vehicle Type", field: "Vehicle type", sortable: true, inlineStyle: inlineCss },
         { name: "Make - Model", field: "Make", inlineStyle: inlineCss },
         // { name: "Model", field: "Model", inlineStyle: inlineCss },
         //  { name: "Email", field: "Email",sortable: true,inlineStyle:inlineCss },
         { name: "Actions" }
      ];
   const Notesheaders =
      [

         { name: "Note", field: "Note", sortable: false, inlineStyle: inlineCss },
         { name: "Created Date", field: "Created Date", sortable: false, inlineStyle: inlineCss }

      ];

   const onSortingChangeDocument = (field) => {
      const order =
         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

      setSortingField(field);
      setSortingOrder(order);
      customer_doc_api(id, currentDocsPage, ITEMS_PER_PAGE_DOCS, vehicleName, ITEMS_PER_PAGE_DOCS, setTotalDocsItems, settotalDocsPages, setCurrentDocsPage, setDocumentList, setTotalDocsRecord, setdisplayDocsPage, field, order, "");
      // document_api("","1",ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,"",sortingField,sortingOrder);

   };
   const {
      register,
      formState: { errors },
      handleSubmit: handleSubmit,
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });
   const {
      register: register2,
      formState: { errors: errors2 },
      handleSubmit: handleSubmit2,
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });

   const {

      register: register3,
      reset,
      formState: { errors: errors3 },
      handleSubmit: handleSubmit3,
   } = useForm({
      criteriaMode: "all", defaultValues: { roleCompany: "driver" }, mode: "onBlur"
   });
   const {
      register: register4,
      formState: { errors: errors4 },
      handleSubmit: handleSubmit4,
   } = useForm({
      criteriaMode: "all", mode: "onBlur"
   });
   const addContactForm = () => {
      $("#contact_FirstName").val("");
      $("#contact_Lastname").val("");
      $("#contact_Email").val("");
      $("#contcat_deskphone").val("");
      $("#contact_mobile").val("");
      $("#contact_role").val("");

   }

   const uploadImage = () => {


      document.getElementById("btncloseImage").click();

      $("#loadingforsubmit").show();
      var requestObjectCustomer =
      {


         "id": id,
         "customerName": $("#customername_e").val().trim(),
         "taxId": $("#taxId_e").val().trim(),
         "dotNumber": $("#dotnumber_e").val().trim(),
         "mcNumber": $("#Mcnumber_e").val().trim(),
         "ctNumber": $("#ctnumber_e").val().trim(),
         "addressLine1": $("#addressLine1_e").val().trim(),
         "addressLine2": $("#addressLine2_e").val() ? $("#addressLine2_e").val().trim() : "",
         "city": $("#city_e").val().trim(),
         "stateId": $("#state_e").val(),
         "zip": $("#zipcode_e").val().trim(),
         "phoneMobile": $("#yourphone2").val().trim(),
         "email": $("#emailAddress").val().trim(),
         "fax": $("#faxnumber_e").val().trim(),
         "website": $("#websiteURL").val().trim(),
         "imageName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : "",
         "imageExtension": cropper.getCroppedCanvas() ? "jpeg" : "",
         "imageByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : ""

      }


      //  const requestOptions =
      //  {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json'
      //              },

      //        body: JSON.stringify(requestObject)
      //  };
      var requestOptions = requestObject.RequestHeader(requestObjectCustomer);
      services.update_Customer_Details(requestOptions).then((res) => {
         $("#loadingforsubmit").hide();


         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
            showConfirmButton: true

         }).then((result) => {
            getCustomer_Detils(id);
         });


      });
   }
   const update_Customer_fun = () => {
      //document.getElementById("btnclose").click();
     // $("#loadingforsubmit").show();
      var requestObjectCustomer =
      {


         "id": id,
         "customerName": $("#customername_e").val().trim(),
         "taxId": $("#taxId_e").val().trim(),
         "dotNumber": $("#dotnumber_e").val().trim(),
         "mcNumber": $("#Mcnumber_e").val().trim(),
         "ctNumber": $("#ctnumber_e").val().trim(),
         "addressLine1": $("#addressLine1_e").val().trim(),
         "addressLine2": $("#addressLine2_e").val() ? $("#addressLine2_e").val().trim() : "",
         "city": $("#city_e").val().trim(),
         "stateId": $("#state_e").val(),
         "zip": $("#zipcode_e").val(),
         "phoneMobile": $("#yourphone2").val(),
         "email": $("#emailAddress").val().trim(),
         "website": $("#websiteURL").val().trim(),
         "fax": $("#faxnumber_e").val().trim(),

      }


      //  const requestOptions =
      //  {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json'
      //              },

      //        body: JSON.stringify(requestObject)
      //  };
      var requestOptions = requestObject.RequestHeader(requestObjectCustomer);
      services.update_Customer_Details(requestOptions).then((res) => {
         //$("#loadingforsubmit").hide();

         switch(res.message)
         {
            case "Unable to update Customer because of duplicate Dot Number":
               Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "",
                  text: res.message,
                  showConfirmButton: true
      
               }).then((result) => 
               {
                 
                         if(result.isConfirmed)
                         {
                          // document.getElementById("btnclose").click();
         
                         }
                  
                  //getCustomer_Detils(id);
      
               });
               break;
               default:
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: res.message,
                     showConfirmButton: true
         
                  }).then((result) => 
                  {
                    
                            if(result.isConfirmed)
                            {
                              document.getElementById("btnclose").click();
                              getCustomer_Detils(id);
                            }
                     
                     //getCustomer_Detils(id);
         
                  });
                  break;
         }
       

         

         


      });
   }

   /////////////////vehicle pagination////////////////////////////
   const handlePageChange = (offset, triggerevent) => {


      customerVehicle(id, offset, ITEMS_PER_PAGE, vehicleName, vehicleTypeId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "",inactiveStatus);

   }

   const handleNotesPageChange = (offset, triggerevent) => {
      setitemperpageNotes(offset);
      listOfNotes(offset, "");

   }




   /////////////////vehicle pagination////////////////////////////
   const handlePageContactChange = (offset, triggerevent) => {

      // setcontactitemperpage(offset);
      getContactList(inactiveContact, "false", offset, contactNames, contactRoleIds, ITEMS_CONTACT_PER_PAGE);

   }

   ////////////////documentDetails Customer/////////////////////////////

   /////////////////docs pagination////////////////////////////
   const handlePageChangedocument = (offset, triggerevent) => {

      setVehicleName(vehicleName);
      customer_doc_api(id, offset, ITEMS_PER_PAGE_DOCS, vehicleName, ITEMS_PER_PAGE_DOCS, setTotalDocsItems, settotalDocsPages, setCurrentDocsPage, setDocumentList, setTotalDocsRecord, setdisplayDocsPage, sortingField, sortingOrder, "");

   }
   /////////////////////////////vehicle search/////////////////////////
   const searchList = (vehicleName) => {
      setVehicleName(vehicleName);
      customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, vehicleTypeId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",inactiveStatus);

   }

   const searchContactList = (contactName) => {


      setContactName(contactName);
      getContactList(inactiveContact, "false", 1, contactName, contactRoleIds, ITEMS_CONTACT_PER_PAGE);
   }
   /////////////////////////////vehicle search/////////////////////////
   const searchDocsList = (vehicleId) => {
      setVehicleName(vehicleId);
      customer_doc_api(id, currentDocsPage, ITEMS_PER_PAGE_DOCS, vehicleId, ITEMS_PER_PAGE_DOCS, setTotalDocsItems, settotalDocsPages, setCurrentDocsPage, setDocumentList, setTotalDocsRecord, setdisplayDocsPage, "", sortingField, sortingOrder, "");

   }
   const user = useSelector(state => state.customerList);
   $('#vehicleDisplayPage').on('change', function () {

      //alert(this.value);
      setitemperpage(this.value);
      customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, sessionStorage.getItem("vehicleTypeID"), this.value * ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "",inactiveStatus);


   });

   const validate = (e) => {
      setVal(e.target.value);
      if (isURL(val)) {
         setErr('');
      } else {
         setErr('Invalid URL');
      }
   };

   $('#vehicleDisplayPage1').on('change', function () {

      // alert(this.value);
      setitemperpage1(this.value);
      customerVehicle(id, "1", ITEMS_PER_PAGE1, vehicleName, sessionStorage.getItem("vehicleTypeID"), this.value * ITEMS_PER_PAGE1, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "",inactiveStatus);


   });

   // $('#displaypagefornotes').on('change', function () {

   //    alert(this.value);
   // //   setitemperpageNotes(this.value);
   //   // listOfNotes(this.value);
   //    //customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, sessionStorage.getItem("vehicleTypeID"), this.value * ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "");


   // });



   useEffect(() => {

      $("#sendtocustomerDisabled").prop("disabled", true)
      // $("#emailErrorCustomerDetails").hide()
      $("#loadingfordocs").hide();
      sessionStorage.removeItem("searchValue")
      setRoles(localStorage.getItem("Userroles"));
      var navigationfrom = sessionStorage.getItem("naviationfrom");
      // alert(navigationfrom);
      localStorage.setItem("customerId", id);
      localStorage.setItem("cid", id);

      if (navigationfrom === "Customer-vehiclesList") {
         document.getElementById("pills-contact-tab").click();
         sessionStorage.removeItem("naviationfrom");
      } else if (navigationfrom === "customerDocuments") {
         document.getElementById("pills-doc-tab").click();
         sessionStorage.removeItem("naviationfrom");
      } else {
         document.getElementById("pills-home-tab").click();
         sessionStorage.removeItem("naviationfrom");
      }

      //   sessionStorage.setItem("naviationfrom","customerDetails");

      if (CheckAuthentication) {
         Getstates(setState);
         $(".error").hide();
         getCustomer_Detils(id);
         Getcontactrole(setContactRole);
         GetVehicleType(setvehicles);
         $('#contcat_deskphone').usPhoneFormat();
         $('#contact_mobile').usPhoneFormat();
         $('#edit_phone').usPhoneFormat();
         $('#edit_mobile').usPhoneFormat();
         $('#faxnumber_e').usPhoneFormat();
         $('#yourphone2').usPhoneFormat();

         customer_doc_api(id, "1", ITEMS_PER_PAGE_DOCS, "", ITEMS_PER_PAGE_DOCS, setTotalDocsItems, settotalDocsPages, setCurrentDocsPage, setDocumentList, setTotalDocsRecord, setdisplayDocsPage, sortingField, sortingOrder, "displayItems");
         customerVehicle(id, "1", ITEMS_PER_PAGE, "", vehicleTypeId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",inactiveStatus);
         getContactList(inactiveContact, "false", currentContactPage, contactNames, contactRoleIds, ITEMS_CONTACT_PER_PAGE);
         dispatch(fetchCustomer_List(id, "true", "true", setContactPrimary));

         $('#display_pages_docs_in_items').on('change', function () {

            setVehicleName(vehicleName);
            setitemperdocspage(this.value);
            customer_doc_api(id, "1", ITEMS_PER_PAGE_DOCS, vehicleName, this.value * ITEMS_PER_PAGE_DOCS, setTotalDocsItems, settotalDocsPages, setCurrentDocsPage, setDocumentList, setTotalDocsRecord, setdisplayDocsPage, sortingField, sortingOrder, "");
            //  getcustomerLists("1",ITEMS_PER_PAGE,customerName,this.value*ITEMS_PER_PAGE);

         });

         $('#inactiveContactList').on('change', function (event) {
            // State has changed to checked/unchecked.
            // alert(sessionStorage.getItem("searchValue")+"@@@@@"+$('#selectedRole option:selected').val());
            if ($('input[name="inactiveCotact"]').is(':checked')) {
               setInactive("false");
               getContactList("false", "true", currentContactPage, sessionStorage.getItem("searchValue"), $('#selectedRole option:selected').val(), ITEMS_CONTACT_PER_PAGE);

            } else {
               setInactive("true");
               getContactList("true", "false", currentContactPage, sessionStorage.getItem("searchValue"), $('#selectedRole option:selected').val(), ITEMS_CONTACT_PER_PAGE);
            }
         });


         //  $('#vehicleTypeFilter').on('change', function()
         //  {
         //     sessionStorage.setItem("vehicleTypeID",this.value)
         //     setVehicleTypes(this.value);
         //     customerVehicle(id,"1",ITEMS_PER_PAGE,vehicleName,this.value,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"displayItems");
         //  });


         //  $('#vehicleTypeFilter1').on('change', function()
         //  {
         //     sessionStorage.setItem("vehicleTypeID",this.value)
         //     setVehicleTypes(this.value);
         //     customerVehicle(id,"1",ITEMS_PER_PAGE,vehicleName,this.value,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"displayItems");
         //  });

         // $(document).on("click", "#inactive", function () {
         //    var inactiveVehicle = "";
         //    if ($('input[name="inactive"]').is(':checked')) {
         //      setInactiveStatus(false);
         //      inactiveVehicle = "false";
         //    } else {
         //      setInactiveStatus(true);
         //      inactiveVehicle = "true";
         //    }
         //    customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, vehicleTypeId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",inactiveVehicle);
         //  });


         $('#selectedRole').on('change', function () {
            var inactiveCotactUser = "";
            if ($('input[name="inactiveCotact"]').is(':checked')) {
               setInactive("false");
               inactiveCotactUser = "false";
            } else {
               setInactive("true");
               inactiveCotactUser = "true";
            }
            setContactRoleId(this.value);
            getContactList(inactiveCotactUser, "false", currentContactPage, sessionStorage.getItem("searchValue"), this.value, ITEMS_CONTACT_PER_PAGE);
         });
      } else {
         sessionStorage.clear();
         localStorage.clear();
         //navigate("/login");
         window.location.replace("login");
      }
      sessionStorage.setItem("naviationfrom", "customerDetails");
      listOfNotes(1, "");
      ListOfContacts(id, setCustomerContactList);
   }, [dispatch]);


   //    $( document ).ready(function() {
   //       console.log("@@@"+sessionStorage.getItem("searchValue"))
   //       setContactName(sessionStorage.getItem("searchValue"))
   //   });
   const displayPageChnageForNote = () => {
      var itemperPageForNotes = $("#dipPageVal").val();
      //alert(itemperPageForNotes)
      // setitemperpagenotes(itemperPageForNotes);
      listOfNotes(1, itemperPageForNotes);

   }
   const listOfNotes = (pageNumber, pageSize) => {

      setitemperpagenotes(pageSize == "" ? ITEMS_PER_PAGE_NOTES : pageSize);

      var contactRequest =
      {
         "cutomerId": id,
         "pageNumber": pageNumber,
         "pageSize": pageSize == "" ? ITEMS_PER_PAGE_NOTES : pageSize,
         "sortBy": "",
         "sortDir": ""
      };

      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(contactRequest)
      // };

      var requestOptions = requestObject.RequestHeader(contactRequest);

      services.listOfNotes(requestOptions).then((res) => {
         try {
            setNotes(res.rows ? res.rows : "");

            settotalNotesPage(res.customValues ? res.customValues.totalNumberOfRecords : 0);

            setitemperpageNotes(res.customValues ? res.customValues.currentPageNumber : 0);
            var displaypages = [];
            for (var i = 1; i <= parseInt(Math.ceil(res.customValues.totalNumberOfRecords / 25)); i++) {

               displaypages.push(i * 25);
            }

            setNotedisplayPage(displaypages);

         } catch (ex) {

         }


      });



   };
   const openModal = () => {
      $('input:checkbox[name=contactListemails]').prop('checked', false).attr('checked', 'checked');
      $('input:checkbox[name=contactSelectAll]').prop('checked', false).attr('checked', 'checked');
   }
   const saveNotes = () => {


      if ($("#noteVal").val() == "") {
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter a note",

            showConfirmButton: true

         }).then((result) => {

         });

      } else {

         var contactRequest =
         {


            "cutomerId": id,

            "note": $("#noteVal").val().trim()

         };
         document.getElementById("note_close").click();

         $("#loadingforsubmit").show();
         // const requestOptions =
         // {
         //       method: 'POST',
         //       headers:{'Content-Type': 'application/json'},
         //       body: JSON.stringify(contactRequest)
         // };

         var requestOptions = requestObject.RequestHeader(contactRequest);

         services.adcustomerNotes(requestOptions).then((res) => {

            $("#loadingforsubmit").hide();
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {

               listOfNotes(1, "");
            });
         });

      }



   }
   const selectVehicle = (event) => {
      setSelectedOption(event);
      customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, event, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",inactiveStatus);
   }

   //////////Update contact//////////////////////////////////////
   const updateContactAPI = () => {

      // document.getElementById("edit_close2").click();

      // if($("#edit_mobile").val()=="")
      // {
      //    Swal.fire({
      //       position: 'center',
      //       icon: 'error',
      //       title: "",
      //       text: "Please enter a mobile number",

      //       showConfirmButton: true

      //    }).then((result) => {
      //    });

      // }else if($("#edit_role").val()=="")
      // {
      //    Swal.fire({
      //       position: 'center',
      //       icon: 'error',
      //       title: "",
      //       text: "Please select the role",

      //       showConfirmButton: true

      //    }).then((result) => {
      //    });

      // }else{
      document.getElementById("edit_close2").click();
      $("#loadingforsubmit").show();
      var contactRequest =
      {




         "id": editcontactId,

         "firstName": $("#edit_fristName").val().trim(),

         "lastName": $("#edit_lastName").val().trim(),

         "email": $("#edit_email").val() ? $("#edit_email").val().trim() : "",

         "phoneBusiness": $("#edit_phone").val() ? $("#edit_phone").val().trim() : "",

         "phoneMobile": $("#edit_mobile").val() ? $("#edit_mobile").val().trim() : "",

         "fkContactRoleId": $("#edit_role").val() ? $("#edit_role").val() : "",
         "isActive": $("#edit_status").val() == "Active" ? "True" : "False"




      };

      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(contactRequest)
      // };

      var requestOptions = requestObject.RequestHeader(contactRequest);
      services.update_contactAPI(requestOptions).then((res) => {

         $("#loadingforsubmit").hide();
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,

            showConfirmButton: true

         }).then((result) => {
            if (result.isConfirmed) {
               document.getElementById("edit_close").click();

               var pageno;
               if (currentContactPage == '') {
                  pageno = "1";
               }
               else {
                  pageno = currentContactPage;
               }

               getContactList(inactiveContact, "false", pageno, contactNames, contactRoleIds, ITEMS_CONTACT_PER_PAGE);
               dispatch(fetchCustomer_List(id, "true", "true", setContactPrimary));
            }
            //window.location.reload();
         });
      });
      // }


   }


   const redirect = (page) => {

      navigate(page);

   }

   const backbuttonHandle = (tabName) => {
      // alert(tabName);
      if (tabName === "overview") {
         sessionStorage.setItem("naviationfrom", "overview");
         $('input[name=inactiveOverview]').prop("checked", false);
         customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, "", ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",true);
         setInactiveStatus(true)
      }
      if (tabName === "vehicles") {
         sessionStorage.setItem("naviationfrom", "Customer-vehiclesList");
         $('input[name=inactive]').prop("checked", false);
         customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, "", ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, "", sortingOrder, "displayItems",true);
         setInactiveStatus(true)
      }
      if (tabName === "cusDocuments") {
         sessionStorage.setItem("naviationfrom", "customerDocuments");
      }
   }

   // $(document).on("click", "#pills-home-tab", function () {
   //    sessionStorage.setItem("naviationfrom","customerDetails");
   //  });

   //  $(document).on("click", "#pills-contact-tab", function () {
   //    sessionStorage.setItem("naviationfrom","vehicles");

   //  });

   /////////getcontact_details_By_Id////////////////////////////

   const get_Contact_active_details_by_id = (id) => {

      setContactId(id);
      for (var i = 0; i < contactLists.length; i++) {

         if (id == contactLists[i].id) {

            $("#edit_fristName").val(contactLists[i].firstName);
            $("#edit_lastName").val(contactLists[i].lastName);
            $("#edit_email").val(contactLists[i].email);
            $("#edit_phone").val(contactLists[i].phoneBusiness);
            $("#edit_mobile").val(contactLists[i].phoneMobile);

            setSelectedRole(contactLists[i].roleName);
            //  $("#edit_role").val(contactList_store.customerList[i].role);

            if (contactLists[i].isActive) {
               $("#edit_status option[value=Active]").attr('selected', 'selected').trigger('click');
            } else {
               $("#edit_status option[value=Inactive]").attr('selected', 'selected').trigger('click');
            }


         }

      }


   }
   const get_Contact_details_by_id = (id) => {
      setContactId(id);
      for (var i = 0; i < contactList_store.customerList.length; i++) {

         if (id == contactList_store.customerList[i].id) {
            console.log(contactList_store.customerList[i]);
            $("#edit_fristName").val(contactList_store.customerList[i].firstName);
            $("#edit_lastName").val(contactList_store.customerList[i].lastName);
            $("#edit_email").val(contactList_store.customerList[i].email);
            $("#edit_phone").val(contactList_store.customerList[i].phoneBusiness);
            $("#edit_mobile").val(contactList_store.customerList[i].phoneMobile);

            reset({
               edit_Firstname: contactList_store.customerList[i].firstName ? contactList_store.customerList[i].firstName : "", // Set another date
               edit_Lastname: contactList_store.customerList[i].lastName ? contactList_store.customerList[i].lastName : "", // Set another date
               edit_companyEmail: contactList_store.customerList[i].email ? contactList_store.customerList[i].email : "",
               edit_Phone: contactList_store.customerList[i].phoneBusiness ? contactList_store.customerList[i].phoneBusiness : "",
               // edit_mobileNumber: contactList_store.customerList[i].phoneMobile ? contactList_store.customerList[i].phoneMobile : ""
               // roleCompany: contactList_store.customerList[i].roleName ? contactList_store.customerList[i].roleName : "",
            });

            setSelectedRole(contactList_store.customerList[i].roleName);
            //  $("#edit_role").val(contactList_store.customerList[i].role);

            if (contactList_store.customerList[i].isActive) {
               $("#edit_status option[value=Active]").attr('selected', 'selected').trigger('click');
            } else {
               $("#edit_status option[value=Inactive]").attr('selected', 'selected').trigger('click');
            }


         }

      }


   }

   //////////Add contact//////////////////////////////////////
   const addContactAPI = () => {


      // if($("#contact_mobile").val()=="")
      // {
      //    Swal.fire({
      //       position: 'center',
      //       icon: 'error',
      //       title: "",
      //       text: "Please enter a mobile number",

      //       showConfirmButton: true

      //    }).then((result) => {
      //    });

      // }else if($("#contact_role").val()=="")
      // {
      //    Swal.fire({
      //       position: 'center',
      //       icon: 'error',
      //       title: "",
      //       text: "Please select the role",

      //       showConfirmButton: true

      //    }).then((result) => {
      //    });

      // }else{
      document.getElementById("contact_close").click();
      $("#loadingforsubmit").show();

      var contactRequest =
      {




         "fkCustomerId": id,

         "firstName": $("#contact_FirstName").val().trim(),

         "lastName": $("#contact_Lastname").val().trim(),

         "email": $("#contact_Email").val() ? $("#contact_Email").val().trim() : "",

         "phoneBusiness": $("#contcat_deskphone").val() ? $("#contcat_deskphone").val().trim() : "",

         "phoneMobile": $("#contact_mobile").val() ? $("#contact_mobile").val().trim() : "",

         "fkContactRoleId": $("#contact_role").val() ? $("#contact_role").val() : ""




      };

      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(contactRequest)
      // };

      var requestOptions = requestObject.RequestHeader(contactRequest);
      services.addContacts(requestOptions).then((res) => {

         $("#loadingforsubmit").hide();
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,

            showConfirmButton: true

         }).then((result) => {
            if (result.isConfirmed) {
               var pageno;
               if (currentContactPage == '') {
                  pageno = "1";
               }
               else {
                  pageno = currentContactPage;
               }
               //getContactList(inactiveContact,"false",currentContactPage,contactNames,contactRoleIds,ITEMS_CONTACT_PER_PAGE);
               getContactList(inactiveContact, "false", pageno, contactNames, contactRoleIds, ITEMS_CONTACT_PER_PAGE);
            }
            // window.location.reload();
         });
      });

      // }


   }


   //////////Add contact//////////////////////////////////////
   const addNotes = () => {
      document.getElementById("contact_close").click();
      $("#loadingforsubmit").show();
      var contactRequest =
      {




         "fkCustomerId": id,

         "firstName": $("#contact_FirstName").val().trim()


      };

      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(contactRequest)
      // };

      var requestOptions = requestObject.RequestHeader(contactRequest);
      services.addContacts(requestOptions).then((res) => {

         $("#loadingforsubmit").hide();
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,

            showConfirmButton: true

         }).then((result) => {
            if (result.isConfirmed) {
               var pageno;
               if (currentContactPage == '') {
                  pageno = "1";
               }
               else {
                  pageno = currentContactPage;
               }
               //getContactList(inactiveContact,"false",currentContactPage,contactNames,contactRoleIds,ITEMS_CONTACT_PER_PAGE);
               getContactList(inactiveContact, "false", pageno, contactNames, contactRoleIds, ITEMS_CONTACT_PER_PAGE);
            }
            // window.location.reload();
         });
      });

   }

   ////////////////Update Customer/////////////////////////////
   const getContactList = (statusofAccount, isPrimary, page, companyName, contactRoleId, pageSize) => {

      setcontactitemperpage(pageSize);
      
      var contactRequest =
      {




         "fkCustomerId": id,

         "firstName": companyName,

         "lastName": companyName,

         "pageNumber": page,

         "pageSize": pageSize,

         "sortBy": "",

         "isActive": statusofAccount,
         "fkContactRoleId": contactRoleId,
         "sortDir": "",

         "isPrimaryAndAccountContactsOnly": isPrimary


      };
      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(contactRequest)
      // };

      var requestOptions = requestObject.RequestHeader(contactRequest);

      services.getContactList(requestOptions).then((res) => {
         setContactList(res.rows);

         setTotalContactItem(res.customValues ? res.customValues.totalNumberOfRecords : 0);

         setContactCurrentPage(res.customValues ? res.customValues.currentPageNumber : 0);

      });

   }
   ////////////////Update Customer/////////////////////////////
   const update_Customer_fun2 = () => {


      var customerListReq =
      {

         "id": id,
         "customerName": $("#customername").val(),
         "dotNumber": $("#dotNumber").val().replace(/\s+/g, '') == "" ? "" : $("#dotNumber").val().replace(/\s+/g, '')
      };



      if ($("#customername").val() == "") {

         $(".error").show();
         return false;
      } else {
         $(".error").hide();

      }
      document.getElementById("btnclose").click();
      $("#loadingforsubmit").show();
      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(customerListReq)
      // };
      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.update_Customer_Details(requestOptions).then((res) => {
         $("#loadingforsubmit").hide();
         getCustomer_Detils(id);
         document.getElementById("btnclose").click();
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,

            showConfirmButton: true

         }).then((result) => {
            if (result.isConfirmed) {
               getContactList(inactiveContact, "false", currentContactPage, contactNames, contactRoleIds, ITEMS_CONTACT_PER_PAGE);
            }
         });
         // alert(res.message);


      });

   }

   const addNote = () => {

      $("#noteVal").val("");

   }

   ////////getcustomer_details from aws////////////////////////
   const getCustomer_Detils = (id) => {
      var customerListReq =
      {

         "id": id,

      };



      // const requestOptions =
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(customerListReq)
      // };
      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.getCustomer_details(requestOptions).then((res) => {
         $("#loading").hide();
         // document.getElementById("btnclose").click();
         $("#customername_e").val(res.rows ? res.rows.customerName : "");
         $("#taxId_e").val(res.rows ? res.rows.taxId : "");
         $("#addressLine1_e").val(res.rows ? res.rows.addressLine1 : "");
         $("#addressLine2_e").val(res.rows ? res.rows.addressLine2 : "");
         $("#city_e").val(res.rows ? res.rows.city : "");
         $("#stateId").val(res.rows ? res.rows.stateId : "");
         setaddressLine2Value(res.rows ? res.rows.addressLine2 : "");
         setStateIdC(res.rows ? res.rows.stateId : "");
         $("#zipcode_e").val(res.rows ? res.rows.zip : "");
         // setPhoneNumber(res.rows ? res.rows.phoneMobile : "");
         $("#yourphone2").val(res.rows ? res.rows.phoneMobile : "");
         $("#emailAddress").val(res.rows ? res.rows.email : "");
         $("#faxnumber_e").val(res.rows ? res.rows.fax : "");
         $("#dotnumber_e").val(res.rows ? res.rows.dotNumber : "");
         $("#Mcnumber_e").val(res.rows ? res.rows.mcNumber : "");
         $("#ctnumber_e").val(res.rows ? res.rows.ctNumber : "");
         $("#websiteURL").val(res.rows ? res.rows.website : "");
         setVal(res.rows.website);
         setImage(res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/no-vehicle-image.png");
         // $("#posterURL").attr("src",res.customValues?"data:image/png;base64,"+res.customValues.fileByte:"/images/transport-logo.jpg");
         $("#posterURL").attr("src", res.customValues ? res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/no-vehicle-image.png" : "/images/no-vehicle-image.png");
         setcustomerDetails(res.rows);

      });

   }
   //  $(document).on("click", "#vehicleD", function () {
   //    sessionStorage.setItem("naviationfrom","overview");
   //  });

   var activestatus="false";
   const activeInctive = (event) => {

       if(event.target.checked)
       {

       }else{
           activestatus="true";
       }
       customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, vehicleTypeId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",activestatus);

       setInactiveStatus(activestatus);
   }

   // var activestatusOverview="false";
   // const activeInctiveOverview = (event) => {

   //     if(event.target.checked)
   //     {

   //     }else{
   //       activestatusOverview="true";
   //     }
   //     customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, vehicleTypeId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",activestatusOverview);

   //     setInactiveStatusOverview(activestatusOverview);
   // }


   $(document).on("click", "#EditImage", function () {
      $('#selectedImage').hide();
      $('#DB_Image').show();
      $('#uploadImageDisable').prop('disabled', true);
   });

 

   $('input[name=documentIds]').change(function(){

      if ($('input:checkbox[name=documentIds]:checked').length> 0) {
         $("#sendtocustomerDisabled").prop("disabled", false)
         // $("#printDocuments").prop("disabled", false)
      }else{
         $("#sendtocustomerDisabled").prop("disabled", true)
         // $("#printDocuments").prop("disabled", true)
      }
   
   });

   $('input[name=documentIdss]').change(function(){

      if ($('input:checkbox[name=documentIds]:checked').length> 0) {
         $("#sendtocustomerDisabled").prop("disabled", false)
         // $("#printDocuments").prop("disabled", false)
      }else{
         $("#sendtocustomerDisabled").prop("disabled", true)
         // $("#printDocuments").prop("disabled", true)
      }
   
   });

   return (
      <>
        {LoadingComponentfordocuments}
         {loadingComponent}
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block;" }}>
               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <h4 class="font-weight-bold my-4 text-uppercase d-flex justify-content-between align-items-center">Customer Details
                     <a style={{float: "right"}} onClick={() => redirect("/customer")} class="btn btn-white"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </h4>

                  </div>
               </div>

               {/* <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-end mb-2">
                        <h4 class="font-weight-bold"></h4>
                        <a onClick={() => redirect("/customer")} class="btn btn-white"><span style={{ size: "20px" }}>&lArr;</span> Back </a>
                     </div>
                  </div>
               </div> */}

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card p-3 border-bottom">
                        <div class="banner-owner">
                           <div class="d-flex justify-content-between">
                              <div class="owner-logo position-relative" id="EditImage">
                                 {roles.match("Technician") || roles.match("Inspector") ? "" :
                                    <a href="#" class="logo-edit">
                                       <img src="/images/icons/edit-icon.svg" alt="" />
                                    </a>}
                                 {roles.match("Technician") || roles.match("Inspector") ? <a>
                                    <img alt="" id="posterURL" /></a> : <a href="" data-toggle="modal" data-target="#uploadPicModal" style={{ width: "60px", marginRight: "10px" }} data-backdrop="static" data-keyboard="false">
                                    <img alt="" id="posterURL" /></a>}

                                 <div class="owner-name">
                                    <h4>{customerDetails ? customerDetails.customerName : ""}</h4>
                                    <span>DOT Number: </span> <span>{customerDetails ? customerDetails.dotNumber : ""}</span>
                                 </div>
                              </div>
                              <div class="d-flex align-items-center">
                                 {/* <!-- <img src="/images/icons/edit-icon.svg" alt=""/>
                                       <a href="#" class="mx-2">Edit</a> --> */}
                                 {/* <a href={"/addCustomer/"+id}  >
                                    <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a> */}
                                   {/* {roles.match("Technician") || roles.match("Inspector") ? "" : <div class="d-flex mr-auto align-items-center">

                                   <label className="mb-0 mr-2">Status : </label> <div class="pro-info-item text-capitalize" >{customerDetails ?customerDetails.isActive ? "Active" : "Inactive":""}  {customerDetails ?customerDetails.isActive ?<a class="btn btn-outline-primary text-uppercase" onClick={() => activeCustomer("false")}>Inactive</a>:<a class="btn btn-outline-primary text-uppercase " onClick={() => activeCustomer("true")}>Active</a>:""}</div>

                                 </div>} */}

                                 {roles.match("Technician") || roles.match("Inspector") ? "" : <label className='mb-0 mr-2'>Status : </label>}{roles.match("Technician") || roles.match("Inspector") ? "" :   <ToggleSwitch id="switch" checked={customerDetails ?customerDetails.isActive ? true : false:false} onChange={checked => activeCustomer(checked)} />}

                                 {roles.match("Technician") || roles.match("Inspector") ? "" : <span class="edit-cust"><a  class="btn btn-primary text-uppercase ml-3" data-toggle="modal" data-target="#editcustomer">

                                    {/* <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" /> */}
                                    Edit</a>
                                 </span>}
                              </div>
                           </div>
                           <div class="banner-contact-info d-flex align-items-center flex-column flex-sm-row">
                              <div class="w-100">
                                 <div class="row align-items-center p-2">
                                    {customerDetails ? customerDetails.addressLine1 ?
                                       <div class="col-md-6 col-lg-5 ">
                                          <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                             <img src="/images/icons/map-icon.svg" alt="" class="mr-2" />
                                             <div>
                                                <span>{customerDetails ? customerDetails.addressLine1 : ""}{customerDetails ? customerDetails.addressLine1 ? "," : "" : ""}<span>
                                                   &nbsp;</span>{customerDetails ? customerDetails.addressLine2 : ""}{customerDetails ? customerDetails.addressLine2 ? "," : "" : ""}<span>
                                                      &nbsp;</span><br />{customerDetails ? customerDetails.city : ""}{customerDetails ? customerDetails.city ? "," : "" : ""}<span> <span>
                                                         {customerDetails ? customerDetails.stateName : ""}{customerDetails ? customerDetails.stateName ? "," : "" : ""}</span>
                                                      &nbsp;</span>{customerDetails ? customerDetails.zip : ""}{customerDetails ? customerDetails.zip ? "." : "" : ""}</span>

                                             </div>
                                          </div>
                                       </div> : "" : ""}
                                    {customerDetails ? customerDetails.phoneMobile ? <div class="col-md-6 col-lg-3 ">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          {/* {customerDetails?customerDetails.phoneMobile?<img src="/images/icons/phone-icon.svg" alt="" class="mr-2" />:"":""} */}
                                          <img src="/images/icons/phone-icon.svg" alt="" class="mr-2" />

                                          <span>{customerDetails ? customerDetails.phoneMobile ? customerDetails.phoneMobile : "--" : ""}</span>
                                       </div>
                                    </div> : "" : ""}

                                    {customerDetails ? customerDetails.fax ? <div class="col-md-6 col-lg-4 ">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          {/* {customerDetails?customerDetails.fax?<img src="/images/icons/fax-icon-svg.svg" alt="" class="mr-2" />:"":""} */}
                                          <img src="/images/icons/fax-icon-svg.svg" alt="" class="mr-2" />
                                          <span>{customerDetails ? customerDetails.fax ? customerDetails.fax : "--" : ""}</span>
                                       </div>
                                    </div> : "" : ""}
                                    {customerDetails ? customerDetails.website ? <div class="col-md-6 col-lg-5 ">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          {/* {customerDetails?customerDetails.website?<img src="/images/icons/web-icon.svg" alt="" class="mr-2" />:<img src="/images/icons/web-icon.svg" alt="" class="mr-2" />:""} */}
                                          <img src="/images/icons/web-icon.svg" alt="" class="mr-2" />
                                          <span>
                                             {/* {customerDetails?customerDetails.website:"--"} */}
                                             {customerDetails ? customerDetails.website ? customerDetails.website : "--" : ""}
                                          </span>
                                       </div>
                                    </div> : "" : ""}

                                    {customerDetails ? customerDetails.email ? <div class="col-md-6 col-lg-4 ">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          {/* {customerDetails?customerDetails.email?<img src="/images/icons/message-icon.svg" alt="" class="mr-2" />:"":""} */}
                                          <img src="/images/icons/message-icon.svg" alt="" class="mr-2" />
                                          <span>{customerDetails ? customerDetails.email ? customerDetails.email : "--" : ""}</span>
                                       </div>
                                    </div> : "--" : ""}



                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="row mb-2 ">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div>
                        <ul class="nav nav-pills tab-custome" id="pills-tab" role="tablist">
                           <li class="nav-item">
                              <a class="nav-link active" id="pills-home-tab" onClick={() => backbuttonHandle('overview')} data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Overview</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Contacts</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" id="pills-contact-tab" onClick={() => backbuttonHandle('vehicles')} data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Vehicles</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" id="pills-doc-tab" onClick={() => backbuttonHandle('cusDocuments')} data-toggle="pill" href="#pills-doc" role="tab" aria-controls="pills-doc" aria-selected="false">Documents</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" id="pills-notes-tab" data-toggle="pill" href="#pills-notes" role="tab" aria-controls="pills-notes-tab" aria-selected="false">Notes</a>
                           </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                           <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              <div class="row mb-1">
                                 <div class="col-md-12">
                                    <div class="card p-3">

                                       <div class="row">
                                          {
                                             primaryContactList ? primaryContactList.map((data, index) => (
                                                <div class="col-lg-12 col-xl-6 mb-4">

                                                   <div class="contact-card mb-1">
                                                      <div class="contact-card-header d-flex align-items-start justify-content-between p-3 border-bottom">
                                                         <div class="d-flex flex-column">
                                                            <span class="contact-name text-capitalize"><b>{data.firstName ? data.firstName : "N/A"} {data.lastName ? data.lastName : "N/A"}</b> </span>
                                                            <span class="contact-name-role text-capitalize"><b>{data.roleName ? data.roleName : "N/A"}</b></span>
                                                         </div>
                                                         <div>
                                                            <span class="job-lable text-capitalize">{data.isPrimary ? "Primary contact" : data.isAccounting ? "Accounting Contact" : ""}</span>
                                                            <span class="edit-cust">
                                                               {roles.match("Technician") || roles.match("Inspector") ? "" : <a href="#" data-toggle="modal" data-target="#edit_Contact" onClick={() => get_Contact_details_by_id(data.id, "Primary")}>
                                                                  <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>}
                                                               {/* col-md-6 col-lg-4 mb-4 */}
                                                            </span>
                                                         </div>
                                                      </div>
                                                      <div class="contact-body p-2">
                                                         <div class="row">
                                                            <div class="col-md-12">
                                                               {data.phoneBusiness ? <img src="/images/icons/phone-icon2.svg" style={{ marginRight: "10px" }} alt="" /> : ""}
                                                               <span>{data ? data.phoneBusiness ? data.phoneBusiness : "" : "N/A"}</span>
                                                            </div>
                                                            <div class="col-md-12">
                                                               {data.phoneMobile ? <img src="/images/icons/mobile-icon.svg" style={{ marginRight: "10px" }} alt="" /> : <img src="/images/icons/mobile-icon.svg" style={{ marginRight: "10px" }} alt="" />}
                                                               <span>{data.phoneMobile ? data.phoneMobile : "N/A"}</span>
                                                            </div>
                                                            <div class="col-md-12">
                                                               {data.email ? <img src="/images/icons/message-icon2.svg" style={{ marginRight: "10px" }} alt="" /> : ""}
                                                               <span>{data ? data.email ? data.email : "" : "N/A"}</span>
                                                            </div>
                                                            {/* <div class="col-md-7">
                                                            <img src="/images/icons/fax-icon.svg" alt=""/>
                                                            <span>{"N/A"}</span>
                                                         </div> */}
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>)) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                             </div>
                                          }
                                          {/* <div class="col-md-6">
                                                <div class="contact-card mb-1">
                                                   <div class="contact-card-header d-flex align-items-center justify-content-between p-3 border-bottom">
                                                      <div>
                                                         <span class="contact-name"><b>Richard Brooke</b> <span class="ml-1 mr-1">|</span> </span>
                                                         <span class="contact-name-role"><b>CEO</b></span>
                                                      </div>
                                                      <div>
                                                         <span class="job-lable">Primary contact</span>
                                                         <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal">
                                                         <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a></span>
                                                      </div>
                                                   </div>
                                                   <div class="contact-body p-2">
                                                      <div class="row">
                                                         <div class="col-md-5">
                                                            <img src="/images/icons/mobile-icon.svg" alt=""/>
                                                            <span>(617) 399 8424</span>
                                                         </div>
                                                         <div class="col-md-7">
                                                            <img src="/images/icons/message-icon2.svg" alt=""/>
                                                            <span>richard@abcdtransport.com</span>
                                                         </div>
                                                         <div class="col-md-5">
                                                            <img src="/images/icons/phone-icon2.svg" alt=""/>
                                                            <span>(617) 399 8424</span>
                                                         </div>
                                                         <div class="col-md-7">
                                                            <img src="/images/icons/fax-icon.svg" alt=""/>
                                                            <span>(617) 399 8424</span>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> */}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="row mb-1">
                                 <div class="col-md-12">
                                    <div class="card">
                                       <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                          <div class="d-flex justify-content-start align-items-center">
                                            <div class="search position-relative">
                                             <Search
                                                placeholderName="Search by VIN Number / Model"
                                                onSearch={value => {
                                                   searchList(value);
                                                }}

                                             />
                                             <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a>
                                             </div>
                                              {roles.match("Technician") || roles.match("Inspector") ? "" : <><input type="checkbox"
                                                  onChange={(e) => activeInctive(e) }
                                                   id="inactive"
                                                   name="inactiveOverview"
                                                   style={{ marginLeft: "21px", marginTop: "0px" }} />
                                                   <span style={{ marginLeft: "10px", marginTop: "0px" }}>
                                                    Inactive records only
                                                   </span></>}

                                                   <div class="d-flex align-items-center align-self-start">
                                                <span class="ml-4 mr-2 pl-3">Vehicle type :</span>
                                                <div class="dropdown show mr-3">

                                                   <select class="form-control custome-select" value={selectedOption} onChange={e => {
                                                      setVehicleTypes(e.target.value)
                                                      selectVehicle(e.target.value)
                                                      sessionStorage.setItem("vehicleTypeID", e.target.value)
                                                      //setVehicleTypes(this.value);
                                                      customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, e.target.value, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",inactiveStatus);
                                                   }}>
                                                      <option value=''  >Select</option>
                                                      {
                                                         vehicleType ? vehicleType.map((vehicle, index) => (
                                                            <option value={vehicle.id}>{vehicle.name}</option>
                                                         )) : ""
                                                      }
                                                   </select>
                                                   {/* <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                         <a class="dropdown-item" href="#">Tractor</a>
                                                         <a class="dropdown-item" href="#">Truck</a>
                                                         <a class="dropdown-item" href="#">Trailer</a>
                                                      </div> */}
                                                </div>
                                             </div>
                                          </div>
                                          <div class="d-flex sort align-self-start mt-1 mb-1 d-none">
                                             {/* <div class="d-flex align-items-center align-self-start">
                                                   <span class="mr-2">Sort by :</span>
                                                   <div class="dropdown show mr-3 ">
                                                      <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Day</a>
                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                         <a class="dropdown-item" href="#">Week</a>
                                                         <a class="dropdown-item" href="#">Month</a>
                                                      </div>
                                                   </div>
                                                </div> */}

                                          </div>
                                          <div class="d-flex align-self-start mb-1 mt-1">
                                             {/* <!-- <button type="button" class="btn btn-success">Add Customer</button> --> */}
                                             {roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/addVehicle/" + id)} type="button" class="btn btn-success">Add Vehicle</a>}
                                          </div>
                                       </div>
                                       <div class="card-body pt-0 pb-0">
                                          <div class="row">
                                             <div class="col-12">
                                                <div class="table-responsive  border-bottom">
                                                   <table class="table">

                                                      <tr>
                                                         {headers.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                            <th
                                                               key={name}
                                                               onClick={() =>
                                                                  sortable ? onSortingChangeVehicle(field) : null
                                                               }
                                                               class={thStyle} style={inlineStyle}>
                                                               {name}

                                                               {sortingField && sortingField === field && (

                                                                  sortingOrder === "asc"
                                                                     ? "↑"
                                                                     : "↓"
                                                               )}

                                                            </th>
                                                         ))}
                                                      </tr>

                                                      {/* <Tableheader
                              headers={headers}

                          /> */}
                                                      <tbody>
                                                         {
                                                            vechileLists ? vechileLists.map((data, index) => (
                                                               <tr>
                                                                  <td style={{ width: "20%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.unitNumber != '' ? data.unitNumber : 'NA'}</td>
                                                                  <td style={{ width: "20%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.vinNumber != '' ? data.vinNumber : 'NA'} </td>
                                                                  <td>{data.vehicleTypeName != '' ? data.vehicleTypeName : 'NA'}</td>
                                                                  <td>{data.make ? data.make : "NA"} - {data.model ? data.model : "NA"}</td>
                                                                  {/* <td>{data.model != '' ? data.model : 'NA'}</td> */}
                                                                  {/* <td>{data.customerEmail}</td> */}

                                                                  <td style={{ "width": "50px" }}><a onClick={() => redirect("/vehicleDetails/" + data.id)} class="btn btn-outline-primary text-uppercase" id="vehicleD">View</a></td>
                                                               </tr>
                                                            )) : ""
                                                         }

                                                      </tbody>

                                                   </table>

                                                </div>

                                             </div>
                                             {vechileLists ? vechileLists.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                             </div>}
                                          </div>
                                       </div>
                                       {vechileLists.length > 0 ? <div class="d-flex align-items-center justify-content-between p-3">
                                          <div class="d-flex">
                                             <div class="dropdown show mr-3 ">
                                                {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                   <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                      <a class="dropdown-item" href="#">25</a>
                                                      <a class="dropdown-item" href="#">50</a>
                                                   </div> */}
                                                <select class="form-control custome-select" className="btn gray-mid-color dropdown-toggle " id="vehicleDisplayPage">
                                                   {
                                                      displayPage.map((data, index) => (
                                                         <option value={data}>{data}</option>
                                                      ))
                                                   }
                                                </select>
                                             </div>
                                             <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE <= totalRecors ? currentPage * ITEMS_PER_PAGE : totalRecors} out of {totalRecors} Records Displayed</span>
                                          </div>
                                          <nav aria-label="Page navigation example">
                                             {totalItems > 0 ? <Pagination
                                                total={totalItems}
                                                itemsPerPage={ITEMS_PER_PAGE}
                                                currentPage={currentPage}
                                                //onPageChange={page => setCurrentPage(page)}
                                                onPageChange={handlePageChange}
                                             /> : ""}
                                             {/* <ul class="pagination mb-0">
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Previous">
                                                      <span aria-hidden="true">«</span>
                                                      <span class="sr-only">Previous</span>
                                                      </a>
                                                   </li>
                                                   <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Next">
                                                      <span aria-hidden="true">»</span>
                                                      <span class="sr-only">Next</span>
                                                      </a>
                                                   </li>
                                                </ul> */}
                                          </nav>
                                       </div> : ""}
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                              <div class="row mb-1">
                                 <div class="col-md-12">
                                    <div class="card p-3">
                                       <div class="row">

                                          {
                                             primaryContactList ? primaryContactList.map((data, index) => (
                                                <div class="col-lg-12 col-xl-6 mb-4">

                                                   <div class="contact-card mb-1">
                                                      <div class="contact-card-header d-flex align-items-start justify-content-between p-3 border-bottom">
                                                         <div class="d-flex flex-column">
                                                            <span class="contact-name text-capitalize"><b>{data.firstName ? data.firstName : "N/A"} {data.lastName ? data.lastName : "N/A"}</b></span>
                                                            <span class="contact-name-role text-capitalize"><b>{data.roleName ? data.roleName : "N/A"}</b></span>
                                                         </div>
                                                         <div>
                                                            <span class="job-lable text-capitalize">{data.isPrimary ? "Primary contact" : data.isAccounting ? "Accounting Contact" : ""}</span>
                                                            <span class="edit-cust">
                                                               {roles.match("Technician") || roles.match("Inspector") ? "" : <a href="#" data-toggle="modal" data-target="#edit_Contact" onClick={() => get_Contact_details_by_id(data.id, "Primary")}>
                                                                  <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>}
                                                            </span>
                                                         </div>
                                                      </div>
                                                      <div class="contact-body p-2">
                                                         <div class="row">

                                                            <div class="col-md-12">
                                                               {data.phoneBusiness ? <img src="/images/icons/phone-icon2.svg" style={{ marginRight: "10px" }} alt="" /> : ""}
                                                               <span>{data.phoneBusiness ? data.phoneBusiness : ""}</span>
                                                            </div>
                                                            <div class="col-md-12">
                                                               {data.phoneMobile ? <img src="/images/icons/mobile-icon.svg" style={{ marginRight: "10px" }} alt="" /> : ""}
                                                               <span>{data.phoneMobile ? data.phoneMobile : ""}</span>
                                                            </div>
                                                            <div class="col-md-12">
                                                               {data.email ? <img src="/images/icons/message-icon2.svg" style={{ marginRight: "10px" }} alt="" /> : ""}
                                                               <span>{data.email ? data.email : ""}</span>
                                                            </div>
                                                            {/* <div class="col-md-7">
                                                            <img src="/images/icons/fax-icon.svg" alt=""/>
                                                            <span>{"N/A"}</span>
                                                         </div> */}
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>)) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span></div>
                                          }


                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="row mb-1">
                                 <div class="col-md-12">
                                    <div class="card p-3">
                                       <div class="contactmb">
                                          <div class="search position-relative align-self-start mb-1 mt-1 contactmb-1">
                                             {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/>
                                                <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt=""/>
                                                </a> */}
                                             <Search
                                                placeholderName="Search by customer name"
                                                onSearch={value => {
                                                   searchContactList(value);
                                                }}

                                             />
                                             <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a>
                                          </div>
                                          <div class="d-flex sort align-self-start ml-2 mt-1 mb-1 contactmb-2">
                                             {/* <div class="d-flex align-items-center align-self-start">
                                                   <span class="mr-2">Sort by :</span>
                                                   <div class="dropdown show mr-3 ">
                                                      <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Day</a>
                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                         <a class="dropdown-item" href="#">Week</a>
                                                         <a class="dropdown-item" href="#">Month</a>
                                                      </div>
                                                   </div>
                                                </div> */}
                                             <div class="d-flex align-items-center align-self-start">
                                                <span class="mr-2">Roles :</span>
                                                <div class="dropdown show mr-3 ">
                                                   <select class="form-control custome-select" id="selectedRole">
                                                      <option value="">---Select role---</option>
                                                      {

                                                         contactRole ? contactRole.sort((a, b) => a.name.localeCompare(b.name)).map((role, index) => {
                                                            //   console.log("roleslected="+roleslected);

                                                            return <option value={role.id}>{role.name}</option>


                                                         }) : <option value="">---Select role---</option>
                                                      }
                                                   </select>
                                                </div>
                                             </div>
                                             <div class="d-flex align-items-center align-self-start ml-3">

                                                <input type="checkbox" aria-label="Checkbox for following text input" id="inactiveContactList" name="inactiveCotact" />
                                                <span class="me-2"> &nbsp; Inactive Contacts</span>
                                             </div>
                                          </div>
                                          <div class="d-flex align-self-start mb-1 mt-1 contactmb-3">
                                             {roles.match("Technician") || roles.match("Inspector") ? "" : <a href="#" class="btn btn-success" data-toggle="modal" data-target="#addcontact" onClick={() => addContactForm()}>Add Contact</a>}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-12">
                                    <div class="card p-3">
                                       <div class="row">

                                          {
                                             contactLists ? contactLists.map((data, index) => (
                                                <div class="col-xl-6 col-lg-6 mb-4">
                                                   <div class="contact-card">
                                                      <div class="contact-card-header d-flex align-items-start justify-content-between p-3 border-bottom">
                                                         <div>
                                                            <span class="contact-name text-capitalize"><b>{data.firstName ? data.firstName : "N/A"} {data.lastName ? data.lastName : "N/A"}</b> </span>
                                                         </div>
                                                         <div>
                                                            <span class="job-lable text-capitalize">{data.roleName ? data.roleName : "N/A"}</span>
                                                            {roles.match("Technician") || roles.match("Inspector") ? "" : <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#edit_Contact" onClick={() => get_Contact_active_details_by_id(data.id, "Active_Inactive")}><img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a></span>}
                                                         </div>
                                                      </div>
                                                      <div class="contact-body p-2">
                                                         <div class="row">

                                                            <div class="col-12">
                                                               {data ? data.phoneBusiness ? <img src="/images/icons/phone-icon2.svg" style={{ marginRight: "10px" }} alt="" /> : "" : ""}
                                                               <span>{data ? data.phoneBusiness ? data.phoneBusiness : "" : ""}</span>
                                                            </div>
                                                            <div class="col-12">
                                                               {data ? data.phoneMobile ? <img src="/images/icons/mobile-icon.svg" style={{ marginRight: "10px" }} alt="" /> : "" : ""}
                                                               <span>{data ? data.phoneMobile ? data.phoneMobile : "" : ""}</span>
                                                            </div>
                                                            <div class="col-12">
                                                               {data ? data.email ? <img src="/images/icons/message-icon2.svg" style={{ marginRight: "10px" }} alt="" /> : "" : ""}
                                                               <span>{data ? data.email ? data.email : "" : ""}</span>
                                                            </div>
                                                            {/* <div class="col-12">
                                                            <img src="/images/icons/fax-icon.svg" alt=""/>
                                                            <span>{"N/A"}</span>
                                                         </div> */}
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>)) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                             </div>
                                          }
                                       </div>
                                    </div>
                                    <nav aria-label="Page navigation example">
                                       {totalContactItems > 0 ? <Pagination
                                          total={totalContactItems}
                                          itemsPerPage={ITEMS_CONTACT_PER_PAGE}
                                          currentPage={currentContactPage}
                                          //onPageChange={page => setCurrentPage(page)}
                                          onPageChange={handlePageContactChange}
                                       /> : ""}
                                       {/* <ul class="pagination mb-0">
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Previous">
                                                      <span aria-hidden="true">&laquo;</span>
                                                      <span class="sr-only">Previous</span>
                                                      </a>
                                                   </li>
                                                   <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Next">
                                                      <span aria-hidden="true">&raquo;</span>
                                                      <span class="sr-only">Next</span>
                                                      </a>
                                                   </li>
                                                </ul> */}
                                    </nav>
                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                              <div class="row">
                                 <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                                    <div class="card">
                                       <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                       <div class="d-flex justify-content-start align-items-center">
                                            <div class="search position-relative">
                                             <Search
                                                placeholderName="Search by VIN Number / Model"
                                                onSearch={value => {
                                                   searchList(value);
                                                }}

                                             />
                                             <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a>
                                             </div>
                                             {roles.match("Technician") || roles.match("Inspector") ? "" : <> <input type="checkbox"
                                                 onChange={(e) => activeInctive(e) }
                                                   id="inactive"
                                                   name="inactive"
                                                   style={{ marginLeft: "21px", marginTop: "0px" }} />
                                                   <span style={{ marginLeft: "10px", marginTop: "0px" }}>
                                                    Inactive records only
                                                   </span></>}
                                                   <div class="d-flex align-items-center align-self-start">
                                                <span class="ml-3 mr-2 pl-4">Vehicle Type :</span>
                                                <div class="dropdown show mr-3">
                                                   {/* <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                         <a class="dropdown-item" href="#">Tractor</a>
                                                         <a class="dropdown-item" href="#">Truck</a>
                                                         <a class="dropdown-item" href="#">Trailer</a>
                                                      </div> */}
                                                   <select class="form-control custome-select" id="vehicleTypeFilter1" value={selectedOption}
                                                      onChange={e => {
                                                         setVehicleTypes(e.target.value)
                                                         selectVehicle(e.target.value)
                                                         sessionStorage.setItem("vehicleTypeID", e.target.value)
                                                         //setVehicleTypes(this.value);
                                                         customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, e.target.value, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems",inactiveStatus);
                                                      }}>
                                                      <option value='' >Select</option>
                                                      {
                                                         vehicleType ? vehicleType.sort((a, b) => a.name.localeCompare(b.name)).map((vehicle, index) => (
                                                            <option value={vehicle.id}>{vehicle.name}</option>
                                                         )) : ""
                                                      }
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="d-flex sort align-self-start mt-1 mb-1 d-none">
                                             {/* <div class="d-flex align-items-center align-self-start">
                                                   <span class="mr-2">Sort by :</span>
                                                   <div class="dropdown show mr-3 ">
                                                      <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Day</a>
                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                         <a class="dropdown-item" href="#">Week</a>
                                                         <a class="dropdown-item" href="#">Month</a>
                                                      </div>
                                                   </div>
                                                </div> */}

                                          </div>
                                          <div class="d-flex align-self-start mb-1 mt-1">
                                             {roles.match("Technician") || roles.match("Inspector") ? "" : <a class="btn btn-success" onClick={() => redirect("/addVehicle/" + id)}>Add Vehicle</a>}
                                          </div>
                                       </div>
                                       <div class="card-body pt-0 pb-0">
                                          <div class="row">
                                             <div class="col-12">
                                                <div class="table-responsive  border-bottom">
                                                   <table class="table">
                                                      {/* <thead>
                                                            <tr>
                                                               <th>Vehicle Number</th>
                                                               <th>Fleet Owner Unit Number</th>
                                                               <th> VIN</th>
                                                               <th>Vehicle Type </th>
                                                               <th>Action</th>
                                                            </tr>
                                                         </thead> */}
                                                      <tr>
                                                         {headers.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                            <th
                                                               key={name}
                                                               onClick={() =>
                                                                  sortable ? onSortingChangeVehicle(field) : null
                                                               }
                                                               class={thStyle} style={inlineStyle}>
                                                               {name}

                                                               {sortingField && sortingField === field && (

                                                                  sortingOrder === "asc"
                                                                     ? "↓"
                                                                     : "↑"
                                                               )}

                                                            </th>
                                                         ))}
                                                      </tr>
                                                      <tbody>
                                                         {
                                                            vechileLists ? vechileLists.map((data, index) => (
                                                               <tr>
                                                                  <td>{data.unitNumber != '' ? data.unitNumber : "NA"}</td>
                                                                  <td>{data.vinNumber != '' ? data.vinNumber : "NA"} </td>
                                                                  <td>{data.vehicleTypeName != '' ? data.vehicleTypeName : "NA"}</td>
                                                                  <td>{data.make ? data.make : "NA"} - {data.model ? data.model : "NA"}</td>
                                                                  {/* <td>{data.model != '' ? data.model : "NA"}</td> */}
                                                                  {/* <td>{data.customerEmail}</td> */}
                                                                  <td style={{ "width": "50px" }}><a onClick={() => redirect("/vehicleDetails/" + data.id)} class="btn btn-outline-primary text-uppercase" id="vehicleD">View</a></td>
                                                               </tr>
                                                            )) : ""
                                                         }

                                                      </tbody>
                                                   </table>
                                                </div>
                                                {vechileLists ? vechileLists.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                </div>}
                                             </div>
                                          </div>
                                       </div>

                                       {vechileLists.length > 0 ?
                                          <div class="d-flex align-items-center justify-content-between p-3">
                                             <div class="d-flex">
                                                <div class="dropdown show mr-3 ">
                                                   <select class="form-control custome-select" className="btn gray-mid-color dropdown-toggle " id="vehicleDisplayPage1">
                                                      {
                                                         displayPage.map((data, index) => (
                                                            <option value={data}>{data}</option>
                                                         ))
                                                      }
                                                   </select>

                                                </div>
                                                <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE1 <= totalRecors ? currentPage * ITEMS_PER_PAGE1 : totalRecors} out of {totalRecors} Records Displayed</span>
                                             </div>
                                             <nav aria-label="Page navigation example">
                                                <Pagination
                                                   total={totalItems}
                                                   itemsPerPage={ITEMS_PER_PAGE1}
                                                   currentPage={currentPage}
                                                   //onPageChange={page => setCurrentPage(page)}
                                                   onPageChange={handlePageChange}
                                                />

                                             </nav>
                                          </div> : ""}
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane fade" id="pills-notes" role="tabpanel" aria-labelledby="pills-notes-tab">
                              <div class="row">
                                 <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                                    <div class="card">

                                       <div class="row mb-1">
                                          <div class="col-md-12">
                                             <div class="card">
                                                <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                   <div class="search position-relative align-self-start mb-1 mt-1">
                                                      {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/>
                                                <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt=""/>
                                                </a> */}
                                                      {/* <Search
                                                placeholderName="Vehicle No / Identification No / Model"
                                                onSearch={value => {
                                                   searchList(value);
                                                }}

                                             /> */}
                                                      {/* <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a> */}
                                                   </div>
                                                   <div class="d-flex sort align-self-start mt-1 mb-1">
                                                      {/* <div class="d-flex align-items-center align-self-start">
                                                   <span class="mr-2">Sort by :</span>
                                                   <div class="dropdown show mr-3 ">
                                                      <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Day</a>
                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                         <a class="dropdown-item" href="#">Week</a>
                                                         <a class="dropdown-item" href="#">Month</a>
                                                      </div>
                                                   </div>
                                                </div> */}
                                                      {/* <div class="d-flex align-items-center align-self-start">
                                                <span class="mr-2">Vehicle Type :</span>
                                                <div class="dropdown show mr-3">

                                                   <select class="form-control custome-select" value={selectedOption} onChange={e => {
                                                      setVehicleTypes(e.target.value)
                                                      selectVehicle(e.target.value)
                                                      sessionStorage.setItem("vehicleTypeID", e.target.value)
                                                      //setVehicleTypes(this.value);
                                                      customerVehicle(id, "1", ITEMS_PER_PAGE, vehicleName, e.target.value, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "displayItems");
                                                   }}>
                                                      <option value=''  >Select</option>
                                                      {
                                                         vehicleType ? vehicleType.map((vehicle, index) => (
                                                            <option value={vehicle.id}>{vehicle.name}</option>
                                                         )) : ""
                                                      }
                                                   </select>

                                                </div>
                                             </div> */}
                                                   </div>
                                                   <div class="d-flex align-self-start mb-1 mt-1">
                                                      {/* <!-- <button type="button" class="btn btn-success">Add Customer</button> --> */}
                                                      {roles.match("Technician") || roles.match("Inspector") ? "" : <a href="#" data-toggle="modal" data-target="#addNote" type="button" class="btn btn-success" onClick={addNote}>Add Note</a>}
                                                   </div>
                                                </div>
                                                <div class="card-body pt-0 pb-0">
                                                   <div class="row">
                                                      <div class="col-12">
                                                         <div class="table-responsive  border-bottom">
                                                            <table class="table">

                                                               <tr>
                                                                  {Notesheaders.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                                     <th
                                                                        key={name}
                                                                        onClick={() =>
                                                                           sortable ? onSortingChangeVehicle(field) : null
                                                                        }
                                                                        class={thStyle} style={inlineStyle}>
                                                                        {name}

                                                                        {sortingField && sortingField === field && (

                                                                           sortingOrder === "asc"
                                                                              ? "↓"
                                                                              : "↑"
                                                                        )}

                                                                     </th>
                                                                  ))}
                                                               </tr>

                                                               {/* <Tableheader
                              headers={headers}

                          /> */}
                                                               <tbody>
                                                                  {
                                                                     listNotes ? listNotes.map((data, index) => (
                                                                        <tr>
                                                                           <td style={{ width: "85%", verticalAlign: "baseline", whiteSpace: "break-spaces", paddingRight: "5%", lineHeight: "20px" }}>{data.note != '' ? data.note : 'NA'}</td>
                                                                           <td style={{ width: "10%", verticalAlign: "baseline", whiteSpace: "break-spaces" }}>{data.createTime ? moment(data.createTime).format('MM-DD-YYYY') : "--"} </td>


                                                                           {/* <td style={{ "width": "20px" }}><a  class="btn btn-outline-primary text-uppercase" id="vehicleD">View</a></td> */}
                                                                        </tr>
                                                                     )) : ""
                                                                  }

                                                               </tbody>

                                                            </table>

                                                         </div>

                                                      </div>
                                                      {listNotes.length > 0 ? listNotes.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                      </div>}
                                                   </div>
                                                </div>
                                                {listNotes.length > 0 ? <div class="d-flex align-items-center justify-content-between p-3">
                                                   <div class="d-flex">
                                                      <div class="dropdown show mr-3 ">
                                                         {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                   <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                      <a class="dropdown-item" href="#">25</a>
                                                      <a class="dropdown-item" href="#">50</a>
                                                   </div> */}
                                                         <select class="form-control custome-select" className="btn gray-mid-color dropdown-toggle " id="dipPageVal" onChange={displayPageChnageForNote} >
                                                            {
                                                               displayPageForNote.map((data, index) => (
                                                                  <option value={data}>{data}</option>
                                                               ))
                                                            }
                                                         </select>
                                                      </div>
                                                      <span class="d-flex align-items-center pagenation-mob">{currentNotPage * ITEMS_PER_PAGE_NOTES <= totalPageNotes ? currentNotPage * ITEMS_PER_PAGE_NOTES : totalPageNotes} out of {totalPageNotes} Records Displayed</span>
                                                   </div>
                                                   <nav aria-label="Page navigation example">
                                                      {totalPageNotes > 0 ? <Pagination
                                                         total={totalPageNotes}
                                                         itemsPerPage={ITEMS_PER_PAGE_NOTES}
                                                         currentPage={currentNotPage}
                                                         //onPageChange={page => setCurrentPage(page)}
                                                         onPageChange={handleNotesPageChange}
                                                      /> : ""}
                                                      {/* <ul class="pagination mb-0">
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Previous">
                                                      <span aria-hidden="true">«</span>
                                                      <span class="sr-only">Previous</span>
                                                      </a>
                                                   </li>
                                                   <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Next">
                                                      <span aria-hidden="true">»</span>
                                                      <span class="sr-only">Next</span>
                                                      </a>
                                                   </li>
                                                </ul> */}
                                                   </nav>
                                                </div> : ""}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="tab-pane fade" id="pills-doc" role="tabpanel" aria-labelledby="pills-doc-tab">
                              <div class="row">
                                 <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                                    <div class="card">
                                       <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                          <div class="search position-relative align-self-start mb-1 mt-1">
                                             {/* <input type="search" id="gsearch" name="gsearch" placeholder="Vehicle No / Identification No / Model" class="pl-5"/>
                                                <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt=""/>
                                                </a> */}
                                             <Search
                                                placeholderName="Search by Title / Type"
                                                onSearch={value => {
                                                   searchDocsList(value);
                                                }}

                                             />
                                             <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a>
                                          </div>
                                          <div class="d-flex sort align-self-start mt-1 mb-1">


                                             {roles.match("Technician") || roles.match("Inspector") ? "" :
                                                <button id="sendtocustomerDisabled" class="btn btn-success" data-toggle="modal" data-target="#myModal" onClick={openModal}>Send to Customer</button>
                                                // <a onClick={() => sendtocustomer()} class="btn btn-success" style={{"margin-left":"15px"}} >Send to Customer</a>
                                             }
                                             {/* <div class="d-flex align-items-center align-self-start">
                                                   <span class="mr-2">Sort by :</span>
                                                   <div class="dropdown show mr-3 ">
                                                      <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Day</a>
                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                         <a class="dropdown-item" href="#">Week</a>
                                                         <a class="dropdown-item" href="#">Month</a>
                                                      </div>
                                                   </div>
                                                </div> */}
                                             {/* <div class="d-flex align-items-center align-self-start">
                                                   <span class="mr-2">Vehicle Type :</span>
                                                   <div class="dropdown show mr-3">
                                                      <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                         <a class="dropdown-item" href="#">Tractor</a>
                                                         <a class="dropdown-item" href="#">Truck</a>
                                                         <a class="dropdown-item" href="#">Trailer</a>
                                                      </div>
                                                   </div>
                                                </div> */}
                                          </div>
                                          {/* <!-- <div class="d-flex align-self-start mb-1 mt-1">
                                                <a href="#" class="btn btn-success">Add Documents</a>
                                                </div> --> */}
                                       </div>
                                       <div class="card-body pt-0 pb-0">
                                          <div class="row">
                                             <div class="col-12">
                                                <div class="table-responsive border-top border-bottom">
                                                   <table class="table">
                                                      <thead>
                                                         <tr>
                                                            {docheaders.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                               <th
                                                                  key={name}
                                                                  onClick={() =>
                                                                     sortable ? onSortingChangeDocument(field) : null
                                                                  }
                                                                  class={thStyle} style={inlineStyle}>
                                                                  {name}

                                                                  {sortingField && sortingField === field && (

                                                                     sortingOrder === "asc"
                                                                        ? "↑"
                                                                        : "↓"
                                                                  )}

                                                               </th>
                                                            ))}
                                                         </tr>
                                                      </thead>

                                                      <tbody>
                                                         {
                                                            documentList ? documentList.map((data, index) => (
                                                               <tr>
                                                                  <td style={{ width: "3%", textAlign:"center" }}>
                                                                     <input type="checkbox" name="documentIds" onChange={(event) => sendtocustomerCheck(data.id)} value={data.id} />
                                                                  </td>
                                                                  <td style={{ width: "30%",textAlign:"left", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px", wordWrap: "anywhere" }}>{data.documentSubCategoryName != '' ? data.documentSubCategoryName : '--'} </td>
                                                                  <td style={{ width: "30%",textAlign:"left", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px", wordWrap: "anywhere" }}>{data.title != '' ? data.title : '--'}</td>
                                                                  {/* <td>{data.documentCategoryName!=''?data.documentCategoryName:'NA'} </td> */}
                                                                  {/* <td style={{ width: "auto", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px", minWidth: "100px" }}>{data.documentDate != '' ? data.documentDate : 'NA'}</td> */}
                                                                  <td style={{ width: "auto", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px", minWidth: "110px" }}>{data.expiryDate != '' ? moment(data.expiryDate).format('MM-DD-YYYY') : 'N/A'}</td>
                                                                  <td style={{ width: "15%", lineHeight: "18px", paddingRight: "18px", }}>{data.latestSharedOn!=null ? hour_format=='24'?moment.utc(data.latestSharedOn).local().format('MM-DD-YYYY HH:mm:ss'):moment.utc(data.latestSharedOn).local().format('MM-DD-YYYY hh:mm:ss A') : "--"}</td>
                                                                  <td style={{ width: "50px" }}><a class="btn btn-outline-primary text-uppercase" onClick={() => document_details(data.id, data.filePath)}>View</a> {roles.match(Manager) || roles.match(Owner) ? <a onClick={() => deleteDocuments(data.id, data.unitNumber != '' ? data.unitNumber : '', data.title != '' ? data.title : '')} class="btn btn-outline-primary text-uppercase">Delete</a> : ""}
                                                                     &nbsp;{data.documentCategoryName === "Inspection Documents" ? "" : roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/editDocument/" + data.fkCustomerId + "/" + data.fkVehicleId + "/" + data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>}
                                                                  </td>
                                                               </tr>
                                                            )) : ""
                                                         }

                                                      </tbody>
                                                   </table>
                                                </div>
                                                {documentList ? documentList.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                </div>}
                                             </div>
                                          </div>
                                       </div>

                                       {documentList.length > 0 ? <div class="d-flex align-items-center justify-content-between p-3">
                                          <div class="d-flex">
                                             <div class="dropdown show mr-3 ">
                                                <select class="form-control custome-select" className="btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_docs_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   {
                                                      displayDocsPage.map((data, index) => (
                                                         <option value={data}>{data}</option>
                                                      ))
                                                   }
                                                </select>

                                             </div>
                                             <span class="d-flex align-items-center pagenation-mob">{currentDocsPage * ITEMS_PER_PAGE_DOCS <= totalDocsRecors ? currentDocsPage * ITEMS_PER_PAGE_DOCS : totalDocsRecors} out of {totalDocsRecors} Records Displayed</span>
                                          </div>
                                          <nav aria-label="Page navigation example">
                                             {totalDocsRecors > 0 ? <Pagination
                                                total={totalDocsRecors}
                                                itemsPerPage={ITEMS_PER_PAGE_DOCS}
                                                currentPage={currentDocsPage}
                                                //onPageChange={page => setCurrentPage(page)}
                                                onPageChange={handlePageChangedocument}
                                             /> : ""}
                                             {/* <ul class="pagination mb-0">
                                                      <li class="page-item">
                                                         <a class="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                            <span class="sr-only">Previous</span>
                                                         </a>
                                                      </li>
                                                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                      <li class="page-item">
                                                         <a class="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                            <span class="sr-only">Next</span>
                                                         </a>
                                                      </li>
                                                      </ul> */}
                                          </nav>
                                       </div> : ""}

                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
            {/* 20 july 2020 modal  */}
            <div id="myModal" class="modal fade" role="dialog">
               <div class="modal-dialog">


                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Send to Customer</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeCustomerModel">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body sc-mod-pop">
                        <div class="row">
                           <div class="col-md-12">
                              {/* <p id="emailErrorCustomerDetails" class="error" >Please enter one or more emails to send the documents to.</p> */}
                              <input type="checkbox" class="form-check-input" name="contactSelectAll" onClick={(e) => SelectAllContactList(e, setChecked)} />
                          
                              <label style={{ marginLeft: "26px", fontWeight: "bold", marginBottom: "11px" }}>Select All</label></div>
                           {

                              CustomerContactList.length > 0 ? CustomerContactList.map((data, index) => {
                                 return (<>
                                    <div class="col-md-12">
                                       <div class="form-check">
                                          <input type="checkbox" class="form-check-input" name="contactListemails" value={`${data.email}`} onClick={(e) => CustomercontactId(e, setChecked, checked)} />
                                          <span style={{ marginLeft: "20px" }}>{data.firstName ? data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1) : ""}</span>
                                       </div>

                                    </div>

                                 </>)
                              }) :
                                 <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                 </div>}

                                 <lable style={{paddingLeft:"12px", display:"flex", paddingTop:"20px"}}>Please enter one or more emails to send the documents to.</lable>
                                 <AddRemoveInputField setInputList={setInputEmailList} inputList={emailforsendtoct}/>
                                
                           {/* <div class="col-md-12 pt-4">
                              <label for="exampleInputEmail1">Additional email</label>
                              <input type="text" class="form-control custome-input" id="additionalEmail" placeholder="Enter additional email" />
                           </div> */}
                        </div>

                     </div>

                     <div class="modal-footer">
                        <button type="button" class="btn btn-primary" onClick={() => Sendtocustomer(checked, 'customerDocuments',emailforsendtoct)} >Submit</button>
                     </div>
                  </div>

               </div>
            </div>
            {/* 20 july 2020 modal  */}
            {/* <!-- Modal --> */}
            <div class="modal fade" id="edit_Contact" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <form onSubmit={handleSubmit3(updateContactAPI)}>
                     <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel">Edit Contact</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="edit_close2">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <div class="row">
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">First Name <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" maxLength="20" minLength="2" defaultValue="edit_fristName" id="edit_fristName" placeholder="" onChange={e => setFieldName(e.target.value)}    {...register3("edit_Firstname", {
                                       required: "First name is required",
                                       maxLength: {
                                          value: 20,
                                          message: "First name should n't exceed the max length 20 characters"
                                       },
                                       minLength: {
                                          value: 2,
                                          message: "First name at least 2 characters"
                                       },
                                    })} />
                                    <ErrorMessage
                                       errors={errors3}
                                       name="edit_Firstname"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Last Name <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="edit_lastName" maxLength="20" minLength="2" defaultValue="edit_lastName" placeholder="" onChange={e => setFieldName(e.target.value)}    {...register3("edit_Lastname", {
                                       required: "Last name is required",
                                       maxLength: {
                                          value: 20,
                                          message: "Last name should n't exceed the max length 20 characters"
                                       },
                                       minLength: {
                                          value: 2,
                                          message: "Last name at least 2 characters"
                                       },
                                    })} />
                                    <ErrorMessage
                                       errors={errors3}
                                       name="edit_Lastname"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Email Address <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="edit_email" placeholder="" defaultValue="edit_lastName@g.com" onChange={e => setFieldName(e.target.value)} {...register3("edit_companyEmail", {
                                       required: "Email is required",
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "Invalid email address"
                                       }
                                    })} />
                                    <ErrorMessage
                                       errors={errors3}
                                       name="edit_companyEmail"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Desk number <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" defaultValue="111-111-1111" id="edit_phone" aria-describedby="emailHelp" {...register3("edit_Phone", {
                                       required: "Desk number is required",
                                       maxLength: {
                                          value: 12,
                                          message: "Desk number should be 10 digits"
                                       },
                                       minLength: {
                                          value: 12,
                                          message: "Desk number should be 10 digits"
                                       }
                                    })} />
                                    <ErrorMessage
                                       errors={errors3}
                                       name="edit_Phone"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Phone Number </label>
                                    <input type="text" class="form-control custome-input" id="edit_mobile" aria-describedby="emailHelp" {...register3("edit_mobileNumber", {
                                       maxLength: {
                                          value: 12,
                                          message: "Phone number should be 10 digits"
                                       },
                                       minLength: {
                                          value: 12,
                                          message: "Phone number should be 10 digits"
                                       }

                                    })} />
                                    <ErrorMessage
                                       errors={errors3}
                                       name="edit_mobileNumber"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleFormControlSelect1">Role in Company <span class="star">*</span></label>
                                    <select class="form-control custome-select" id="edit_role" {...register3("roleCompany", {
                                       required: "Role is required",
                                    })}>
                                       <option value="">---Select role---</option>
                                       {

                                          contactRole ? contactRole.map((role, index) => {
                                             //   console.log("roleslected="+roleslected);
                                             if (roleslected == role.name) {
                                                return <option value={role.id} selected>{role.name}</option>
                                             } else {
                                                return <option value={role.id}>{role.name}</option>
                                             }
                                             // return  <option value={role.id} selected={roleslected==role.name} >{role.name}</option>

                                          }) : <option value="">---Select role---</option>
                                       }
                                    </select>
                                    <ErrorMessage
                                       errors={errors3}
                                       name="roleCompany"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control custome-select" id="edit_status">
                                       <option value="">---Select role---</option>
                                       <option value="Active">Active</option>
                                       <option value="Inactive">Inactive</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal" id="edit_close">Close</button>
                           <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>

            <div class="modal fade" id="addNote" tabindex="-1" aria-labelledby="addnote" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">

                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Note</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="note_close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <label for="addnotes">Add note<span class="star">*</span> :</label>

                        <textarea id="noteVal" name="addnotes" rows="4" cols="50" maxLength="300">

                        </textarea>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" onClick={() => saveNotes()} >Save changes</button>
                     </div>

                  </div>
               </div>
            </div>
            <div class="modal fade" id="addcontact" tabindex="-1" aria-labelledby="addcontact" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <form onSubmit={handleSubmit2(addContactAPI)}>
                        <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel">Add Contacts</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="contact_close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <div class="row">
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">First Name <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="contact_FirstName" placeholder="" onChange={e => setFieldName(e.target.value)}    {...register2("Firstname", {
                                       required: "First name is required",
                                    })} />
                                    <ErrorMessage
                                       errors={errors2}
                                       name="Firstname"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Last Name <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="contact_Lastname" onChange={e => setFieldName(e.target.value)}    {...register2("Lastname", {
                                       required: "Last name is required",
                                    })} />
                                    <ErrorMessage
                                       errors={errors2}
                                       name="Lastname"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Email Address <span class="star">*</span></label>
                                    <input type="email" class="form-control custome-input" id="contact_Email" placeholder="" onChange={e => setFieldName(e.target.value)} {...register2("CompanyEmail", {
                                       required: "Email is required",
                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "Invalid email address"
                                       }
                                    })} />
                                    <ErrorMessage
                                       errors={errors2}
                                       name="CompanyEmail"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Desk number <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="contcat_deskphone" aria-describedby="emailHelp" {...register2("contcat_deskphone", {
                                       required: "Desk number is required",
                                       maxLength: {
                                          value: 12,
                                          message: "Desk number should be 10 digits"
                                       },
                                       minLength: {
                                          value: 12,
                                          message: "Desk number should be 10 digits"
                                       },

                                    })} />
                                    <ErrorMessage
                                       errors={errors2}
                                       name="contcat_deskphone"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Phone Number</label>
                                    <input type="text" class="form-control custome-input" id="contact_mobile" aria-describedby="emailHelp" {...register2("MobileNumber", {
                                       // required: "Mobile number is required",
                                       maxLength: {
                                          value: 12,
                                          message: "Phone number should be 10 digits"
                                       },
                                       minLength: {
                                          value: 12,
                                          message: "Phone number should be 10 digits"
                                       },
                                    })} />
                                    <ErrorMessage
                                       errors={errors2}
                                       name="MobileNumber"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleFormControlSelect1">Role in Company <span class="star">*</span></label>
                                    <select class="form-control custome-select" id="contact_role" {...register2("roleCompany", {
                                       required: "Role is required",
                                    })}>
                                       <option value="">---Select role---</option>
                                       {
                                          contactRole ? contactRole.map((role, index) => (
                                             <option value={role.id}>{role.name}</option>
                                          )) : <option value=""></option>
                                       }
                                    </select>
                                    <ErrorMessage
                                       errors={errors2}
                                       name="roleCompany"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                           <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="editcustomer" tabindex="-1" aria-labelledby="editcustomer" aria-hidden="true">

               <div class="modal-dialog">
                  <div class="modal-content">

                     <div class="modal-header">
                        <h5 class="modal-title" id="editcustomer">Edit Customer</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body p-2">
                        <form onSubmit={handleSubmit4(update_Customer_fun)}>

                           <div class="card">
                              <div class=" p-3 mt-2 mb-2">
                                 <h4>Customer Information</h4>
                              </div>
                              <div class="card-body pt-0 pb-2">
                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Customer Name <span class="star">*</span></label>
                                          <input type="text" class="form-control" id="customername_e" placeholder="" maxLength="80" minLength="2" defaultValue='name'   {...register4("customername", {
                                             required: "Customer name is required",
                                             maxLength: {
                                                value: 80,
                                                message: "Customer Name shouldn't exceed the max length 80 characters"
                                             },
                                             minLength: {
                                                value: 2,
                                                message: "Customer Name atleast 2 characters"
                                             }
                                          })} />
                                          <ErrorMessage
                                             errors={errors4}
                                             name="customername"
                                             class="invalid-feedback"
                                             render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type} class="error">{message}</p>
                                                   ))
                                                   : null;
                                             }}
                                          />
                                          {/* <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp"/> */}
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Tax-<span class="lableUpper">ID</span></label>
                                          <input type="text" class="form-control custome-input" id="taxId_e" defaultValue='taxId_e' aria-describedby="emailHelp" />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Address Line 1 <span class="star">*</span></label>
                                          <input type="text" class="form-control custome-input" id="addressLine1_e" defaultValue='hyd' maxLength="50" onChange={e => setFieldName(e.target.value)}    {...register4("address1", {
                                             required: "Address line 1 is required",
                                          })} />
                                          <ErrorMessage
                                             errors={errors4}
                                             name="address1"
                                             class="invalid-feedback"
                                             render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type} class="error">{message}</p>
                                                   ))
                                                   : null;
                                             }}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Address Line 2</label>
                                          <input type="text" class="form-control custome-input" id="addressLine2_e" maxLength="50" defaultValue={addressLine2Value} />
                                       </div>
                                    </div>

                                 </div>
                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">City <span class="star">*</span></label>
                                          <input type="text" class="form-control custome-input" id="city_e" minLength="2" maxLength="50" defaultValue='hyd' onChange={e => setFieldName(e.target.value)}    {...register4("City", {
                                             required: "City is required",
                                             maxLength: {
                                                value: 50,
                                                message: "City shouldn't exceed the max length 50 characters"
                                             },
                                             minLength: {
                                                value: 2,
                                                message: "City atleast 2 characters"
                                             },
                                             // pattern: {
                                             //    value: /^[a-zA-Z\s]*$/,
                                             //    message: "City is allow only character's"
                                             // }
                                          })} />
                                          <ErrorMessage
                                             errors={errors4}
                                             name="City"
                                             class="invalid-feedback"
                                             render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type} class="error">{message}</p>
                                                   ))
                                                   : null;
                                             }}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleFormControlSelect1">State <span class="star">*</span></label>

                                          {/* <select id="inputState"   class="form-select  custome-select" name="state">
                                                <option selected value=' '>Select State</option>
                                                {
                                                    states.map((state, index) => (
                                                        <option value={state.id}>{state.name}</option>
                                                    ))
                                                }


                                          </select> */}
                                          <select class="form-control custome-select" id="state_e" defaultValue={"stateIdC"}  >

                                             <option value="">Select state</option>
                                             {
                                                states ? states.map((state, index) => (
                                                   <option value={state.id} selected={state.id == stateIdC ? "slected" : ''}>{state.name}</option>
                                                )) : <option value=""></option>
                                             }
                                          </select>

                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Zip Code <span class="star">*</span></label>
                                          <input type="text" class="form-control custome-input" id="zipcode_e" defaultValue='23456' onChange={e => setFieldName(e.target.value)}    {...register4("zip", {
                                             required: "Zip code is required",
                                             pattern: {
                                                value: /^[0-9]{5}(-[0-9]{1})?$/,
                                                message: "Zip code should be 5 digits"
                                             },
                                          })} maxLength="5" minLength="5" />
                                          <ErrorMessage
                                             errors={errors4}
                                             name="zip"
                                             class="invalid-feedback"
                                             render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type} class="error">{message}</p>
                                                   ))
                                                   : null;
                                             }}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Phone Number <span class="star">*</span></label>
                                          <input type="text" class="form-control custome-input" id="yourphone2" defaultValue="123-456-7890" onChange={e => setFieldName(e.target.value)} {...register4("Phone", {
                                             required: "Phone number is required",
                                             maxLength: {
                                                value: 12,
                                                message: "Phone number should be 10 digits"
                                             },
                                             minLength: {
                                                value: 12,
                                                message: "Phone number should be 10 digits"
                                             },

                                          })} />
                                          <ErrorMessage
                                             errors={errors4}
                                             name="Phone"
                                             class="invalid-feedback"
                                             render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type} class="error">{message}</p>
                                                   ))
                                                   : null;
                                             }}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Fax Number </label>
                                          <input type="text" class="form-control custome-input" id="faxnumber_e" onChange={e => faxvalidation(e)} ></input>
                                          <span class="error" id="faxerror">Fax number should be 10 digits</span>
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Email Address <span class="star">*</span></label>
                                          <input type="text" class="form-control" id="emailAddress" defaultValue='dotshow@mailnatoe.com' placeholder=""  {...register4('email', { required: true })} />
                                          {errors4.email && errors4.email.type === "required" && <div class="error">Email is required</div>}
                                          {errors4.email && errors4.email.type === "pattern" && <div class="error">Invalid email address</div>}
                                          {/* , pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ */}
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1">Web Address URL</label>
                                          <input type="text" class="form-control custome-input" aria-describedby="emailHelp" id="websiteURL" value={val} onChange={validate} />
                                          <span class="error">{err}</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>


                           <div class="card">
                              <div class=" p-3 mt-2 mb-2">
                                 <h4>ID Numbers</h4>
                              </div>
                              <div class="card-body pt-0 pb-2">
                                 <div class="row">
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1" ><span class="lableUpper">DOT</span> Number <span class="star">*</span></label>
                                          <input type="text" class="form-control custome-input" id="dotnumber_e" defaultValue='we345' maxLength="20" placeholder="" onChange={e => setFieldName(e.target.value)}  {...register4("dotnumber", {
                                             required: "DOT number is required",

                                          })} />

                                          <ErrorMessage
                                             errors={errors4}
                                             name="dotnumber"
                                             class="invalid-feedback"
                                             render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type} class="error">{message}</p>
                                                   ))
                                                   : null;
                                             }}
                                          />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1"><span class="lableUpper">MC</span> Number</label>
                                          <input type="text" class="form-control custome-input" id="Mcnumber_e" aria-describedby="emailHelp" maxLength="20" />
                                       </div>
                                    </div>
                                    <div class="col-6">
                                       <div class="form-group">
                                          <label for="exampleInputEmail1"><span class="lableUpper">CT</span> Number</label>
                                          <input type="text" class="form-control custome-input" id="ctnumber_e" aria-describedby="emailHelp" maxLength="20" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer pr-0 pb-0">
                              <button type="button" class="btn btn-secondary" id="btnclose" data-dismiss="modal">Close</button>
                              {/* <button type="submit" class="btn btn-primary">Save changes</button> */}
                              <button type="submit" class="btn btn-primary"   >Save changes</button>
                           </div>
                        </form>
                     </div>


                  </div>
               </div>
            </div>

         </div>
         <div>

         </div>
         <div class="modal fade" id="uploadPicModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                     <button type="button" class="close" data-dismiss="modal" id="btncloseImage">
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div class="modal-body">
                     <input type="file" class="w-100 p-3 mb-3" onChange={onChange} accept="image/jpeg,image/png,image/jpeg" />
                     <div class="row mb-2" id="selectedImage">
                        <div class="col-12">

                           <div class="image-upload-plg">

                              {/* <Cropper
                                 style={{ height: 320, width: "100%" }}
                                 zoomTo={0.5}
                                 initialAspectRatio={4 / 3}
                                 preview=".img-preview"
                                 src={image}
                                 viewMode={3}

                                 minCropBoxHeight={10}
                                 minCropBoxWidth={10}
                                 background={false}
                                 responsive={true}
                                 autoCropArea={1}
                                 checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                 onInitialized={(instance) => {
                                    setCropper(instance);
                                 }}
                                 guides={true}
                              /> */}

                              <Cropper
                                 style={{ height: 300, width: "100%" }}
                                 zoomTo={0}
                                 aspectRatio={1}
                                 guides={false}
                                 dragMode={"move"}
                                 src={image}
                                 onInitialized={(instance) => {
                                    setCropper(instance);
                                 }}
                              />
                           </div>


                        </div>

                        <div>
                           <div className="box" style={{ width: "400px", float: "right", display: "none" }}>
                              <h1>Preview</h1>
                              <div
                                 className="img-preview"

                              />
                           </div>
                           {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div> */}
                        </div>
                     </div>
                     <div class="row mb-2" id="DB_Image">
                        <div className="m-auto mt-2">
                           <img src={image} style={{ width: "240px", height: "240px", marginTop: "20px" }} ></img>

                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btncloseImage">Close</button>
                     <button type="button" class="btn btn-primary" id="uploadImageDisable" onClick={uploadImage}>Save changes</button>
                  </div>
               </div>
            </div>
         </div>

      </>
   )
};

CustomerDetails.propTypes = {};

CustomerDetails.defaultProps = {};

export default CustomerDetails;
