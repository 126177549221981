
import $ from "jquery";
import { conactUsAPI } from "../modules/constant";

///////////For production/////////////////////////////////////////
import { Cookies } from 'react-cookie';
import moment from 'moment';
import Swal from "sweetalert2";
var tenantName = localStorage.getItem("realm");
//DEV url////
// var key_url="http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth/realms/";
///QA url////
// var key_url="http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth/realms/";
//productionurl/////
// var key_url="https://auth.dotstow.com/auth/realms/";

function ExpirytimeCheck() 
{

  const cookies = new Cookies();
  const RefreshToken = cookies.get("_RK");
  const Expirytime = cookies.get("_ET");

  //////////validate the life span of the token with current time///////////

  var currentTime = new moment(new Date()).unix();


  if (currentTime >= Expirytime) {


    // fetch("http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth/realms/" + tenantName + '/protocol/openid-connect/token', {
    // fetch("http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth/realms/" + tenantName + '/protocol/openid-connect/token', {
    fetch("https://auth.dotstow.com/auth/realms/" + tenantName + '/protocol/openid-connect/token', {
      method: 'POST',
      headers: {
        //'Authorization': 'Bearer token',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: "client_id=dotstow_ui&grant_type=refresh_token&refresh_token=" + RefreshToken
    }).then((response) => response.json())

      .then((responseData) => {
        // cookies.remove("_AK");
        // cookies.remove("_RK");
        // cookies.remove("_ET");
        cookies.set("_AK", responseData.access_token, { "path": "/", maxAge: "1704085200" });
        cookies.set("_RK", responseData.refresh_token, { "path": "/", maxAge: "1704085200" });


        let expiresInMS = responseData.expires_in;
        const ms = expiresInMS * 1000;   // let say expiration in 60 s / 1 min
        let now = new Date();
        let expiresDateTime = new Date(now.getTime() + ms);

        var expTime = new moment(expiresDateTime).unix();
        cookies.set("_ET", expTime, { "path": "/", maxAge: "1704085200" });
        // localStorage.setItem("_AT", responseData.access_token);
        localStorage.setItem("_ET", expTime);
        window.location.reload(false);
        //  setCookie('secureKey', responseData.ac);
      }).catch(err => { console.log(err) })




  } else 
  {

    handleSessionExpiry();
   
  }
}

function handleSessionExpiry() {
  const cookies = new Cookies();
  cookies.remove("_AK");
  cookies.remove("_RK");
  cookies.remove("_ET");
  sessionStorage.clear();
  localStorage.clear();
  window.location.replace("login");
}

class Service {


  //////////////////////get customer list from cloud//////////////////////////
  listofcustomers(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/customer/listCustomers", requestJson)
      .then(response => {

        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/customer/listCustomers`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }

      })
      .then(responseData => {
        return responseData;
      })
      .catch(error => {


        //  if(error.messag.match("Unexpected token < in JSON at position 0"))
        //  {
        //   sessionStorage.clear();
        //   localStorage.clear();
        //  //navigate("/login");
        //    window.location.href="/login";
        //  }
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }
  //////////////////////get states from cloud//////////////////////////
  getstates(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/customer/getStates", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/customer/getStates`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {


        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  //////////////////////get contact roles from cloud//////////////////////////
  getcontactRoles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/contact/getContactRoles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/contact/getContactRoles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {


        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  //////////////////////add customer from cloud//////////////////////////
  add_customer(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/customer/addCustomer", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/customer/addCustomer`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  //////////////////////get customer details from cloud//////////////////////////
  getCustomer_details(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/customer/getCustomerDetails", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/customer/getCustomerDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////get vechill Type from cloud//////////////////////////
  getVehicles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/getVehicleTypes", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/getVehicleTypes`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  //////////////////////update customer through the API//////////////////////////
  update_Customer_Details(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/customer/updateCustomer", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/customer/updateCustomer`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////getvechileList through the API//////////////////////////
  vechile_list(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/listVehicles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/listVehicles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////getContactlist through the API//////////////////////////
  getContactList(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/contact/listContact", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/contact/listContact`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////Add vehicle through the API//////////////////////////
  addVehicle(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/addVehicle", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/addVehicle`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  updateVehicle(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/updateVehicle", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/updateVehicle`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////GetTruckTypes through the API//////////////////////////
  getTruckType(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/getTruckTypes", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/getTruckTypes`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////getTrailerSubTypes Sub through the API//////////////////////////
  getTrailerSubTypes(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/getTrailerSubTypes", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/getTrailerSubTypes`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////getTankerSubTypes Sub through the API//////////////////////////
  getTankerSubTypes(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/getTankerSubTypes", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/getTankerSubTypes`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }


  //////////////////////Add contact through the API//////////////////////////
  addContacts(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/contact/addContact ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/contact/addContact`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////Add contact through the API//////////////////////////
  // listFleetOwnerVehicles
  customer_vechile_list(requestJson) {
    // return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/listFleetOwnerVehicles ", requestJson)
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/getCustomerVehicles ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/getCustomerVehicles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }


  // listFleetOwnerVehicles
  customer_vechile_list_fleetowner(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/listFleetOwnerVehicles ", requestJson)
      // return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/getCustomerVehicles ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/listFleetOwnerVehicles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////Update contact through the API//////////////////////////
  update_contactAPI(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/contact/updateContact", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/contact/updateContact`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////Get Vehicle through the API//////////////////////////
  getVehicle_Details(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/getVehicleDetails", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/getVehicleDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////getListOfDocuments through the API//////////////////////////
  getListOfDocuments(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/listDocuments ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/listDocuments`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////Get customer document through the API//////////////////////////
  getCustomerDocuments(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getCustomerDocuments ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getCustomerDocuments`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////getDocumentDetails through the API//////////////////////////
  getDocumentDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getDocumentDetails ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getDocumentDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  getDocumentPrint(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/printDocuments ", requestJson)
      .then(response => {

        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/printDocuments`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////getuserDocumentDetails through the API//////////////////////////
  getUserDocumentDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/getUserDocument", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/getUserDocument`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////addd documents through the API//////////////////////////
  getDocumentDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getDocumentDetails ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getDocumentDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////get documents category through the API//////////////////////////
  getDocumentCategories(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getDocumentCategories ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getDocumentCategories`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////add documents  through the API//////////////////////////
  addDocuments(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/addDocument", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/addDocument`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////update documents  through the API//////////////////////////
  updatedocument(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/updateDocument", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/updateDocument`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////get vehicle documents  through the API//////////////////////////
  getVehicle_Docs(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getVehicleDocuments", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getVehicleDocuments`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////add inspection through the API//////////////////////////
  addInspection(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/addInspection", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/addInspection`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  //////////////////////certify inspection through the API//////////////////////////
  certifyInspection(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/certifyInspection", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/certifyInspection`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  //////////////////////get inspection type through the API//////////////////////////
  getInspectionTypeS(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/getInspectionTypes", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/getInspectionTypes`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////get inspection List through the API//////////////////////////
  getInspectionList(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/listInspections", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/listInspections`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  getInspectionDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/getInspection", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/getInspection`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  getInspectionDocumentDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/getInspectionDocument", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/getInspectionDocument`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  ////////////////////get///////////////////////////////////
  getInspectionCountByType(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/dashboard/getInspectionCountByType", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/dashboard/getInspectionCountByType`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  ////////////////////get///////////////////////////////////
  dashBoardAggregations(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/dashboard/dashBoardAggregations", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/dashboard/dashBoardAggregations`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  ////////////////////get///////////////////////////////////
  getFleetDashBoardAggregations(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/dashboard/getFleetDashBoardAggregations", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/dashboard/getFleetDashBoardAggregations`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  ////////////////////get///////////////////////////////////
  atgarageInspectionForinspection(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/listOfAssginedVehicles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/listOfAssginedVehicles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  ////////////////////get///////////////////////////////////
  upcomingremiders(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/reminder/listUpcomingReminders", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/reminder/listUpcomingReminders`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  ////////////////////get///////////////////////////////////
  listofDues(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/reminder/listDue", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/reminder/listDue`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }


  ////////////////////assigned Vehicles///////////////////////////////////
  listOfAssginedVehicles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/listOfAssginedVehicles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/listOfAssginedVehicles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  listCompletedVehicles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/reminder/listCompletedVehicles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/reminder/listCompletedVehicles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  ////////////////////assigned Vehicles///////////////////////////////////
  getExpiringDocuments(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getExpiringDocuments ", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getExpiringDocuments`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //////////////////////Add Dispatcher through the API//////////////////////////
  addUser(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/addUser", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/addUser`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  updateUser(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/updateUser", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/updateUser`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }



  getInspectionDocumentSubCategories(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getInspectionDocumentSubCategories", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getInspectionDocumentSubCategories`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  listVendors(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vendor/listVendors", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vendor/listVendors`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  getVendorDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vendor/getVendorDetails", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vendor/getVendorDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  assignToGarage(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/assignToGarage", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/assignToGarage`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  Updateassigntogarage(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/updateAssignToGarage", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/updateAssignToGarage`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  listUsersByRole(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/listUsersByRole", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/listUsersByRole`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  getVehicleDocumentSubCategories(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getVehicleDocumentSubCategories", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getVehicleDocumentSubCategories`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  addVendor(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vendor/addVendor", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vendor/addVendor`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  getAccountType(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/getAccountType", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/getAccountType`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  getInspectionDocumentSubCategories(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/getInspectionDocumentSubCategories", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/getInspectionDocumentSubCategories`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  getInspectionTypeForAssignToGarage(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/getInspectionTypeForAssignToGarage", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/getInspectionTypeForAssignToGarage`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  getUserDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/getUserDetails", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/getUserDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  addInspectionDocument(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/addInspectionDocument", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/addInspectionDocument`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }



  DriverRole(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/listRoles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/listRoles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  listRoles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/listStaffRoles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/listStaffRoles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  remindersAll(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/reminder/listReminderVehicles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/reminder/listReminderVehicles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  listReminderVehicles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/reminder/listReminderVehicles", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/reminder/listReminderVehicles`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  listAllGarageReminders(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/reminder/listAllGarageReminders", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/reminder/listAllGarageReminders`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  listStaff(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/listStaff", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/listStaff`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  resetuserPassword(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/resetPassword", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/resetPassword`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  getUserDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/getUserDetails", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/getUserDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }
  listUsers(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/listUsers", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/listUsers`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }
  customerCount(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/dashboard/customerCount", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/dashboard/customerCount`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }
  vehicleCount(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/dashboard/vehicleCount", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/dashboard/vehicleCount`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }
  documentCount(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/dashboard/documentCount", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/dashboard/documentCount`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  getGarageDashBoardAggregations(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/dashboard/getGarageDashBoardAggregations", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/dashboard/getGarageDashBoardAggregations`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  getTopTenReminders(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/reminder/getTopTenReminders", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/reminder/getTopTenReminders`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }
  getFleetOwnerDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/settings/updateFleetDetails", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/settings/updateFleetDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  listInspectionsToBeApproved(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/listInspectionsToBeApproved", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/listInspectionsToBeApproved`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  updateGarageDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/settings/updateGarageDetails", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/settings/updateGarageDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  updateVendorDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vendor/updateVendor", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vendor/updateVendor`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  inActivateVendor(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vendor/inActivateVendor", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vendor/inActivateVendor`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  updateFleetDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/settings/updateFleetDetails", requestJson)
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/settings/updateFleetDetails`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }


  getUserByEmailId(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/dotstow/user/getUserByEmailId", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/dotstow/user/getUserByEmailId`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });



  }
  garageUpcomingOverdueList(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/reminder/listUpcomingReminders", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/reminder/listUpcomingReminders`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {

        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });
  }

  //Inspection Orders list

  listInspectionOrders(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/listInspectionOrders", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/listInspectionOrders`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });


  }
  adcustomerNotes(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/notes/addNote", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/notes/addNote`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });


  }
  listOfNotes(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/notes/listNotes", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/notes/listNotes`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });


  }

  sendtoCustomerAPI(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/emailDocuments", requestJson)
      .then(response => {

        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/emailDocuments`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => {
        // Process and return the successful response data
        return responseData;
      })
      .catch(error => {
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });



  }
  getCustomerContacts(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/contact/getCustomerContacts", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/contact/getCustomerContacts`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  listTaskOrder(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/taskOrder/listTaskOrders", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/taskOrder/listTaskOrders`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  createTaskOrder(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/taskOrder/addTaskOrder", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/taskOrder/addTaskOrder`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  updateTaskOrders(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/taskOrder/updateTaskOrder", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/taskOrder/updateTaskOrder`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  deleteTaskOrders(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/taskOrder/deleteTO", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/taskOrder/deleteTO`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  completeTaskOrders(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/completeIO", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/completeIO`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  deleteCancelledIO(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/deleteCancelledIO", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/deleteCancelledIO`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }


  sendMessageToAdmin(requestJson) {
    return fetch(conactUsAPI, requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch('https://htqwj5hcg6.execute-api.us-east-2.amazonaws.com/default/DotStowCorporateLeadsProduction', requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  /////////////Delete documets from DB////////////////////////////
  deleteDocumentsFromDb(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/deleteDocument", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/deleteDocument`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  /////////////Delete documets from DB////////////////////////////
  deleteInspectionDocumentsFromDb(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/inspection/deleteInspection", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/inspection/deleteInspection`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  /////////////LIst All Status ////////////////////////////
  listAllStatus(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/taskOrder/listAllStatus", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/taskOrder/listAllStatus`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  /////////////active or inactive customer ////////////////////////////
  inActivateCustomer(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/customer/inActivateCustomer ", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/customer/inActivateCustomer`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  /////////////active or inactive customer ////////////////////////////
  inActivateVehicle(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/inActivateVehicle", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/inActivateVehicle`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  /////////////cancel io////////////////////////////
  cancelAssignToGarage(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/cancelAssignToGarage", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/cancelAssignToGarage`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  /////////////cancel workorder////////////////////////////
  canceledworkorders(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/canceledworkorders", requestJson)

      .then(response => {

        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/canceledworkorders`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }

      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  /////////////active or inactive user ////////////////////////////
  inactivateUser(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/user/inactivateUser", requestJson)

      .then(response => {
        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/user/inactivateUser`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  /////////////cancel io////////////////////////////
  cancelAssignToGarage(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/cancelAssignToGarage", requestJson)

      .then(response => {

        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/cancelAssignToGarage`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }

      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }
  /////////////cancel workorder////////////////////////////
  canceledworkorders(requestJson) {

    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/vehicle/canceledworkorders", requestJson)

      .then(response => {

        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/vehicle/canceledworkorders`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }

      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });

  }

  //Send to customer document Fleet side

  sendtoCustomerFleet(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/" + tenantName + "/document/emailFleetDocuments", requestJson)

      .then(response => {

        switch (response.status) {
          case 200:
            return response.json(); // Successful response
          case 400:
            console.log("Error 400: Bad Request. Please check your input.");
            return { status: 400, message: "Bad Request. Please check your input." };
          case 401:
            // Unauthorized, attempt to refresh token
            return ExpirytimeCheck().then(() => {
              // Retry request after token refresh
              return fetch(`${process.env.React_App_AWS_API}/tenant/${tenantName}/document/emailFleetDocuments`, requestJson)
                .then(retryResponse => {
                  if (retryResponse.status === 200) {
                    return retryResponse.json();
                  } else {
                    handleSessionExpiry(); // Unknown status on retry
                    return { status: retryResponse.status, message: "Session expired. Please log in again." };
                  }
                });
            });
          case 403:
            console.log("Error 403: Forbidden. You do not have permission to access this resource.");
            return { status: 403, message: "Forbidden. You do not have permission to access this resource." };
          case 404:
            console.log("Error 404: Resource not found. Please check the URL.");
            return { status: 404, message: "Resource not found. Please check the URL." };
          case 500:
            console.log("Error 500: Internal server error. Please try again.");
            return { status: 500, message: "Internal server error. Please try again." };
          case 503:
            console.log("Error 503: Service unavailable. The server is temporarily unable to handle the request.");
            return { status: 503, message: "Service unavailable. Please try again later." };
          default:
            // Handle unexpected status codes
            handleSessionExpiry();
            console.log(`Unexpected status ${response.status}: Redirecting to login.`);
            return { status: response.status, message: "Unexpected error. Please log in again." };
        }

      })
      .then(responseData => { return responseData; })
      .catch(error => {
        // Handle and return the error (with status code and message)
        if (error.status) {
          console.log(`Error ${error.status}: ${error.data.message || "Something went wrong"}`);
        } else {
          console.log("Network or other error", error.status);
        }
        return { status: error.status, message: error.data?.message || "Something went wrong, Please try again" };
      });


  }

}

export default new Service();


