import React, { history, useEffect, useState, useMemo } from "react";
import Tableheader from "../../../utility/tableheader";
import Pagination from "../../../utility/pagination";
import vehicle_api from "./vehicle_lists";
import Search from "../../../utility/search";
import $ from "jquery";
import getcustomerLists from "../customer/customer_api";
import CheckAuthentication from "../../../utility/checkAuthentication";
import { useNavigate, useParams } from "react-router-dom";
import { loadingComponent } from "../../../utility/Loading";
import Swal from "sweetalert2";
const Vehicles = () => {


  // $( document ).ready(function() {
  //   $("#closeCustomer").trigger('click');
  // });


  const [sortingField, setSortingField] = useState("");
  const [roles, setRoles] = useState("");
  const [loading, setLoading] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [search, setSearch] = useState("");
  const inlineCss =
  {
    textAlign: "left"
  }
  const onSortingChangeVehicle = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    // vehicle_api("1", currentPage, ITEMS_PER_PAGE, "", ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, field ? field : "", order,inactiveStatus);
    vehicle_api("1", currentPage, ITEMS_PER_PAGE, searchValue, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, field ? field : "", sortingOrder, "", inactiveStatus);

  };
  const [customerName, setCustomerName] = useState("");
  const headers =
    [
      { name: "Customer Name", field: "Customer Name", sortable: true, thStyle: 'width="40%"', inlineStyle: inlineCss },
      { name: "Unit Number", field: "Unit number", sortable: true, thStyle: 'width="40%"', inlineStyle: inlineCss },
      { name: "VIN Number ", field: "Vin number", sortable: true, inlineStyle: inlineCss },
      { name: "Vehicle Type", field: "Vehicle type", sortable: true, inlineStyle: inlineCss },
      { name: "Make - Model", field: "Make", inlineStyle: inlineCss },
      // { name: "Model", field: "Model",inlineStyle:inlineCss },
      // { name: "Email", field: "Email",sortable: true,inlineStyle:inlineCss },
      { name: "Actions" }
    ];

  const [vechileLists, setVehicleList] = useState([]);
  const [totalRecors, setTotalRecord] = useState([]);
  const [totalRecors1, setTotalRecord1] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, settotalPages] = useState();
  const [displayPage, setdisplayPage] = useState([]);
  const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
  const [totalItems1, setTotalItems1] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [totalpages1, settotalPages1] = useState();
  const [displayPage1, setdisplayPage1] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerId, setCustomerId] = useState();
  const navigate = useNavigate();
  const [inactiveStatus, setInactiveStatus] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  /////////////////vehicle pagination////////////////////////////
  const handlePageChange = (offset, triggerevent) => {

    vehicle_api("1", offset, ITEMS_PER_PAGE, searchValue, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "", inactiveStatus);

  }
  /////////////////////////////vehicle search/////////////////////////
  const searchList = (vehicleId) => {
    setSearchValue(vehicleId);
    vehicle_api("1", "1", ITEMS_PER_PAGE, vehicleId, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems", inactiveStatus);

  }

  useEffect(() => {
    setRoles(localStorage.getItem("Userroles"));
    if (CheckAuthentication) {
      vehicle_api("1", "1", ITEMS_PER_PAGE, "", ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems", inactiveStatus);
      $('#display_pages_in_items').on('change', function () {

        setitemperpage(this.value);
        vehicle_api("", "1", this.value, $('#searchValue').val(), this.value, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "", inactiveStatus);
        //  getcustomerLists("1",ITEMS_PER_PAGE,customerName,this.value*ITEMS_PER_PAGE);
      });
    }
    else {
      sessionStorage.clear();
      localStorage.clear();
      //navigate("/login");
      window.location.replace("login");
    }

    sessionStorage.setItem("naviationfrom", "vehicles");

    // $(document).on("click", "#inactive", function () {
    //   var inactiveVehicle = "";
    //   if ($('input[name="inactive"]').is(':checked')) {
    //     setInactiveStatus(false);
    //     inactiveVehicle = "false";
    //   } else {
    //     setInactiveStatus(true);
    //     inactiveVehicle = "true";
    //   }
    //   vehicle_api("1", currentPage, ITEMS_PER_PAGE, searchValue, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "", inactiveVehicle);
    // });

  }, []);

  
  const redirect = (page) => {

    navigate(page);

  }
  const redirectVehicle = (customerId) => {


    if (customerId !== undefined) {

      window.location.href = "/addVehicle/" + customerId;

    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "",
        text: "Please choose a customer",

        showConfirmButton: true

      }).then((result) => {
        if (result.isConfirmed) {

        }
      });
    }


  }

  const searchCustomerList = (customerName) => {
    $("#customerList").show();
    setSearch(customerName)
    setCustomerName(customerName);
    getcustomerLists("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems1, settotalPages1, setCurrentPage1, setCustomerList, setTotalRecord1, setdisplayPage1, sortingField, sortingOrder,'',true);

  }
  const handleChange = (event, index, name, customerId) => {
    // setCustomerId(event.target.value);
    setCustomerId(customerId);
    setSearch(name)
    //onChange(event.target.value)
    $("#searchText").focus();
    $("#customerList").hide();

  }

  var activestatus="false";
    const activeInctive = (event) => {
      
        if(event.target.checked)
        {

        }else{
            activestatus="true";
        }
        vehicle_api("1", currentPage, ITEMS_PER_PAGE, searchValue, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "", activestatus);

        setInactiveStatus(activestatus);
    }


  return (
    <>
      {loadingComponent}
      <div class="main-panel">
        <div class="content-wrapper" style={{ display: "block;" }}>
          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto mb-2 ">
              <h4 class="font-weight-bold my-4 text-uppercase">Vehicles</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto grid-margin stretch-card">
              <div class="card">
                <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                  {/* <div class="search position-relative align-self-start mb-1 mt-1"> */}
                  <div class="d-flex justify-content-start">
                    <div class="search position-relative">
                      <Search
                        placeholderName="Search with Unit/VIN/Model"
                        onSearch={value => {
                          searchList(value);
                        }}

                      />
                      <a class="search-icon">
                        <img src="/images/icons/search.svg" alt="" />
                      </a>
                    </div>
                    {roles.match("Technician") || roles.match("Inspector") ? "" : <><input type="checkbox"
                      // defaultChecked={false}
                      onChange={(e) => activeInctive(e) }
                      id="inactive"
                      name="inactive"
                      style={{ marginLeft: "21px", marginTop: "4px" }} />
                    <span style={{ marginLeft: "10px", marginTop: "15px" }}>
                    Inactive records only
                    </span></>} 
                    
                  </div>

                  <div class="d-flex align-self-start mb-1 mt-1">
                    {/* <!-- <button type="button" class="btn btn-success">Add Customer</button> --> */}
                    {roles.match("Technician") || roles.match("Inspector") ? "" : <a class="btn btn-success" data-bs-toggle="modal" data-bs-target="#adddocs">Add Vehicle</a>}
                  </div>
                </div>
                <div class="card-body pt-0 pb-0">

                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive  border-bottom">



                        <table class="table">
                          <tr>
                            {headers.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                              <th
                                key={name}
                                onClick={() =>
                                  sortable ? onSortingChangeVehicle(field) : null
                                }
                                class={thStyle} style={inlineStyle}>
                                {name}

                                {sortingField && sortingField === field && (

                                  sortingOrder === "asc"
                                    ? "↑"
                                    : "↓"
                                )}

                              </th>
                            ))}
                          </tr>

                          <tbody>
                            {
                              vechileLists ? vechileLists.map((data, index) => (
                                <tr>
                                  <td class="text-capitalize" style={{ width: "20%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.customerName ? data.customerName : "--"}</td>
                                  <td style={{ width: "15%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.unitNumber ? data.unitNumber : "--"}</td>
                                  <td style={{ width: "20%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.vinNumber ? data.vinNumber : "--"}</td>
                                  <td style={{ width: "15%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.vehicleTypeName ? data.vehicleTypeName : "--"}</td>
                                  <td style={{ width: "20%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.make ? data.make : "NA"} - {data.model ? data.model : "NA"}</td>
                                  {/* <td>{data.model?data.model:"--"}</td> */}
                                  {/* <td>{data.customerEmail}</td> */}
                                  <td style={{ "width": "50px" }}><a onClick={() => redirect("/vehicleDetails/" + data.id)} class="btn btn-outline-primary text-uppercase">View</a></td>
                                </tr>
                              )) : ""
                            }

                          </tbody>
                        </table>
                      </div>
                    </div>
                    {vechileLists ? vechileLists.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                    </div>}
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-between p-3">
                  <div class="d-flex">
                    <div class="dropdown show mr-3 ">
                      <select class="form-control custome-select" className="btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {
                          displayPage.map((data, index) => (
                            <option value={data}>{data}</option>
                          ))
                        }
                      </select>
                    </div>
                    <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE <= totalRecors ? currentPage * ITEMS_PER_PAGE : totalRecors} out of {totalRecors} Records Displayed</span>
                  </div>
                  <nav aria-label="Page navigation example">
                    <Pagination
                      total={totalRecors}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      //onPageChange={page => setCurrentPage(page)}
                      onPageChange={handlePageChange}
                    />
                    {/* <ul class="pagination mb-0">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item"><a class="page-link" href="#">1</a></li>
                          <li class="page-item"><a class="page-link" href="#">2</a></li>
                          <li class="page-item"><a class="page-link" href="#">3</a></li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul> */}
                  </nav>
                </div>

              </div>

            </div>

          </div>
        </div>

      </div>
      <div class="modal fade" id="adddocs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Vehicle</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closePopup" >
                <span aria-hidden="true" >×</span>
              </button>
            </div>
            <div class="modal-body scroll-auto">
              <div class="row mb-4">
                <div class="col-12">
                  <div class="search position-relative w-100">
                    {/* <Search
                                                   placeholderName="Customer name"
                                                  
                                                   
                                                /> */}
                    <input type="text" id="searchText" value={search} placeholder="Search with customer name" className="form-control searchValues" onChange={e => {
                      searchCustomerList(e.target.value);
                    }} />
                    <a class="search-icon"> <img src="/images/icons/search.svg" alt="" /></a>
                    {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100" />
                                            <a href="#" class="search-icon"> <img src="images/icons/search.svg" alt="" /></a> */}
                  </div>
                </div>
                <div class="col-12 mt-2 px-3" id="customerList">
                  {
                    customerList ? customerList.map((data, index) => (
                      <div class="form-check input-align-right align-items-center d-flex w-100  mt-3 mb-3" onClick={(event) => handleChange(event, index, data.customerName, data.customerId)}>
                        <input class="form-check-input" type="hidden" name="customerId" value={data.customerId} id="flexRadioDefault1" />

                        <div class="d-flex align-items-center" id={"selectedCustomer" + index}>
                          <div class="add-owner-logo">
                            <img src="/images/transport-logo.jpg" alt="" />
                          </div>



                          <label class="form-check-label" for="flexRadioDefault1">  <b>{data.customerName}</b> </label>


                        </div>
                      </div>)) : ""
                  }


                </div>
              </div>


            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="closeCustomer">Close</button>
              <a class="btn btn-primary" data-bs-dismiss="modal" onClick={() => redirectVehicle(customerId)}>Select Customer</a>
              {/* <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

Vehicles.propTypes = {};

Vehicles.defaultProps = {};

export default Vehicles;
