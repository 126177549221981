import React,{history,useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const AnnualVehicleRead = () => {
   const { fkAccountId, inspectionId,vid } = useParams();
   const [inputList, setInputList] = useState([{ }]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [ fileName, setfileName ] = useState("");
   const [ inspectionDetails, setInspectionDetails ] = useState("");
   const [ documentList, setDocumentLists ] = useState([]);
   const [expiryDate, setexpiryDate] = useState(new Date());
   const navigate = useNavigate();
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));

      // vehicle_details_api(id, setVehicleDetails, setCustomerId);
      getInpection(fkAccountId,inspectionId)
   }, []);

  function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {

        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) =>
      {

         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }

            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
         // alert(res.rows.workOrderNumber);
      });

   }

   const redirect=(page)=>
   {

      navigate(page);

   }
   const save=(status)=>
   {
      // alert(status);
   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please enter Inspected by",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
         var status = $('input:radio[name="inspectionFailed"]:checked').val();
         if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
         {
           Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text:"Please select disposition of tank",
               showConfirmButton: true

             }).then((result) =>
             {

                   if(result.isConfirmed)
                   {


                   }
             });
             return false;
         }
         if( $('input:radio[name="inspectionFailed"]:checked').val()===""||$('input:radio[name="inspectionFailed"]:checked').val()===undefined)
         {
           Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text:"Please select inspection result",
               showConfirmButton: true

             }).then((result) =>
             {

                   if(result.isConfirmed)
                   {


                   }
             });
             return false;
         }

         if(status==="approvedCertify")
         {
            status="APPROVED";
         }else
         {
            status="Rejected";
         }
         // alert(status);

         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
           "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,

           "result": {
            "approvedCertify":$("input[name='inspectionFailed']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='inspectionFailed']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }


       };

       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions =
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },

       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) =>
       {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         Swal.fire({
           position: 'center',
           icon: 'success',
           title: "",
           text:res.message,
           showConfirmButton: true

         }).then((result) =>
         {

               if(result.isConfirmed)
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));

               }
         });
       });

   }
  return(
  <>
  {LoadingComponentForSubmit}
    <div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>
                  <div class="row mb-0">
                     <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                        <div class="d-flex justify-content-between align-items-center my-4">
                           <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                        </div>
                     </div>
                  </div>
                  <div class="row mb-2">
                           <div class="col-lg-11 col-md-12 m-auto ">
                           <HeaderBlock cids={fkAccountId} />
                           </div>
                  </div>



                  <div class="row mb-2">
                     <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card">
                          <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                           </div>
                           <div class="divider"></div>
                           <div class="row p-3">
                              <div class="col-lg-12">
                                 <h4 class="mb-2 form-inspection-title text-center">Annual Vehicle Inspection                                    </h4>
                              </div>
                              <div class="col-lg-12"> <WorkOrderNumberDiv status={"readOnly"} /></div>

                              <div class="col-lg-12">
                                 <div class="inspection-wrap">
                                    <div class="text-center mb-2 mt-2">
                                       <small>
                                       {/* <!-- <span class="mb-3"> Air regulation for vapor tightness: VA SAPCB Article 4-37, 40 CFR 60.505, 40 CFR 63.425(e) and 40 CFR 63.11092(f).</span> --> */}
                                       </small>
                                    </div>
                                    {/* <!-- accordion --> */}
                                    <div class="accordion" id="accordionPanelsStayOpenExample">
                                       <div class="accordion-item">
                                          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                             Brake System
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne" >
                                             <div class="accordion-body w-100">
                                                <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                   <tr className="annuaul-tb-5cl p-2">
                                                     <td>a. Service Brakes</td>
                                                     <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.serviceBrakeNF=='na'?"Yes":"No":""}</td>
                                                     <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.serviceBrakeNF=='verified'?"Yes":"No":""}</td>
                                                     <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.serviceBrake=='1'?"Yes":"No":""}</td>
                                                     <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.serviceBrakeDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.serviceBrakeDate:"--":"--"}</td>

                                                   </tr>
                                                   <tr className="annuaul-tb-5cl p-2">
                                                     <td>b. Parking Brake System</td>
                                                     <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.parkingBrakeNF=='na'?"Yes":"No":""}</td>
                                                     <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.parkingBrakeNF=='verified'?"Yes":"No":""}</td>
                                                     <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.parkingBrake=='1'?"Yes":"No":""}</td>
                                                     <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.parkingBrakeDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.parkingBrakeDate:"--":"--"}</td>
                                                   </tr>

                                                   <tr className="annuaul-tb-5cl p-2">
                                                      <td>c. Brake Drums or Rotors</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.drumsAndRotorsNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.drumsAndRotorsNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.drumsAndRotors=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.drumsAndRotorsDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.drumsAndRotorsDate:"--":"--"}</td>
                                                    </tr>

                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>d. Brake Hose</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.hoseNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.hoseNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.hose=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.hoseDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.hoseDate:"--":"--"}</td>
                                                    </tr>

                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>e. Brake Tubing</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.tubingNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.tubingNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.tubing=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.tubingDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.tubingDate:"--":"--"}</td>
                                                    </tr>

                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>f. Low Pressure Warning Device</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.lowPressureWarningNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.lowPressureWarningNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.lowPressureWarning=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.lowPressureWarningDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.lowPressureWarningDate:"--":"--"}</td>
                                                    </tr>

                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>g. Tractor Protection Valve</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.tractorPrptectionNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.tractorPrptectionNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.tractorPrptection=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.tractorPrptectionDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.tractorPrptectionDate:"--":"--"}</td>
                                                    </tr>

                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>h. Air Compressor</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.electricBrakesNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.airCompressorNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.airCompressor=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.airCompressorDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.airCompressorDate:"--":"--"}</td>
                                                    </tr>

                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>i. Electric Brakes</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.electricBrakesNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.electricBrakesNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.electricBrakes=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.electricBrakesDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.electricBrakesDate:"--":"--"}</td>
                                                    </tr>
                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>j. Hydraulic Brakes</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.hydraulicNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.hydraulicNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.hydraulic=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.hydraulicDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.vacccumeSystemDate:"--":"--"}</td>
                                                   </tr>
                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>k. Vaccuem System</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.vacccumeSystemNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.vacccumeSystemNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.vacccumeSystem=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.vacccumeSystemDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.vacccumeSystemDate:"--":"--"}</td>
                                                    </tr>
                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>l. Antilock Brake System</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.antilockBrakeNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.antilockBrakeNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.antilockBrake=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.antilockBrakeDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.antilockBrakeDate:"--":"--"}</td>
                                                    </tr>
                                                    <tr className="annuaul-tb-5cl p-2">
                                                      <td>m. Automatic Brake Adjusters</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.automaticBrakeNF=='na'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.automaticBrakeNF=='verified'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.automaticBrake=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.breakSystem.automaticBrakeDate!=''?inspectionDetails.annualVehicleInspection.breakSystem.automaticBrakeDate:"--":"--"}</td>
                                                    </tr>
                                                 </table>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-headingTwo">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                             Coupling Devices
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>a. Fifth wheels</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.FifthNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.FifthNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.fifthWheels=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.fifthWheelsDate!=''?inspectionDetails.annualVehicleInspection.couplingDevices.fifthWheelsDate:"--":"--"}</td>

                                                      </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>b. Pintie Hooks</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.PintleNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.PintleNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.Pintle=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.PintleDate!=''?inspectionDetails.annualVehicleInspection.couplingDevices.PintleDate:"--":"--"}</td>
                                                      </tr>

                                                      <tr className="annuaul-tb-5cl p-2">
                                                         <td>c. Drawbar/Towbar Eye</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.EyeNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.EyeNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.Eye=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.EyeDate!=''?inspectionDetails.annualVehicleInspection.couplingDevices.EyeDate:"--":"--"}</td>
                                                       </tr>

                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>d. Drawbar/Towbar Tongue</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.TongueNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.TongueNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.Tongue=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.TongueDate!=''?inspectionDetails.annualVehicleInspection.couplingDevices.TongueDate:"--":"--"}</td>
                                                       </tr>

                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>e. Safety Devices</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.SafetyNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.SafetyNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.Safety=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.SafetyDate!=''?inspectionDetails.annualVehicleInspection.couplingDevices.SafetyDate:"--":"--"}</td>
                                                       </tr>

                                                       {/* <tr className="annuaul-tb-5cl p-2">
                                                         <td>f. Low Pressure Warning Device</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.lowPressureNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.lowPressureNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.lowPressure=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.lowPressureDate!=''?inspectionDetails.annualVehicleInspection.couplingDevices.lowPressureDate:"--":"--"}</td>
                                                       </tr>

                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>g. Tractor Protection Valve</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.tractorProtectionNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.tractorProtectionNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.tractorProtection=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.tractorProtectionDate!=''?inspectionDetails.annualVehicleInspection.couplingDevices.tractorProtectionDate:"--":"--"}</td>
                                                       </tr> */}

                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>f. Saddle-Mounts</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.SaddleNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.SaddleNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.Saddle=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.couplingDevices.SaddleDate!=''?inspectionDetails.annualVehicleInspection.couplingDevices.SaddleDate:"--":"--"}</td>
                                                       </tr>


                                                    </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-headingthree">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                EXHAUST SYSTEM
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree" >
                                             <div class="accordion-body pt-3 pb-4">
                                                <div class="accordion-body">
                                                   <div class="w-100">
                                                      <table className="w-100">
                                                      <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                      </tr>
                                                         <tr className="annuaul-tb-5cl p-2">
                                                           <td>a. Exhaust system leaking forward of or directly below the driver/sleeper compartment</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_driverNF=='na'?"Yes":"No":""}</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_driverNF=='verified'?"Yes":"No":""}</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_driver=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_driverDate!=''?inspectionDetails.annualVehicleInspection.exahustSystem.step3_driverDate:"--":"--"}</td>

                                                         </tr>
                                                         <tr className="annuaul-tb-5cl p-2">
                                                           <td>b. Bus exhaust system leaking or discharging in violation of standard. </td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_violationNF=='na'?"Yes":"No":""}</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_violationNF=='verified'?"Yes":"No":""}</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_violation=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_violationDate!=''?inspectionDetails.annualVehicleInspection.exahustSystem.step3_violationDate:"--":"--"}</td>
                                                         </tr>

                                                         <tr className="annuaul-tb-5cl p-2">
                                                            <td>c. Exhaust system likely to burn,char, or damage the electrial wiring, fuel supply, or any combustible part of the motor vehicle. </td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_damageNF=='na'?"Yes":"No":""}</td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_damageNF=='verified'?"Yes":"No":""}</td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_damage=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.exahustSystem.step3_damageDate!=''?inspectionDetails.annualVehicleInspection.exahustSystem.step3_damageDate:"--":"--"}</td>
                                                          </tr>


                                                       </table>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-headingfour">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefour" aria-expanded="false" aria-controls="panelsStayOpen-collapsefour">
                                                FUEL SYSTEM
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapsefour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingfour" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>a. Visible leak</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_visibleNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_visibleNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_visible=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_visibleDate!=''?inspectionDetails.annualVehicleInspection.fuelSystem.step3_visibleDate:"--":"--"}</td>

                                                      </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>b. Fuel tank filler cap missing</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_fillerNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_fillerNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_filler=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_fillerDate!=''?inspectionDetails.annualVehicleInspection.fuelSystem.step3_fillerDate:"--":"--"}</td>
                                                      </tr>

                                                      <tr className="annuaul-tb-5cl p-2">
                                                         <td>c. Fuel tank securely attached </td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_securityNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_securityNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_security=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.fuelSystem.step3_securityDate!=''?inspectionDetails.annualVehicleInspection.fuelSystem.step3_securityDate:"--":"--"}</td>
                                                       </tr>


                                                    </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-headingfive">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefive" aria-expanded="false" aria-controls="panelsStayOpen-collapsefive">
                                                Lighting Devices
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapsefive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingfive" >
                                             <div class="accordion-body pt-3 pb-4">
                                                <div class="accordion-body">
                                                   <div class="w-100">
                                                      <table className="w-100">
                                                      <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                         <tr className="annuaul-tb-5cl p-2">
                                                           <td> All Lighting devices and relections required by part 393 shall be operable</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.lightingDevices.step3_393NF=='na'?"Yes":"No":""}</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.lightingDevices.step3_393NF=='verified'?"Yes":"No":""}</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.lightingDevices.step3_393=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.lightingDevices.step3_393Date!=''?inspectionDetails.annualVehicleInspection.lightingDevices.step3_393Date:"--":"--"}</td>
                                                           </tr>
                                                       </table>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-headingsix">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsesix" aria-expanded="false" aria-controls="panelsStayOpen-collapsesix">
                                                Safe Loading
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingsix" >
                                             <div class="accordion-body pt-3 pb-4">
                                                <div class="accordion-body">
                                                   <div class="w-100">
                                                      <table className="w-100">
                                                      <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                         <tr className="annuaul-tb-5cl p-2">
                                                           <td>a. Part(s) of vehicle or condition of loading such that the spare tire or any part of the load or dunnage can fall onto the roadway.  </td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_partNF=='na'?"Yes":"No":""}</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_partNF=='verified'?"Yes":"No":""}</td>
                                                           <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_part=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_partDate!=''?inspectionDetails.annualVehicleInspection.safeLoading.step3_partDate:"--":"--"}</td>
                                                         </tr>
                                                         <tr className="annuaul-tb-5cl p-2">
                                                            <td>b. Protection against shifting cargo.</td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_protectionNF=='na'?"Yes":"No":""}</td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_protectionNF=='verified'?"Yes":"No":""}</td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_protection=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_protectionDate!=''?inspectionDetails.annualVehicleInspection.safeLoading.step3_protectionDate:"--":"--"}</td>
                                                          </tr>
                                                          <tr className="annuaul-tb-5cl p-2">
                                                            <td>c. Container securement devices on intermodal equipment</td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_CcontainerNF=='na'?"Yes":"No":""}</td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_CcontainerNF=='verified'?"Yes":"No":""}</td>
                                                            <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_Ccontainer=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_CcontainerDate!=''?inspectionDetails.annualVehicleInspection.safeLoading.step3_CcontainerDate:"--":"--"}</td>
                                                          </tr>
                                                       </table>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading7">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse7" aria-expanded="false" aria-controls="panelsStayOpen-collapse7">
                                                Steering Mechanism
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse7" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading7" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>a. Steering Wheel Free Play</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_wheelNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_wheelNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_wheel=='1'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_wheelDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_wheelDate:"--":"--"}</td>
                                                      </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                         <td>b. Steering Column</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_columnNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_columnNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_column=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_columnDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_columnDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>c. Front Axle Beam and All Steering Components Other Than Steering Column </td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_frontNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_frontNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_front=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_frontDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_frontDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>d. Steering Gear Box </td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_gearNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_gearNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_gear=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_gearDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_gearDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                          <td>e. Pitman Arm</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_pitmanNF=='na'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_pitmanNF=='verified'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_pitman=='1'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_pitmanDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_pitmanDate:"--":"--"}</td>
                                                        </tr>
                                                        <tr className="annuaul-tb-5cl p-2">
                                                          <td>f. Power Steering</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_powerNF=='na'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_powerNF=='verified'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_power=='1'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_powerDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_powerDate:"--":"--"}</td>
                                                        </tr>
                                                        <tr className="annuaul-tb-5cl p-2">
                                                         <td>g. Ball and Socket Joints</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_ballNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_ballNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_ball=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_ballDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_ballDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                          <td>h. Tie Rods and Drag Links</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_tieNF=='na'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_tieNF=='verified'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_tie=='1'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_tieDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_tieDate:"--":"--"}</td>
                                                        </tr>
                                                        <tr className="annuaul-tb-5cl p-2">
                                                          <td>i. Nuts</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_nutsNF=='na'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_nutsNF=='verified'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_nuts=='1'?"Yes":"No":""}</td>
                                                          <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_nutsDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_nutsDate:"--":"--"}</td>
                                                        </tr>
                                                        <tr className="annuaul-tb-5cl p-2">
                                                         <td>j. Steering System</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_systemNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_systemNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_system=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_systemDate!=''?inspectionDetails.annualVehicleInspection.steeringMechanism.step4_systemDate:"--":"--"}</td>
                                                       </tr>

                                                    </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading8">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse8" aria-expanded="false" aria-controls="panelsStayOpen-collapse8">
                                                suspension
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse8" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading8" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>a. Any U-bolt(s), spring
                                                         hanger(s), or other axle
                                                         positioning part(s) cracked,
                                                         broken, loose or missing
                                                         resulting in shifting of an
                                                         axle from its normal position.</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_positioningNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_positioningNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_positioning=='1'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_positioningDate!=''?inspectionDetails.annualVehicleInspection.suspension.step4_positioningDate:"--":"--"}</td>
                                                      </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                         <td>b. Spring Assembly</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_assemblyNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_assemblyNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_assembly=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_assemblyDate!=''?inspectionDetails.annualVehicleInspection.suspension.step4_assemblyDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>c. Torque, Radius or Tracking
                                                            Components </td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_componentsNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_componentsNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_components=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.suspension.step4_componentsDate!=''?inspectionDetails.annualVehicleInspection.suspension.step4_componentsDate:"--":"--"}</td>
                                                       </tr>
                                                      </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading9">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse9" aria-expanded="false" aria-controls="panelsStayOpen-collapse9">
                                                frame
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse9" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading9" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>a. Frame members</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_membersNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_membersNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_members=='1'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_membersDate!=''?inspectionDetails.annualVehicleInspection.frame.step4_membersDate:"--":"--"}</td>
                                                      </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                         <td>b. Tires and wheel clearance</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_clearanceNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_clearanceNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_clearance=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_clearanceDate!=''?inspectionDetails.annualVehicleInspection.frame.step4_clearanceDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>c. Adjustable Axle Assemblies (Sliding Subframes)</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_assembliesNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_assembliesNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_assemblies=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.frame.step4_assembliesDate!=''?inspectionDetails.annualVehicleInspection.frame.step4_assembliesDate:"--":"--"}</td>
                                                       </tr>
                                                      </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading10">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse10" aria-expanded="false" aria-controls="panelsStayOpen-collapse10">
                                                tires
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse10" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading10" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>a. Tires on any streering axle of a power unit</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_powerUnitNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_powerUnitNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_powerUnit=='1'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_powerUnitDate!=''?inspectionDetails.annualVehicleInspection.tires.step4_powerUnitDate:"--":"--"}</td>
                                                      </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                         <td>b. All other tires.</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_allOtherNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_allOtherNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_allOther=='1'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_allOtherDate!=''?inspectionDetails.annualVehicleInspection.tires.step4_allOtherDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>c. Installation of speed-restricted tires unless specifically designated by motor carrier</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_motorCarrierNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_motorCarrierNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_motorCarrier=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.tires.step4_motorCarrierDate!=''?inspectionDetails.annualVehicleInspection.tires.step4_motorCarrierDate:"--":"--"}</td>
                                                       </tr>
                                                      </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading11">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse11" aria-expanded="false" aria-controls="panelsStayOpen-collapse11">
                                                wheels rims
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse11" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading11" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>a. Lock or Side Ring</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_lockNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_lockNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_lock=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_lockDate!=''?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_lockDate:"--":"--"}</td>
                                                      </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                         <td>b. Wheels and Rims  </td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_wheelsNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_wheelsNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_wheels=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_wheelsDate!=''?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_wheelsDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>c. Fasteners</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_fastenersNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_fastenersNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_fasteners=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_fastenersDate!=''?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_fastenersDate:"--":"--"}</td>
                                                       </tr>
                                                       <tr className="annuaul-tb-5cl p-2">
                                                         <td>d. Welds</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_weldsNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_weldsNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_welds=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_weldsDate!=''?inspectionDetails.annualVehicleInspection.wheelsAndRims.step5_weldsDate:"--":"--"}</td>
                                                       </tr>
                                                      </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading12">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse12" aria-expanded="false" aria-controls="panelsStayOpen-collapse12">
                                                Windshield glazing
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse12" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading12" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>Requirements and exceptions
                                                         as stated pertaining to any
                                                         crack, discoloration or vision
                                                         reducing matter (reference
                                                         393.60 for exceptions).</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.windshieldGlazing.step5_requirementsNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.windshieldGlazing.step5_requirementsNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.windshieldGlazing.step5_requirements=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.windshieldGlazing.step5_requirementsDate!=''?inspectionDetails.annualVehicleInspection.windshieldGlazing.step5_requirementsDate:"--":"--"}</td>
                                                      </tr>

                                                      </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading13">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse13" aria-expanded="false" aria-controls="panelsStayOpen-collapse13">
                                                Windshield wipers
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse13" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading13" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>Any power unit that has an
                                                         inoperative wiper, or missing
                                                         or damaged parts that render
                                                         it ineffective.</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.windshieldWipers.step5_powerNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.windshieldWipers.step5_powerNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.windshieldWipers.step5_power=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.windshieldWipers.step5_powerDate!=''?inspectionDetails.annualVehicleInspection.windshieldWipers.step5_powerDate:"--":"--"}</td>
                                                      </tr>

                                                      </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading14">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse14" aria-expanded="false" aria-controls="panelsStayOpen-collapse14">
                                                motorcoach seats
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse14" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading14" >
                                             <div class="accordion-body">
                                                <div class="w-100">
                                                   <table className="w-100">
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>Any passenger seat that is
                                                         not securely fastened to the
                                                         vehicle structure.
                                                         </td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.motorcoachSeats.step5_passengerNF=='na'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.motorcoachSeats.step5_passengerNF=='verified'?"Yes":"No":""}</td>
                                                         <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.motorcoachSeats.step5_passenger=='1'?"Yes":"No":""}</td>
                                                      <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.motorcoachSeats.step5_passengerDate!=''?inspectionDetails.annualVehicleInspection.motorcoachSeats.step5_passengerDate:"--":"--"}</td>
                                                      </tr>

                                                      </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="accordion-item">
                                          <h2 class="accordion-header " id="panelsStayOpen-heading15">
                                             <button class="accordion-button text-uppercase collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse15" aria-expanded="false" aria-controls="panelsStayOpen-collapse15">
                                                others
                                             </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapse15" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading15" >
                                             <div class="accordion-body">
                                                <div class="w-100">

                                                   <div>
                                                     {inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.other!=''?inspectionDetails.annualVehicleInspection.other:"--":""}
                                                   </div>
                                                   {/* <table>
                                                   <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                                     <th>Company</th>
                                                     <th>NA</th>
                                                     <th>Verified Ok</th>
                                                     <th>Need Repair</th>
                                                     <th>Date</th>

                                                   </tr>
                                                      <tr className="annuaul-tb-5cl p-2">
                                                        <td>List any other condition(s) which may prevent safe operation of this vehicle
                                                         </td>
                                                        <td>-</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_CcontainerDateNF=='na'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_CcontainerDateNF=='verified'?"Yes":"No":""}</td>
                                                        <td>{inspectionDetails.annualVehicleInspection?inspectionDetails.annualVehicleInspection.safeLoading.step3_CcontainerDate:""}</td>
                                                      </tr>

                                                      </table> */}
                                                </div>
                                             </div>
                                          </div>
                                       </div>


                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="divider"></div>
                           <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />
                                </div>
                                <div class="divider"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>

                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'|| inspectionDetails.status=='REJECT'|| inspectionDetails.status=='REJECTED'||inspectionDetails.status=="Rejected") {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="inspectionFailed" value='InspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="inspectionFailed" value='InspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnService" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnService" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} customerId={fkAccountId}/> */}
                                {/* <VerifiedBy setEndDate={setEndDate} endDate={endDate}  status={"readOnly"} /> */}
                                {/* <div class="divider"></div>
                                <div class="row verifyby p-4">
                                   <div class="col-4">
                                      <div class="form-group">
                                         <label for="exampleInputEmail1">Verified By</label>
                                         <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                   </div>
                                   <div class="col-4">
                                      <div class="form-group">
                                         <label for="exampleInputEmail1">Date</label>
                                         <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />

                                      </div>
                                   </div>
                                </div> */}
            </>
          )
        } else {
          return (
            <><div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="inspectionFailed" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="inspectionFailed" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnService" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnService" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE}  customerId={fkAccountId}/> */}
         {/* <VerifiedBy setEndDate={setEndDate} endDate={endDate} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />

               </div>
            </div>
         </div> */}
         </>
          )
        }
      }
      })()}
                             </div>

                            <div class="divider"></div>
                            {(() =>
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'|| inspectionDetails.status=='REJECTED'||inspectionDetails.status=='Rejected')
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approvedCertify")}>Submit</a> */}
                                       {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approvedCertify")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
  </>
)};

AnnualVehicleRead.propTypes = {};

AnnualVehicleRead.defaultProps = {};

export default AnnualVehicleRead;
