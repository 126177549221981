import React, { useEffect, useState, useMemo }  from 'react';
import PropTypes from 'prop-types';
import $, { parseJSON } from 'jquery';
import { bottom } from '@popperjs/core';
const Checkbox = ({ label, type, selected, styleClass, onChange,documentIds,from }) => {
    const [checkedStatus, setChecked] = useState(false);
    const handleChange = (event) => {
      
        const {checked} = event.target;
        setChecked(checked);
        if(checked)
        {
            
            
            $('input:checkbox[name=documentIds]').prop('checked', true).attr('checked', 'checked');
        }else{
      
            $('input:checkbox[name=documentIds]').prop('checked', false).attr('checked', 'checked');
        }
       
       
        // onChange(checked);
    };

    return (
      
            <div style={{marginBottom: "-10px;",display: "flex",
                maxHeight: "18px",
                alignItems: "center"}}>
                <input type="hidden" class="chooseItems"  value={documentIds} />
                <input
                type="checkbox"
               
                onChange={handleChange}

                id="DocumentID" name="documentIdss"/>

                {label}
            </div>
   
    )
};

Checkbox.propTypes = {
    styleClass: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
    styleClass: ''
};

export default Checkbox;