import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import AddInspectionName from "../../../../utility/addInspectionName";
import removePdf from "../../../../utility/removePdf";
import moment from "moment-timezone";
import getDateAndTimeStamp from "../../../../utility/getTimeStamp";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
// import '../../../../utility/'
const DOTEdit = () => {
    const { taskOrderId, fkAccountId, inspectionId, vid } = useParams();
    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [fileName, setfileName] = useState("");
    const [inspectionDetails, setInspectionDetails] = useState("");
    const [documentList, setDocumentLists] = useState([]);
    const [compartments, setcompartments] = useState();
    const [compartmentsLen, setcompartmentLength] = useState();
    const [manywaytest, setmanywaytest] = useState();
    const [inspectionTypeId, setinspectionTypeId] = useState("");
    const [expiryDate, setexpiryDate] = useState(new Date());
    const [total, settotal] = useState();
    const navigate = useNavigate();

    const [endDate, setEndDate] = useState(new Date());
    const [startTime, setTime] = useState(new Date());

    const [pressureTest1startTime, setPressureTest1startTime] = useState(null);
    const [pressureTest1finishTime, setPressureTest1finishTime] = useState(null);
    const [pressureTest2startTime, setPressureTest2startTime] = useState(null);
    const [pressureTest2finishTime, setPressureTest2finishTime] = useState(null);

    const [vaccumeTest1startTime, setVaccumeTest1startTime] = useState(null);
    const [vaccumeTest1finishTime, setVaccumeTest1finishTime] = useState(null);
    const [vaccumeTest2startTime, setVaccumeTest2startTime] = useState(null);
    const [vaccumeTest2finishTime, setVaccumeTest2finishTime] = useState(null);

    const [vaporValveTeststartTime, setVaporValveTeststartTime] = useState(null);
    const [vaporValveTestfinishTime, setVaporValveTestfinishTime] = useState(null);

    const [pressureTest3startTime, setPressureTest3startTime] = useState(null);
    const [pressureTest3finishTime, setPressureTest3finishTime] = useState(null);

    const [compartMentLen, setComLen] = useState(new Date());
    var documentLists = [];
    const [roles, setRoles] = useState("");
    const [statusInspection, setStatus] = useState("");
    const [verifiedDATE, setverifiedDate] = useState(new Date());
    var current = new Date();

    var hour_format=localStorage.getItem('time_format_os');


    useEffect(() => {

        setRoles(localStorage.getItem("Userroles"));
        // $('#otherField').hide();
        // $('#otherFieldTextFiled').hide();
        getInpection(fkAccountId, inspectionId);

    }, []);

    

    $(document).ready(function () {

        // $("#pressureTest1startTime").attr("readonly", "readonly");
        // $("#pressureTest1finishTime").attr("readonly", "readonly");
        // $("#pressureTest2startTime").attr("readonly", "readonly");
        // $("#pressureTest2finishTime").attr("readonly", "readonly");
        // $("#vaccumeTest1startTime").attr("readonly", "readonly");
        // $("#vaccumeTest1finishTime").attr("readonly", "readonly");

        // $("#vaccumeTest2startTime").attr("readonly", "readonly");
        // $("#vaccumeTest2finishTime").attr("readonly", "readonly");
        // $("#vaporValveTeststartTime").attr("readonly", "readonly");
        // $("#vaporValveTestfinishTime").attr("readonly", "readonly");
        // $("#pressureTest3startTime").attr("readonly", "readonly");
        // $("#pressureTest3finishTime").attr("readonly", "readonly");


        $("#pressureTest1Change,#pressureTest2change,#vaccumeTest1change,#vaccumeTest2change,#vaporValveTestchange,#pressureTest3Change,#pressureTest1startTimePressure,#pressureTest1finishTimePressure,#pressureTest2startTimePressure,#pressureTest2finishTimePressure,#vaccumeTest1startTimePressure,#vaccumeTest1startTimePressure,#vaccumeTest1finishTimePressure,#vaccumeTest2startTimePressure,#vaccumeTest2finishTimePressure,#vaporValveTeststartTimePressure,#vaporValveTestfinishTimePressure,#pressureTest3startTimePressure,#pressureTest3finishTimePressure").keypress(function (e) {
            // #vaccumeTest1Result,#vaccumeTest2Result
            // if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            //     return false;
            // }
            if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
                ((e.which < 48 || e.which > 57) &&
                    (e.which != 0 && e.which != 8))) {
                return false;
            }

            var text = $(this).val();

            if ((text.indexOf('.') != -1) &&
                (text.substring(text.indexOf('.')).length > 2) &&
                (e.which != 0 && e.which != 8) &&
                ($(this)[0].selectionStart >= text.length - 2)) {
                return false;
            }
        });
    });


    $(document).on("input", 'input[name="checkPerformed"]:checked', function () {

        var otherValue = $('input[name="checkPerformed"]:checked').val();
    //    console.log("@@@check perfomred"+otherValue);
        if (otherValue == "other") {
            $('#otherField').show();
        } else {
            $('#otherField').hide();
        }
    });

    function checkPerformedChecked(){
        var otherValue = $('input[name="checkPerformed"]:checked').val();
      console.log('@@@@@@@@@@@'+$('input[name="checkPerformed"]:checked').val());
             if (otherValue == 'other') {
            $('#otherField').show();
        } else {
            $('#otherField').hide();
        }
    }

    $(document).on("input", 'input[name="other"]', function () {
        // alert($("input[name='other']:checked").val());
        var otherValue = $('input[name="other"]:checked').val();
        if (otherValue == '1') {
            $('#otherFieldTextFiled').show();
        } else {
            $('#otherFieldTextFiled').hide();
        }

    });

   

    function otherChecked(){
        var otherValue = $('input[name="other"]:checked').val();
             if (otherValue == '1') {
            $('#otherFieldTextFiled').show();
        } else {
            $('#otherFieldTextFiled').hide();
        }
    }

    var totalGallons = 0;
    var totalmanyGallons = 0;
    var workingLen = [];
    var manwayLen = [];
    function getInpection(fkAccountId, inspectionId) {
        var requestObjectForLeakage =
        {

            "id": inspectionId
        }

        var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

        services.getInspectionDetails(requestOptions).then((res) => {

            setInspectionDetails(res.rows);
            setDocumentLists(res.rows.documentList ? res.rows.documentList : "");
            documentLists.push(res.rows.documentList);
            setinspectionTypeId(res.rows.inspectionType.id);
            setStatus(res.rows.status);
            if (res.rows) {
                if (res.rows.verifiedDate) {
                    setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
                }
                if (res.rows.expiryDate) {
                    setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
                }
            }


            // $("#pressureTest1startTime").val(res.rows.dOTCargoTankTest.pressureTest1.startTime ? res.rows.dOTCargoTankTest.pressureTest1.startTime : "");
            $("#pressureTest1startTime").val(res.rows.dOTCargoTankTest.pressureTest1.startTime ? moment(res.rows.dOTCargoTankTest.pressureTest1.startTime,["HH:mm A"]).format("HH:mm") : "");
            $("#pressureTest1finishTime").val(res.rows.dOTCargoTankTest.pressureTest1.finishTime ? moment(res.rows.dOTCargoTankTest.pressureTest1.finishTime, ["HH:mm A"]).format("HH:mm") : "");
            setPressureTest1startTime(res.rows.dOTCargoTankTest.pressureTest1.startTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest1.startTime)) : "");
            setPressureTest1finishTime(res.rows.dOTCargoTankTest.pressureTest1.finishTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest1.finishTime)) : "");

            $("#pressureTest2startTime").val(res.rows.dOTCargoTankTest.pressureTest2.startTime ? moment(res.rows.dOTCargoTankTest.pressureTest2.startTime, ["HH:mm A"]).format("HH:mm") : "");
            $("#pressureTest2finishTime").val(res.rows.dOTCargoTankTest.pressureTest2.finishTime ? moment(res.rows.dOTCargoTankTest.pressureTest2.finishTime, ["HH:mm A"]).format("HH:mm") : "");
            setPressureTest2startTime(res.rows.dOTCargoTankTest.pressureTest2.startTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest2.startTime)) : "");
            setPressureTest2finishTime(res.rows.dOTCargoTankTest.pressureTest2.finishTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest2.finishTime)) : "");

            $("#vaccumeTest1startTime").val(res.rows.dOTCargoTankTest.vaccumeTest1.startTime ? moment(res.rows.dOTCargoTankTest.vaccumeTest1.startTime, ["HH:mm A"]).format("HH:mm") : "");
            $("#vaccumeTest1finishTime").val(res.rows.dOTCargoTankTest.vaccumeTest1.finishTime ? moment(res.rows.dOTCargoTankTest.vaccumeTest1.finishTime, ["HH:mm A"]).format("HH:mm") : "");
            setVaccumeTest1startTime(res.rows.dOTCargoTankTest.vaccumeTest1.startTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaccumeTest1.startTime)) : "");
            setVaccumeTest1finishTime(res.rows.dOTCargoTankTest.vaccumeTest1.finishTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaccumeTest1.finishTime)) : "");

            $("#vaccumeTest2startTime").val(res.rows.dOTCargoTankTest.vaccumeTest2.startTime ? moment(res.rows.dOTCargoTankTest.vaccumeTest2.startTime, ["HH:mm A"]).format("HH:mm") : "");
            $("#vaccumeTest2finishTime").val(res.rows.dOTCargoTankTest.vaccumeTest2.finishTime ? moment(res.rows.dOTCargoTankTest.vaccumeTest2.finishTime, ["HH:mm A"]).format("HH:mm") : "");
            setVaccumeTest2startTime(res.rows.dOTCargoTankTest.vaccumeTest2.startTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaccumeTest2.startTime)) : "");
            setVaccumeTest2finishTime(res.rows.dOTCargoTankTest.vaccumeTest2.finishTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaccumeTest2.finishTime)) : "");

            $("#vaporValveTeststartTime").val(res.rows.dOTCargoTankTest.vaporValveTest.startTime ? moment(res.rows.dOTCargoTankTest.vaporValveTest.startTime, ["HH:mm A"]).format("HH:mm") : "");
            $("#vaporValveTestfinishTime").val(res.rows.dOTCargoTankTest.vaporValveTest.finishTime ? moment(res.rows.dOTCargoTankTest.vaporValveTest.finishTime, ["HH:mm A"]).format("HH:mm") : "");
            setVaporValveTeststartTime(res.rows.dOTCargoTankTest.vaporValveTest.startTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaporValveTest.startTime)) : "");
            setVaporValveTestfinishTime(res.rows.dOTCargoTankTest.vaporValveTest.finishTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.vaporValveTest.finishTime)) : "");

            $("#pressureTest3startTime").val(res.rows.dOTCargoTankTest.pressureTest3.startTime ? moment(res.rows.dOTCargoTankTest.pressureTest3.startTime, ["HH:mm A"]).format("HH:mm") : "");
            $("#pressureTest3finishTime").val(res.rows.dOTCargoTankTest.pressureTest3.finishTime ? moment(res.rows.dOTCargoTankTest.pressureTest3.finishTime, ["HH:mm A"]).format("HH:mm") : "");
            setPressureTest3startTime(res.rows.dOTCargoTankTest.pressureTest3.startTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest3.startTime)) : "");
            setPressureTest3finishTime(res.rows.dOTCargoTankTest.pressureTest3.finishTime ? new Date(getDateAndTimeStamp(res.rows.dOTCargoTankTest.pressureTest3.finishTime)) : "");


            setcompartmentLength(res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.compartmentList.length : "");

            setcompartments(res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.compartmentList : "");
            // $("#pressureTest1startTimePressure").val(res.rows.dOTCargoTankTest?res.rows.dOTCargoTankTest.pressureTest1.startTimePressure:"");
            if (res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.compartmentList : "" !== "") {
                for (var i = 0; i < res.rows.dOTCargoTankTest.compartmentList.length; i++) {
                    if (res.rows.dOTCargoTankTest.compartmentList[i].working == "1") {
                        workingLen.push(res.rows.dOTCargoTankTest.compartmentList[i].working);
                        totalGallons += parseFloat(res.rows.dOTCargoTankTest.compartmentList[i].safeFillAmountInGals);
                    }

                }
                $("#allValues").val(totalGallons);
            }
            if (res.rows.dOTCargoTankTest.compartmentList.length == workingLen.length) {
                $('#checkAll').prop('checked', true);
            }
            if (res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.manwayCompartmentsList : "" !== "") {
                for (var i = 0; i < res.rows.dOTCargoTankTest.manwayCompartmentsList.length; i++) {
                    if (res.rows.dOTCargoTankTest.manwayCompartmentsList[i].working == "1") {
                        manwayLen.push(res.rows.dOTCargoTankTest.manwayCompartmentsList[i].working);
                        totalmanyGallons += parseFloat(res.rows.dOTCargoTankTest.manwayCompartmentsList[i].safeFillAmountInGals);
                    }

                }
                $("#allManyValues").val(totalmanyGallons);
            }
            if (res.rows.dOTCargoTankTest.manwayCompartmentsList.length == manwayLen.length) {
                $('#checkAllmany').prop('checked', true);
            }
            setmanywaytest(res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.manwayCompartmentsList : "");
            $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");

            $('input[name="checkPerformed"][value="' + res.rows.dOTCargoTankTest.checkPerformed.checkPerformed + '"]').trigger("click");
            $('input[name="other"][value="'+res.rows.dOTCargoTankTest.componentsTested.other+'"]').trigger("click");
            $("input[name='other'][value='" + res.rows.dOTCargoTankTest.componentsTested.other + "']").trigger("click");
            $('#otherData').val(res.rows.dOTCargoTankTest.checkPerformed.otherData);
                // alert(res.rows.dOTCargoTankTest.componentsTested.other)
            if (res.rows.dOTCargoTankTest.componentsTested.other == '1') {
                $('#otherFieldTextFiled').show();
            }

            if (res.rows.dOTCargoTankTest.checkPerformed.checkPerformed == 'other') {
                $('#otherField').show();
            }

            //setPressureTest1startTime(res.rows.dOTCargoTankTest?res.rows.dOTCargoTankTest.pressureTest1.startTime:"");
            $("#notes22").val(res.rows.dOTCargoTankTest ? res.rows.dOTCargoTankTest.componentsTested.otherDataCom : "")
            //   $("#pressureTest1startTime").val(res.rows.dOTCargoTankTest?res.rows.dOTCargoTankTest.pressureTest1.startTime:"");
            $("#notes").val(res.rows ? res.rows.notes : "");
            $("#inspectedBy").val(res.rows ? res.rows.inspectedBy : "");
            setEndDate(new Date(res.rows ? res.rows.inspectedDate : ""));
            sessionStorage.setItem("documentList", JSON.stringify(res.rows.documentList));



        });

    }
    var sum = 0;
    $('#checkAll').click(function () {
        var checked = $(this).is(":checked");
        if (checked) {
            sum = 0;
            for (var i = 0; i < compartmentsLen; i++) {
                $('input[name=sec' + i + 'Distortion').prop('checked', this.checked);
                var comp = $("#comp" + i + "gallons").val();
                sum += parseFloat(comp);


            }
            if (isNaN(Math.abs(sum))) {
                $("#allValues").val("")
            } else {
                $("#allValues").val(Math.abs(sum))
            }

        }
        else {
            sum = 0;
            for (var i = 0; i < compartmentsLen; i++) {
                $('input[name=sec' + i + 'Distortion').prop('checked', checked);

            }
            $("#allValues").val("");
        }

    });
    var sum2 = 0;
    $('#checkAllmany').click(function () {
        var checked = $(this).is(":checked");
        if (checked) {

            for (var i = 0; i < compartmentsLen; i++) {
                $('input[name=sec' + i + 'manyDistortion').prop('checked', this.checked);
                var comp = $("#many" + i + "test").val();
                sum2 += parseFloat(comp);


            }
            $("#allmanyValues").val(sum2);
        }
        else {
            sum2 = 0;
            for (var i = 0; i < compartmentsLen; i++) {
                $('input[name=sec' + i + 'manyDistortion').prop('checked', checked);

            }
            $("#allmanyValues").val("");
        }
    });
    const redirect = (page) => {

        navigate(page);

    }
    const handlemanyChange = (e, index) => {

        let isChecked = e.target.checked;

        if (isChecked) {
            $('input[name=sec' + index + 'manyDistortion').prop('checked', isChecked);

            var comp = $("#many" + index + "test").val();
            sum2++;
            $("#allmanyValues").val(sum2);
        } else {
            var comp = $("#many" + index + "test").val();
            sum2--;
            $("#allmanyValues").val(sum2);
        }



    }
    const textChange = (e, index) => {


        //$('input[name=sec'+index+'Distortion').prop('checked', isChecked);
        // var comp=$("#comp" + index + "gallons").val();

        // sum+= parseFloat(comp);
        //  $("#allValues").val(Math.abs(sum));
        sum = 0;
        for (var i = 0; i < compartmentsLen; i++) {

            var comp = $("#comp" + i + "gallons").val();
            sum += parseFloat(comp);


        }
        if (isNaN(Math.abs(sum))) {
            $("#allValues").val("")
        } else {
            $("#allValues").val(Math.abs(sum))
        }



    }
    const handleChange = (e, index) => {

        let isChecked = e.target.checked;
        $('#checkAll').prop('checked', false);

        if (isChecked) {
            $('input[name=sec' + index + 'Distortion').prop('checked', isChecked);
            var comp = $("#comp" + index + "gallons").val();
            sum += parseFloat(comp);
            $("#allValues").val(Math.abs(sum));
        } else {

            $('input[name=sec' + index + 'Distortion').prop('checked', false);

            //$('input[name=sec'+index+'Distortion').removeAttr('checked');
            var comp = $("#comp" + index + "gallons").val();
            sum -= parseFloat(comp);
            $("#allValues").val(Math.abs(sum));
        }



    }

    function ValidateTime(val) {

        // var current = new Date();
        // alert(current.getHours());
        // alert(val);
        //    const t = document.createElement("input")

        //       t.type = "time"

        //       t.value = "22:00"

        //       document.body.appendChild(t)

        //       console.log(t.offsetWidth);

        //       const width = getComputedStyle(t).getPropertyValue("width");

        //       alert(width < 81? 24 : 12);


        var dt = new Date();
        var hours = dt.getHours(); // gives the value in 24 hours format
        // alert(hours);
        var AmOrPm = 8 >= 12 ? 'pm' : 'am';
        hours = (8 % 12) || 12;
        var minutes = dt.getMinutes();
        var finalTime = "Time  - " + hours + ":" + minutes + " " + AmOrPm;
        // final time Time - 22:10
        // alert(finalTime);

    }

    const save = (status) => {
        // alert($('input[name="other"]:checked').val());
        // alert($("#pressureTest1startTime").val());
        // // alert(moment($("#pressureTest1startTime").val(),["HH:mm"]).format('HH'))
        // alert(current.toLocaleTimeString());
        // alert(moment(current.toLocaleTimeString(), ["hh:mm"]).format('hh'))

        // if (moment(current.toLocaleTimeString(), ["HH:mm:ss A"]).format('A') == "PM" || "AM") {
        //     // $("#pressureTest1startTime").val()>'12'?moment($("#pressureTest1startTime").val(),["HH:mm"]).format('HH:mm'):moment($("#pressureTest1startTime").val(),["hh:mm"]).format('hh:mm A')
        //     alert(moment($("#pressureTest1startTime").val(), ["hh:mm"]).format('hh:mm A'))
        // } else {
        //     alert(moment($("#pressureTest1startTime").val(), ["HH:mm"]).format('HH:mm'))
        // }

        // alert($("#pressureTest1startTime").val())
        // alert(moment('13:00',["HH:mm A"]).format('HH:mm A'));

        // if(moment('1:00 PM',["HH"]).format('HH')>=12){
        //     alert(moment('1:00 PM',["HH:mm A"]).format('hh:mm'))
        // }else{ 
        //     alert(moment('1:00 PM',["HH:mm A"]).format('HH:mm A'))
        // }

        // return false;


        // alert($("#pressureTest1startTime").val());
        // alert($("#pressureTest1startTime").val()?moment($("#pressureTest1startTime").val(),["hh:mm"]).format('hh:mm')>12?moment($("#pressureTest1startTime").val(),["HH:mm"]).format('HH:mm'):moment($("#pressureTest1startTime").val(),["hh:mm"]).format('hh:mm A'):'');return false;
        // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());
        // console.log("results=="+JSON.stringify(inputList));
        var jsonStr = [];
        var jsonstrMany = [];
        var documentList = [];

        for (var i = 0; i < compartments.length; i++) {

            jsonStr.push({
                "working": $("input[name=sec" + (i) + "Distortion]:checked").val() === "on" ? "1" : "0",
                "safeFillAmountInGals": $("#comp" + (i) + "gallons").val() ? $("#comp" + (i) + "gallons").val() : "0",
            })
        }
        for (var i = 0; i < compartments.length; i++) {

            jsonstrMany.push({
                "working": $("input[name=sec" + (i) + "manyDistortion]:checked").val() === "on" ? "1" : "0",
                "safeFillAmountInGals": $("#many" + (i) + "test").val() ? $("#many" + (i) + "test").val() : "0",
            })
        }
        try {
            for (var i = 0; i < inputList.length; i++) {
                // console.log("filebase64=="+inputList[i].filetobase64);
                var extension = "";
                var byteString = "";
                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                    extension = "word";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
                    extension = "bmp";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                    extension = "docm";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                    extension = "dotx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                    extension = "dotx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
                    extension = "dotm";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
                }
                else {

                }


                documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
            }
        } catch (exception) {
            //    documentList.push();
        }
        try {
            if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

                var i;
                var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                for (i = 0; i < storedArray.length; i++) {

                    documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                }

            } else {

                documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
            }

        } catch (ex) {

        }
        if ($("#inspectedBy").val() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please enter inspectedBy",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }


        var requestObjectForInsp =
        {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "type": "Ohio EPA",
            "workOrderNumber": $("#workOrderNumber").val(),
            "notes": $("#notes").val(),
            "inspectedBy": $("#inspectedBy").val(),
            "inspectedDate": $("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedByBate").val(),
            "status": status,
            "taskOrderId": taskOrderId,
            "inspectionType":
            {
                "id": inspectionTypeId,
                "name": "Ohio EPA"
            },
            "result":
            {
                "approvedCertify": "0",
                "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
                "vehicleReturnedServices": "0",
                "vehicleWithdrawnFromService": "0",
                "vehicleSentToAnotherRepairFacility": "0"
            },
            // "result": {
            //     "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
            //     "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
            //  },
            //  "dispositionOfVehicle": {
            //     "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
            //     "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
            //     "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
            //  },
            "vehicleDetails":
            {
                "vinNumber": $("#vin").html(),
                "lisencePlateNumber": $("#lisencePlateNumber").html(),
                "id": vid,
                "unitNumber": $("#lisencePlateNumber").html()
            },
            "dOTCargoTankTest":
            {
                "compartmentList": jsonStr,
                "manwayCompartmentsList": jsonstrMany,
                "checkPerformed": {
                    "checkPerformed": $("input[name='checkPerformed']:checked").val() ? $("input[name='checkPerformed']:checked").val() : '',
                    "otherData": $("input[name='checkPerformed']:checked").val()=='other' ? $("#otherData").val() : ''
                },
                "pressureTest1":
                {
                    // "startTime": $("#pressureTest1startTime").val() != '' ? $("#pressureTest1startTime").val() : '',
                    // "finishTime": $("#pressureTest1finishTime").val() != '' ? $("#pressureTest1finishTime").val() : '',
                    "startTime": $("#pressureTest1startTime").val() != '' ? hour_format=='24'?moment($("#pressureTest1startTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#pressureTest1startTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "finishTime": $("#pressureTest1finishTime").val() != '' ? hour_format=='24'?moment($("#pressureTest1finishTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#pressureTest1finishTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    // "finishTime": $("#pressureTest1finishTime").val() != '' ? moment($("#pressureTest1finishTime").val(), ["hh:mm"]).format('hh:mm A') : '',
                    "startTimePressure": $("#pressureTest1startTimePressure").val() != '' ? $("#pressureTest1startTimePressure").val() : '',
                    "finishTimePressure": $("#pressureTest1finishTimePressure").val() != '' ? $("#pressureTest1finishTimePressure").val() : '',
                    "change": $("#pressureTest1Change").val() != '' ? $("#pressureTest1Change").val() : ''
                },
                "pressureTest2":
                {
                    // "startTime": $("#pressureTest2startTime").val() != '' ? $("#pressureTest2startTime").val() : '',
                    // "finishTime": $("#pressureTest2finishTime").val() != '' ? $("#pressureTest2finishTime").val() : '',
                    "startTime": $("#pressureTest2startTime").val() != '' ? hour_format=='24'?moment($("#pressureTest2startTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#pressureTest2startTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "finishTime": $("#pressureTest2finishTime").val() != '' ? hour_format=='24'?moment($("#pressureTest2finishTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#pressureTest2finishTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "startTimePressure": $("#pressureTest2startTimePressure").val() != '' ? $("#pressureTest2startTimePressure").val() : '',
                    "finishTimePressure": $("#pressureTest2finishTimePressure").val() != '' ? $("#pressureTest2finishTimePressure").val() : '',
                    "change": $("#pressureTest2change").val() != '' ? $("#pressureTest2change").val() : '',
                    "testResult": $("#pressureTest2Result").val() != '' ? $("#pressureTest2Result").val() : ''
                },
                "vaccumeTest1": {
                    // "startTime": $("#vaccumeTest1startTime").val() != '' ? $("#vaccumeTest1startTime").val() : '',
                    // "finishTime": $("#vaccumeTest1finishTime").val() != '' ? $("#vaccumeTest1finishTime").val() : '',
                    "startTime": $("#vaccumeTest1startTime").val() != '' ? hour_format=='24'?moment($("#vaccumeTest1startTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#vaccumeTest1startTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "finishTime": $("#vaccumeTest1finishTime").val() != '' ? hour_format=='24'?moment($("#vaccumeTest1finishTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#vaccumeTest1finishTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "startTimePressure": $("#vaccumeTest1startTimePressure").val() != '' ? $("#vaccumeTest1startTimePressure").val() : '',
                    "finishTimePressure": $("#vaccumeTest1finishTimePressure").val() != '' ? $("#vaccumeTest1finishTimePressure").val() : '',
                    "change": $("#vaccumeTest1change").val() != '' ? $("#vaccumeTest1change").val() : '',
                    "testResult": $("#vaccumeTest1Result").val() != '' ? $("#vaccumeTest1Result").val() : ''
                },
                "vaccumeTest2": {
                    // "startTime": $("#vaccumeTest2startTime").val() != '' ? $("#vaccumeTest2startTime").val() : '',
                    // "finishTime": $("#vaccumeTest2finishTime").val() != '' ? $("#vaccumeTest2finishTime").val() : '',
                    "startTime": $("#vaccumeTest2startTime").val() != '' ? hour_format=='24'?moment($("#vaccumeTest2startTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#vaccumeTest2startTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "finishTime": $("#vaccumeTest2finishTime").val() != '' ? hour_format=='24'?moment($("#vaccumeTest2finishTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#vaccumeTest2finishTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "startTimePressure": $("#vaccumeTest2startTimePressure").val() != '' ? $("#vaccumeTest2startTimePressure").val() : '',
                    "finishTimePressure": $("#vaccumeTest2finishTimePressure").val() != '' ? $("#vaccumeTest2finishTimePressure").val() : '',
                    "change": $("#vaccumeTest2change").val() != '' ? $("#vaccumeTest2change").val() : '',
                    "testResult": $("#vaccumeTest2Result").val() != '' ? $("#vaccumeTest2Result").val() : ''
                }, "componentsTested": {
                    "productPiping": $("input[name='productPiping']:checked").val() === "on" ? "1" : "0",
                    "dischargeValves": $("input[name='dischargeValves']:checked").val() === "on" ? "1" : "0",
                    "dischargeHoses": $("input[name='dischargeHoses']:checked").val() === "on" ? "1" : "0",
                    "manholeAssemblies": $("input[name='manholeAssemblies']:checked").val() === "on" ? "1" : "0",
                    "tankVessel": $("input[name='tankVessel']:checked").val() === "on" ? "1" : "0",
                    "vaporCollection": $("input[name='vaporCollection']:checked").val() === "on" ? "1" : "0",
                    "compartmentVent": $("input[name='compartmentVent']:checked").val() === "on" ? "1" : "0",
                    "vaporvent": $("input[name='vaporvent']:checked").val() === "on" ? "1" : "0",
                    "other": $('input[name="other"]:checked').val() === "1" ? "1" : "0",
                    "otherDataCom": $('input[name="other"]:checked').val() === "1" ? $("#notes22").val() : "",
                    // "otherDataCom": $("#notes22").val() ? $("#notes22").val() : ''

                }, "vaporValveTest": {
                    // "startTime": $("#vaporValveTeststartTime").val() != '' ? $("#vaporValveTeststartTime").val() : '',
                    // "finishTime": $("#vaporValveTestfinishTime").val() != '' ? $("#vaporValveTestfinishTime").val() : '',
                    "startTime": $("#vaporValveTeststartTime").val() != '' ? hour_format=='24'?moment($("#vaporValveTeststartTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#vaporValveTeststartTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "finishTime": $("#vaporValveTestfinishTime").val() != '' ? hour_format=='24'?moment($("#vaporValveTestfinishTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#vaporValveTestfinishTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "startTimePressure": $("#vaporValveTeststartTimePressure").val() != '' ? $("#vaporValveTeststartTimePressure").val() : '',
                    "finishTimePressure": $("#vaporValveTestfinishTimePressure").val() != '' ? $("#vaporValveTestfinishTimePressure").val() : '',
                    "change": $("#vaporValveTestchange").val() != '' ? $("#vaporValveTestchange").val() : ''
                }, "pressureTest3": {
                    // "startTime": $("#pressureTest3startTime").val() != '' ? $("#pressureTest3startTime").val() : '',
                    // "finishTime": $("#pressureTest3finishTime").val() != '' ? $("#pressureTest3finishTime").val() : '',
                    "startTime": $("#pressureTest3startTime").val() != '' ? hour_format=='24'?moment($("#pressureTest3startTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#pressureTest3startTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "finishTime": $("#pressureTest3finishTime").val() != '' ? hour_format=='24'?moment($("#pressureTest3finishTime").val(), ["HH:mm"]).format('HH:mm'):moment($("#pressureTest3finishTime").val(), ["hh:mm"]).format('hh:mm A'):'',
                    "startTimePressure": $("#pressureTest3startTimePressure").val() != '' ? $("#pressureTest3startTimePressure").val() : '',
                    "finishTimePressure": $("#pressureTest3finishTimePressure").val() != '' ? $("#pressureTest3finishTimePressure").val() : '',
                    "change": $("#pressureTest3Change").val() != '' ? $("#pressureTest3Change").val() : ''

                },


            },
            "documentList": documentLists

        }


        //   const requestOptions =
        //   {
        //      method: 'POST',
        //      headers: { 'Content-Type': 'application/json'
        //               },

        //         body: JSON.stringify(requestObject)
        //   };
        var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
        if (statusInspection == "DRAFT" || status == "DRAFT") {
            // setLoading(true);
            $("#loadingforsubmit").show();
            services.addInspection(requestOptions).then((res) => {
                // setLoading(false);
                $("#loadingforsubmit").hide();
                // alert(res.success);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: "Your changes have been successfully saved!",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                    }
                });
            });
        } else {

            if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "Please select disposition of tank",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {

                        // alert("hhhhd")
                    }
                });
                return false;
            }
            if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "Please select inspection result",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {


                    }
                });
                return false;
            }
            if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

            } else {

                status = "Rejected";
            }

            var requestObjectInspection =
            {
                "id": inspectionId,
                "expiryDate": $("#expiryDate").val(),
                "verifiedBy": $("#verifiedBy").val(),
                "verifiedDate": $("#verifiedByBate").val(),
                "status": status,
                "taskOrderId": taskOrderId,
                "result": {
                    "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
                    "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
                },
                "dispositionOfVehicle": {
                    "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                    "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                    "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
                }

            };
            // setLoading(true);
            $("#loadingforsubmit").show();
            services.addInspection(requestOptions).then((res) => {
                // setLoading(false);
                if (res.success) {

                    //certify inspection
                    var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                    services.certifyInspection(requestOptions).then((res) => {
                        // setLoading(false);
                        $("#loadingforsubmit").hide();
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: "",
                            text: res.message,
                            showConfirmButton: true

                        }).then((result) => {

                            if (result.isConfirmed) {
                                navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                            }
                        });
                    });
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: "Your changes have been successfully saved!",
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                            navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                        }
                    });

                }
            });



        }

    }
    const NumericOnly = (e) => { //angka only
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) return true
        else e.target.value = preval.substring(0, (preval.length - 1))
    }

    return (
        <>
           
            {LoadingComponentForSubmit}
            
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="row mb-0">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <div class="d-flex justify-content-between align-items-center my-4">
                                <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                                <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-0">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <HeaderBlock cids={fkAccountId} />
                        </div>
                    </div>



                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="card">

                                <div class="row mb-2">
                                    <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                        <VehicleBasicInfo customerId={vid} />
                                    </div>
                                </div>
                                <div class="divider"></div>

                                <div class="row p-3">
                                    <div class="col-lg-12">
                                        <h4 class="mb-2 form-inspection-title text-center">DOT Cargo Tank - EPA - Pressure Vacuum Test (OHIO)</h4>
                                    </div>
                                    <div class="col-lg-12"><WorkOrderNumberDiv /></div>

                                    <div class="w-100 px-3">
                                        <div class="row mb-2 mt-2 px-3">
                                            <div class="col-lg-12">
                                                <div class="py-2">
                                                    <h5 class="text-uppercase">Check Performed</h5>
                                                </div>
                                            </div>
                                            <div class="col-lg-1 mb-2 pl-0">
                                                <div class="d-flex align-items-center">
                                                    <input type="radio" name="checkPerformed" value="new" class="mr-2" />
                                                    <span>New</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-2 mb-2">
                                                <div class="d-flex align-items-center">
                                                    <input type="radio" name="checkPerformed" value="annual_retest" class="mr-2" />
                                                    <span>Annual Retest</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-1 mb-2">
                                                <div class="d-flex align-items-center">
                                                    <input type="radio" name="checkPerformed" value="repair" class="mr-2" />
                                                    <span>Repair </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 mb-2 d-flex align-items-center">
                                                <div class="d-flex align-items-center">
                                                    <input type="radio" name="checkPerformed" value="other" class="mr-2" onClick={checkPerformedChecked()}/>
                                                    <span>Other (Specify)</span>

                                                </div>
                                                <div id="otherField">
                                                    <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" size="10" name="otherData" id="otherData" class="mr-2" /> </div></div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="inspection-wrap">

                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" style={{ "width": "50%" }}>Compartment Tested/Inspected </th>
                                                            <th scope="col" style={{ "width": "40%" }}>Safe fill amount(GALS)</th>
                                                            <th scope="col" class="d-flex align-items-center "><input type="checkbox" class="mr-2" placeholder="" onChange={NumericOnly} id="checkAll" />ALL</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            compartments ? compartments.map((data, index) => (
                                                                <tr>
                                                                    <td>
                                                                        <div class="row align-items-center">
                                                                            <div class="col-md-4">Compartment #{index + 1} </div>

                                                                        </div>
                                                                    </td>
                                                                    <td>

                                                                        <div class="col-md-4"><input type="text" class="form-control" id={"comp" + index + "gallons"} name={"comp" + index + "gallons"} aria-describedby="emailHelp" placeholder="Capacity NNNN Gallons" defaultValue={data.safeFillAmountInGals} onChange={e => textChange(e, index)} /></div>

                                                                    </td>
                                                                    {/* {data.working=="1"?<td><input type="checkbox" aria-label="Radio button for following text input"  value="1"  name={"sec" + (index) + "Distortion"} defaultChecked={"true"}  onChange={e => handleChange(e,index)}   /></td>:<td><input type="checkbox" aria-label="Radio button for following text input"  value="0"  name={"sec" + (index) + "Distortion"}  onChange={e => handleChange(e,index)} defaultChecked={"false"}   /></td>} */}
                                                                    {(() => {
                                                                        if (data.working == "1") {

                                                                            return (<><td><input type="checkbox" aria-label="Radio button for following text input" name={"sec" + index + "Distortion"} defaultChecked={true} onChange={e => handleChange(e, index)} /></td></>)
                                                                        } else {
                                                                            return (<><td><input type="checkbox" aria-label="Radio button for following text input" name={"sec" + index + "Distortion"} defaultChecked={false} onChange={e => handleChange(e, index)} /></td></>)
                                                                        }
                                                                    })()}


                                                                </tr>
                                                            )) : ""

                                                        }
                                                        {/* <tr>
                                        <td>
                                            <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #1 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.safeFillAmountInGals:""}</div>
                                            </div>
                                        </td>
                                            <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #2 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                        </tr>



                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #3 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment3.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                            <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #4 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment4.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment4.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                            <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #5 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #6 </div>
                                                    <div class="col-md-4">Gallon Capacity</div>
                                                    <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.safeFillAmountInGals:""}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                            <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                        </tr> */}


                                                        <td>Total in Gallons</td> <td><div class="col-md-4"><input type="text" class="form-control" id="allValues" disabled /></div></td>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="w-100">
                                <div class="inspection-wrap px-3">

                                <div class="table-2 mb-4">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                        <th scope="col" style={{ "width": "50%" }}>Manway Tested/Inspected(fill cover gaskets & vents) </th>
                                                    <th scope="col" class="d-flex align-items-center text-center justify-content-center" ><input type="checkbox" class="ml-4 mr-2"    aria-describedby="emailHelp" placeholder=""  id="checkAllmany" />ALL</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                        manywaytest ? manywaytest.map((data, index) => (
                                            <tr>
                                            <td>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4">Compartment #{index+1} </div>

                                                </div>
                                            </td>

                                        { (() => {
                                                  if (data.working=="1")
                                                  {

                                                  return(<><td class="text-center"><input type="checkbox" aria-label="Radio button for following text input"  name={"sec" + index + "manyDistortion"} defaultChecked={true}   onChange={e => handlemanyChange(e,index)}   /></td></>)
                                                  }else
                                                  {
                                                  return(<><td class="text-center"><input type="checkbox" aria-label="Radio button for following text input"   name={"sec" + index + "manyDistortion"} defaultChecked={false} onChange={e => handlemanyChange(e,index)} /></td></>)
                                                  }
                                            })()}

                                        </tr>
                                        )) : ""

                                    }

                                        </tbody>
                                    </table>
                                </div>
                                </div>
                                </div> */}
                                    <div class="col-lg-12">
                                        <div class="inspection-wrap">



                                            <div class="p-3 mb-4" style={{ backgroundColor: "#ccc" }}>
                                                <p>Test Method: US EPA Method 27, 40CFR, Part 60 APPX. A (03.420) <br />
                                                    Test Requirements: Modified Method 27, Pressure 18" W.C., Vacuum 6" W.C. <br />
                                                    *Note: Two consecutive test within 1/2 W.C. Test Result is average of two consecutive test
                                                </p>
                                            </div>



                                            <div class="table-3 mb-4" style={{ fontSize: "11px" }}>
                                                <table class="table rt-border-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" colSpan="2">Pressure - 18" W.C / 5 mins. Less than 0.5" W.C loss</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr class="border-bottom">
                                                            <td>

                                                                <div class="row align-items-center justify-content-between datebx-rem">
                                                                    <div class="col-md-12 mb-3">Test  #1</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center" >
                                                                            <span class="mr-2 mm-60">Start Time</span>
                                                                            {/* <input id="appt-time" type="time" name="appt-time" step=""  timeFormat="auto"/> */}
                                                                            {/* <input type="time"  class="form-control"  id="pressureTest1startTime"  onChange={e => ValidateTime(e.target.value)}  aria-describedby="emailHelp" style={{ width: "102px" }} /> */}
                                                                            <input type="time" class="form-control" id="pressureTest1startTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker 
                                                                                // showTimeSelect
                                                                                // showTimeSelectOnly
                                                                                // timeFormat="HH:mm aa"
                                                                                // timeIntervals={1}
                                                                                // timeCaption="Time"
                                                                                showTimeInput
                                                                                dateFormat="HH:mm aa" selected={pressureTest1startTime} onChange={(date) => setPressureTest1startTime(date)} type="text" id="pressureTest1startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="text" class="form-control mr-3" id="pressureTest1startTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest1.startTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-2 mm-60">Finish Time</span>
                                                                            <input type="time" class="form-control" id="pressureTest1finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={pressureTest1finishTime} onChange={(date) => setPressureTest1finishTime(date)} type="text" id="pressureTest1finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>

                                                                            <input type="text" class="form-control mr-3" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest1.finishTimePressure : ""} id="pressureTest1finishTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-2 mm-60">Change </span>
                                                                            <input type="text" class="form-control mr-2" id="pressureTest1Change" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest1.change : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>


                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test  #2</div>

                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Start Time</span>
                                                                            <input type="time" class="form-control" id="pressureTest2startTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={pressureTest2startTime} onChange={(date) => setPressureTest2startTime(date)} type="text" id="pressureTest2startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest2.startTimePressure : ""} id="pressureTest2startTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Finish Time</span>
                                                                            <input type="time" class="form-control" id="pressureTest2finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={pressureTest2finishTime} onChange={(date) => setPressureTest2finishTime(date)} type="text" id="pressureTest2finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" id="pressureTest2finishTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest2.finishTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Change </span>
                                                                            <input type="input" class="form-control mr-2" id="pressureTest2change" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest2.change : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>

                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-0 mm-60" >Test Result </span>
                                                                            <input type="input" class="form-control mr-2" id="pressureTest2Result" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest2.testResult : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="table-3 mb-4">
                                                <table class="table rt-border-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" colSpan="2">Vacuum - 6" W.C / 5 mins. Less than 0.5" W.C loss</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr class="border-bottom">

                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test  #3</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Start Time</span>
                                                                            <input type="time" class="form-control" id="vaccumeTest1startTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                // timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="h:mm aa" selected={vaccumeTest1startTime} onChange={(date) => setVaccumeTest1startTime(date)} type="text" id="vaccumeTest1startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest1.startTimePressure : ""} id="vaccumeTest1startTimePressure" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Finish Time</span>
                                                                            <input type="time" class="form-control" id="vaccumeTest1finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect
                                                                                showTimeSelectOnly
                                                                                // timeFormat="h:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="h:mm aa" selected={vaccumeTest1finishTime} onChange={(date) => setVaccumeTest1finishTime(date)} type="text" id="vaccumeTest1finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" id="vaccumeTest1finishTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest1.finishTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60" >Change </span>
                                                                            <input type="input" class="form-control mr-2" id="vaccumeTest1change" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest1.change : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test  #4</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Start Time</span>
                                                                            <input type="time" class="form-control" id="vaccumeTest2startTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={vaccumeTest2startTime} onChange={(date) => setVaccumeTest2startTime(date)} type="text" id="vaccumeTest2startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" id="vaccumeTest2startTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest2.startTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Finish Time</span>
                                                                            <input type="time" class="form-control" id="vaccumeTest2finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={vaccumeTest2finishTime} onChange={(date) => setVaccumeTest2finishTime(date)} type="text" id="vaccumeTest2finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" id="vaccumeTest2finishTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest2.finishTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Change </span>
                                                                            <input type="input" class="form-control mr-2" id="vaccumeTest2change" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest2.change : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-0 mm-60" >Test Result </span>
                                                                            <input type="input" class="form-control mr-2" id="vaccumeTest2Result" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaccumeTest2.testResult : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="row mb-4">
                                                <div class="col-lg-12">
                                                    <div class="p-4 text-center">
                                                        <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Components Tested / Inspected</h5>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.productPiping == '1') {
                                                                    return (<><input type="checkbox" name="productPiping" class="mr-2" defaultChecked={true} /></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="productPiping" class="mr-2" defaultChecked={false} /></>)
                                                                }

                                                            }
                                                        })()}
                                                        {/* <input type="checkbox"  name="productPiping"  class="mr-2" defaultChecked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.productPiping=='1'?true:false:''}/> */}
                                                        <span>Product Piping/Gaskets</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.dischargeValves == '1') {
                                                                    return (<><input type="checkbox" name="dischargeValves" class="mr-2" defaultChecked={true} /></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="dischargeValves" class="mr-2" defaultChecked={false} /></>)
                                                                }

                                                            }
                                                        })()}
                                                        {/* <input type="checkbox"  name="dischargeValves"  class="mr-2" defaultChecked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.dischargeValves=='1'?"true":"false":''}/> */}
                                                        <span>Discharge Valves</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.dischargeHoses == '1') {
                                                                    return (<><input type="checkbox" name="dischargeHoses" class="mr-2" defaultChecked={true} /></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="dischargeHoses" class="mr-2" defaultChecked={false} /></>)
                                                                }

                                                            }
                                                        })()}

                                                        <span>Discharge Hoses</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.manholeAssemblies == '1') {
                                                                    return (<><input type="checkbox" name="manholeAssemblies" class="mr-2" defaultChecked={true} /></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="manholeAssemblies" class="mr-2" defaultChecked={false} /></>)
                                                                }

                                                            }
                                                        })()}
                                                        {/* <input type="checkbox"  name="manholeAssemblies"  class="mr-2" defaultChecked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.manholeAssemblies=='1'?true:false:''}/> */}
                                                        <span>Manhole Assemblies</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.tankVessel == '1') {
                                                                    return (<><input type="checkbox" name="tankVessel" class="mr-2" defaultChecked={true} /></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="tankVessel" class="mr-2" defaultChecked={false} /></>)
                                                                }

                                                            }
                                                        })()}
                                                        {/* <input type="checkbox"  name="tankVessel"  class="mr-2" defaultChecked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.tankVessel=='1'?true:false:''}/> */}
                                                        <span>Tank vessel weld seams</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.vaporCollection == '1') {
                                                                    return (<><input type="checkbox" name="vaporCollection" class="mr-2" defaultChecked={true} /></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="vaporCollection" class="mr-2" defaultChecked={false} /></>)
                                                                }

                                                            }
                                                        })()}
                                                        {/* <input type="checkbox"  name="vaporCollection"  class="mr-2" defaultChecked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.vaporCollection=='1'?true:false:''}/> */}
                                                        <span>Vapor collection piping</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.compartmentVent == '1') {
                                                                    return (<><input type="checkbox" name="compartmentVent" class="mr-2" defaultChecked={true} /></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="compartmentVent" class="mr-2" defaultChecked={false} /></>)
                                                                }

                                                            }
                                                        })()}
                                                        {/* <input type="checkbox"  name="compartmentVent"  class="mr-2" defaultChecked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.compartmentVent=='1'?true:false:''}/> */}
                                                        <span>Compartment Vent</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.vaporvent == '1') {
                                                                    return (<><input type="checkbox" name="vaporvent" class="mr-2" defaultChecked={true} /></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="vaporvent" class="mr-2" defaultChecked={false} /></>)
                                                                }

                                                            }
                                                        })()}
                                                        {/* <input type="checkbox"  name="vaporvent"  class="mr-2" defaultChecked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.vaporvent=='1'?true:false:''}/> */}
                                                        <span>Vapor vent hood / hose assemblies'</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 mb-2">
                                                    <div class="d-flex align-items-center">
                                                        {(() => {
                                                            if (inspectionDetails.dOTCargoTankTest) {
                                                                if (inspectionDetails.dOTCargoTankTest.componentsTested.other == '1') {
                                                                    return (<><input type="checkbox" name="other" value="1" class="mr-2" defaultChecked={true} onClick={otherChecked()}/></>)

                                                                } else {
                                                                    return (<><input type="checkbox" name="other"  value="1" class="mr-2" defaultChecked={false} onClick={otherChecked()}/></>)
                                                                }

                                                            }
                                                        })()}
                                                        {/* <input type="checkbox"  name="other" value="1"  class="mr-2" /> */}
                                                        {/* checked={inspectionDetails.dOTCargoTankTest?inspectionDetails.dOTCargoTankTest.componentsTested.other=='1'?"checked":'':''} */}
                                                        <span>Other  (List)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {(() => {
                                                if (inspectionDetails.dOTCargoTankTest) {
                                                    if (inspectionDetails.dOTCargoTankTest.componentsTested.other == '1') {

                                                        return (<> */}
                                                        <div id="otherFieldTextFiled">
                                                            <div class="d-flex align-items-center"><textarea class="form-control mb-2" maxLength="300" name="notes22" id="notes22" rows="3" spellcheck="false" placeholder=""></textarea><br /><br /> </div>
                                                        </div>
                                                        {/* </>)
                                                    }
                                                }
                                            })()} */}


                                            <div class="table-3 mb-4">
                                                <table class="table rt-border-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Vapor Valve Test: (40CFR 63.420(E) (2))</th>
                                                            {/* <th scope="col">Pressure 18" W.C/ 5 min. duration &lt;5" W.C loss</th> */}
                                                            <th scope="col">Pressure 18" W.C/ 5 min. duration Less than 5" W.C loss</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr class="border-bottom">
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test #1</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Start Time</span>
                                                                            <input type="time" class="form-control" id="vaporValveTeststartTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={vaporValveTeststartTime} onChange={(date) => setVaporValveTeststartTime(date)} type="text" id="vaporValveTeststartTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" id="vaporValveTeststartTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaporValveTest.startTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Finish Time</span>
                                                                            <input type="time" class="form-control" id="vaporValveTestfinishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={vaporValveTestfinishTime} onChange={(date) => setVaporValveTestfinishTime(date)} type="text" id="vaporValveTestfinishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" id="vaporValveTestfinishTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaporValveTest.finishTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60" >Change </span>
                                                                            <input type="input" class="form-control mr-2" id="vaporValveTestchange" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.vaporValveTest.change : ""} />
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-12 mb-3">Test #2</div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Start Time</span>
                                                                            <input type="time" class="form-control" id="pressureTest3startTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={pressureTest3startTime} onChange={(date) => setPressureTest3startTime(date)} type="text" id="pressureTest3startTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" id="pressureTest3startTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest3.startTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60">Finish Time</span>
                                                                            <input type="time" class="form-control" id="pressureTest3finishTime" aria-describedby="emailHelp" style={{ width: "102px" }} />
                                                                            {/* <DatePicker showTimeSelect

                                                                                showTimeSelectOnly
                                                                                timeFormat="HH:mm aa"
                                                                                timeIntervals={1}
                                                                                timeCaption="Time"
                                                                                dateFormat="HH:mm aa" selected={pressureTest3finishTime} onChange={(date) => setPressureTest3finishTime(date)} type="text" id="pressureTest3finishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-4">Pres.</span>
                                                                            <input type="input" class="form-control mr-3" id="pressureTest3finishTimePressure" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest3.finishTimePressure : ""} aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 mb-3">

                                                                        <div class="d-flex align-items-center">
                                                                            <span class="mr-3 mm-60" >Change </span>
                                                                            <input type="input" class="form-control mr-2" id="pressureTest3Change" aria-describedby="emailHelp" defaultValue={inspectionDetails.dOTCargoTankTest ? inspectionDetails.dOTCargoTankTest.pressureTest3.change : ""} style={{ width: "100px" }} />
                                                                            <span>"W.C</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                                <div class="divider"></div>

                                <div class="form-footer-comon-wrap">
                                    <div class="row documents p-3">
                                        <div class="col-lg-12">
                                            {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                            <div class="row">
                                                {
                                                    documentList ? documentList.map((data, index) => (
                                                        <div class="col-md-12">
                                                            <div class="align-items-center">


                                                                {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                                            {(() => {
                                                                                if (data.extension == 'pdf') {
                                                                                    return (
                                                                                        <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                    )
                                                                                } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                    return (
                                                                                        <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                    )
                                                                                } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                    return (
                                                                                        <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                            {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                            {data.name ? data.name : "--"} </a></p></span>
                                                                    <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>

                                                                {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                            </div>
                                                        </div>


                                                    )) : ""

                                                }
                                            </div>
                                        </div><br />

                                    </div>
                                    <div class="row px-3">
                                        <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                                    </div>
                                    <AddInspectionName setEndDate={setEndDate} endDate={endDate} />



                                </div>

                                {statusInspection == "DRAFT" ? "" : <div class="divider"></div>}
                                {(() => {
                                    if (statusInspection == "DRAFT") {
                                        <></>
                                    } else {
                                        if (!roles.match("Technician")) {
                                            if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                                return (
                                                    <>
                                                        <div class="result-didspos">
                                                            <div class="p-4">
                                                                <span>Result</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Inspection Failed</b>
                                                                </div>
                                                            </div>
                                                            <div class="p-4">
                                                                <span>Disposition of Tank</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                                    <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId} />
                                                        {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} customerId={fkAccountId}/> */}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <><div class="result-didspos ">
                                                        <div class="p-4">
                                                            <span>Result</span>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                                            </div>
                                                        </div>
                                                        <div class="p-4">
                                                            <span>Disposition of Tank</span>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank returned to service</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId} />
                                                        {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                                                    </>
                                                )
                                            }
                                        }
                                    }
                                })()}

                                <div class="divider"></div>
                                <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-5px" }}>
                                    <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                    {statusInspection == "DRAFT" ? <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> : ""}
                                    {(() => {
                                        if (statusInspection == "DRAFT") {
                                            return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                        } else {
                                            if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
                                                return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("approved")}>Save</button></>)
                                            }
                                            else {
                                                return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                            }
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
};

DOTEdit.propTypes = {};

DOTEdit.defaultProps = {};

export default DOTEdit;