import React,{history,useEffect, useState, useMemo } from "react";
import Tableheader from "../../../utility/tableheader";
import Pagination from "../../../utility/pagination";
import { useNavigate ,useParams} from "react-router-dom";
import Search from "../../../utility/search";
import $ from "jquery";
import document_api from "./document_api";
import services from "../../../services/services";
import getcustomerLists from "../customer/customer_api";
import customerVehicle from "../vehicle/customer_vehicle";
import SinglePage from "./singlePage";
import pdf from "./pdf";
import Swal from "sweetalert2";
import { requestObject } from "../../../utility/requestObject";
import CheckAuthentication from "../../../utility/checkAuthentication";
//export const sampleBase64pdf="";
import moment from 'moment'
import { loadingComponent } from "../../../utility/Loading";
import { sendtocustomer } from "../../../utility/sendtocustomer";
// import Checkbox from "../../../utility/Checkbox";
import { sendtocustomerCheck } from "../../../utility/sendtocustomer";
import { document_details } from "../../../utility/DocumentDetails";
import { Manager,Owner } from "../../constant";
import { deleteDocuments } from "../../../utility/sendtocustomer";
import { LoadingComponentfordocuments } from "../../../utility/LoadingForDoc";
const Documents = () =>
{

  const inlineCss=
  {
      textAlign:"left"
  }
  const inlineWs=
  {
    whiteSpace: "break-spaces",
    paddingRight:"10px",
    minWidth:"150px"
  }

  const [customerName, setCustomerName] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [roles, setRoles] = useState("");

  const [search, setSearch] = useState("");
  const [Vehiclesearch, setVehicleSearch] = useState("");

  const headers = 
  [
    // { name: <Checkbox from='global' />, field: "documentId" },
    { name: "Type", field: "documentSubCategoryName",sortable: true,inlineStyle:inlineCss },
    { name: "Title", field: "Title",sortable: true, thStyle: 'width="40%"',inlineStyle:inlineCss },
    // { name: "Category", field: "Doc category",sortable: true,inlineStyle:inlineCss },
    { name: "Unit Number", field: "Unit Number",sortable: true,inlineStyle:inlineCss, inlineStyle:inlineWs },
    // { name: "Date", field: "Document Date",sortable: true,inlineStyle:inlineCss },
    { name: "Expiry Date", field: "expiryDate", sortable: true, inlineStyle: inlineCss},
    // { name: "Created By", field: "Created By",sortable: true,inlineStyle:inlineCss },
    { name: "Actions" }
  ];
  

  const navigate = useNavigate();
  const [ extension, setfileExtension ] = useState("");
  const [totalRecors,setTotalRecord] = useState([]);
  const [totalRecors1,setTotalRecord1] = useState([]);
  const [customerList,setCustomerList] = useState([]);
  const [vehicleList,setVehicleList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems1, setTotalItems1] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [totalpages, settotalPages] = useState();
  const [customerId, setCustomerId] = useState();
  const [vehicleId, setVehicleId] = useState();
  const [totalpages1, settotalPages1] = useState();
  const [displayPage, setdisplayPage] = useState([]);
  const [displayPage1, setdisplayPage1] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
  const [pdfurl, setpdfURL] = useState();
  const [searchTextValue, setSearchText] = useState("");
 
  /////////////////vehicle pagination////////////////////////////
    const handlePageChange = (offset,triggerevent) => 
    {
          
      document_api("",offset,ITEMS_PER_PAGE,searchTextValue,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,searchTextValue,sortingField,sortingOrder,"diplayItems");
    
    }
    /////////////////////////////vehicle search/////////////////////////
  const searchList = (searchText) => 
  {
      setSearchText(searchText);
      document_api("","1",ITEMS_PER_PAGE,searchTextValue,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,searchText,sortingField,sortingOrder,"diplayItems");

  }
  const onSortingChangeDocument = (field) =>
  {
    const order =
        field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    //document_api("",currentPage,ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,"",field?field:"",order,"");
    document_api("","1",ITEMS_PER_PAGE,searchTextValue,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,searchTextValue,field?field:"",order,"diplayItems");
  };
  const redirect=(page)=>
  {
         
        navigate(page);
        sessionStorage.setItem("naviationfrom","AddDocument");
  }
  const redirectAddoDocs=(customerId,vehicleId)=>
  {
  
         if(vehicleId!==undefined &&customerId!==undefined)
         {
             sessionStorage.setItem("naviationfrom","AddDocument");

           //    navigate("/addDocument/"+customerId+"/"+vehicleId);
           window.location.href="/addDocument/"+customerId+"/"+vehicleId;
         }else{
          Swal.fire({  
            position: 'center',  
            icon: 'error',  
            title: "",  
            text:"Please choose a customer and vehicle",
          
            showConfirmButton: true 
            
          }).then((result) => 
          {
                if (result.isConfirmed) 
                {
                 
                }
          });
         }
        
    
  }
  useEffect(() => 
  {
    
    $("#loadingfordocs").hide();
    setRoles(localStorage.getItem("Userroles"));
    if(CheckAuthentication)
    {
      $("#vehicleData").hide();
      document_api("","1",ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,"",sortingField,sortingOrder,"diplayItems");
     
      $('#display_pages_in_items').on('change', function() 
      {
        setitemperpage(this.value);
        document_api("","1",this.value,"",this.value,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,$("#searchValue").val(),sortingField,sortingOrder,"");
   
      });
    }
    else
    {
      sessionStorage.clear();
      localStorage.clear();
     //navigate("/login");
       window.location.href="/login";
    }
   
   
  },[]);
 



const searchCustomerList = (customerName) => 
{
  $("#customerNames").show();
 setSearch(customerName)
  setCustomerName(customerName);     
  getcustomerLists("1",ITEMS_PER_PAGE,customerName,ITEMS_PER_PAGE,setTotalItems1,settotalPages1,setCurrentPage1,setCustomerList,setTotalRecord1,setdisplayPage1,"",sortingField,sortingOrder,true);

}
const handleChange = (e,index,name,customerId) => {

  setCustomerId(customerId);
  setSearch(name)
  if(e.target.value!=="")
  {
    $("#customerNames").hide();
    $("#vehicleData").show();
  }else{
    $("#vehicleData").hide();
  }
  //onChange(event.target.value)
  $("#searchText").focus();
}

const handleChange_vhicle = (event,index,vehicleName,vehicleId) => {
  setVehicleSearch(vehicleName);
  setVehicleId(vehicleId);
  $("#vehicleListData").hide();
  //onChange(event.target.value)
}
  /////////////////////////////vehicle search/////////////////////////
  const searchVehicleList = (vehicleName) => 
  {
    $("#vehicleListData").show(); 
     setVehicleSearch(vehicleName);
     customerVehicle(customerId,"1",ITEMS_PER_PAGE,vehicleName,"",ITEMS_PER_PAGE,setTotalItems1,settotalPages1,setCurrentPage1,setVehicleList,setTotalRecord1,setdisplayPage1,'','','',true);

  }
  ///////////////////////////////////////////////////////////////////////
  const base64toBlob = (data) => 
  {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
};

  return(
  <>
  {LoadingComponentfordocuments}
    {loadingComponent}
     <div class="main-panel">
        <div class="content-wrapper" style={{display: "block;"}}>
         <div class="row">
              <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                <h4 class="font-weight-bold my-4 text-uppercase">Documents</h4>
             </div>
         </div>
        
          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto grid-margin stretch-card">
              <div class="card">
                <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                    <div class="search position-relative align-self-start mb-1 mt-1">
                        {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by VIN / Vehicle No" class="pl-5"/>
                       <a href="#" class="search-icon"> 
                           <img src="images/icons/search.svg" alt=""/>
                       </a> */}
                        
                        <Search
                                                   placeholderName="Search by Type / Title "
                                                   onSearch={value => {
                                                      searchList(value);
                                                   }}
                        
                                                />

                    <a href="#" class="search-icon"> 
                       <img src="images/icons/search.svg" alt=""/>
                     </a>
                    </div>
                    <div class="d-flex align-self-start mb-1 mt-1">
                    {/* {roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => sendtocustomer()} class="btn btn-success" style={{"margin-right":"15px"}} >Send to Customer</a>} */}
                    {roles.match("Technician")||roles.match("Inspector")?"":<a href="" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#adddocs">Add Document</a>}
                    </div>
                </div>
                <div class="card-body pt-0 pb-0">
                 
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive  border-bottom">
                      
                        <table class="table">
                        <tr>
                                {headers.map(({ name, field, sortable,thStyle,inlineStyle }) => (
                                    <th
                                        key={name}
                                        onClick={() =>
                                            sortable ? onSortingChangeDocument(field) : null
                                        }
                                        class={thStyle} style={inlineStyle}>
                                        {name}

                                        {sortingField && sortingField === field && (

                                            sortingOrder === "asc"
                                                ? "↑"
                                                : "↓"
                                        )}
                                        
                                    </th>
                                ))}
                            </tr>
                          {/* <Tableheader
                                  headers={headers}
                                
                              /> */}
                      
                         
                           <tbody> 
                                  {
                                                               documentList.length>0?documentList.map((data, index) => (
                                                            <tr>
                                                              {/* <td style={{ width: "5%" }}>
                                                                  <input type="checkbox" name="documentIds" onChange={(event) => sendtocustomerCheck(data.id)} value={data.id}/>
                                                                   </td> */}
                                                               <td style={{ width: "25%", whiteSpace:"break-spaces", lineHeight:"18px", paddingRight:"15px" }}>{data.documentSubCategoryName != '' ? data.documentSubCategoryName : '--'}</td>
                                                               <td style={{ width: "40%", whiteSpace:"break-spaces", lineHeight:"18px", paddingRight:"15px" }}>{data.title != '' ? data.title : '--'}</td>
                                                               {/* <td>{data.documentCategoryName} </td> */}
                                                               <td style={{ width: "5%", whiteSpace:"break-spaces", lineHeight:"18px", paddingRight:"15px" }}>{data.unitNumber?data.unitNumber:"--"}</td>
                                                               {/* <td style={{ width: "15%", whiteSpace:"break-spaces", lineHeight:"18px", paddingRight:"15px",minWidth:"80px" }}>{data.documentDate?moment(data.documentDate).format('MM-DD-YYYY'):"--"}</td> */}
                                                               <td style={{ width: "15%", whiteSpace:"break-spaces", lineHeight:"18px", paddingRight:"15px",minWidth:"80px" }}>{data.expiryDate?moment(data.expiryDate).format('MM-DD-YYYY'):"--"}</td>
                                                               {/* <td style={{ width: "10%", whiteSpace:"break-spaces", lineHeight:"18px", paddingRight:"15px" }}>{data.createUser?data.createUser:"--"}</td> */}
                                                               <td style={{width:"50px"}}><a class="btn btn-outline-primary text-uppercase"  onClick={() => document_details(data.id,data.filePath)}>View</a> {roles.match(Manager)||roles.match(Owner)?<a onClick={() =>deleteDocuments(data.id,data.unitNumber != '' ? data.unitNumber : '',data.title != '' ? data.title : '')} class="btn btn-outline-primary text-uppercase">Delete</a>:""}
                                                               &nbsp;{data.documentCategoryName === "Inspection Documents" ? "" : roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/editDocument/" + data.fkCustomerId + "/" + data.fkVehicleId + "/" + data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>}  
                                                               </td>
                                                            </tr>
                                                               )):""
                                  }
                                                         
                            </tbody>
                       </table>
                      </div>
                      
                    </div>
                    { documentList.length>0 ? documentList.map((data, index) => { }) : 
                    <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                      </div>
                      }

                  </div>
                  </div>

                  <div class="d-flex align-items-center justify-content-between p-3">
                  <div class="d-flex">
                    <div class="dropdown show mr-3 ">
                    <select class="form-control custome-select" class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                      {
                                                                  displayPage.map((data, index) => (
                                                                     <option value={data}>{data}</option>
                                                                  ))
                                                      }
                                                      </select>
                      </div>
                      <span class="d-flex align-items-center pagenation-mob">{currentPage*ITEMS_PER_PAGE<=totalRecors?currentPage*ITEMS_PER_PAGE:totalRecors} out of {totalRecors} Records Displayed</span>
                   </div>
                    <nav aria-label="Page navigation example">
                    <Pagination
                            total={totalRecors}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            //onPageChange={page => setCurrentPage(page)}
                            onPageChange={handlePageChange}
                        />
                        {/* <ul class="pagination mb-0">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item"><a class="page-link" href="#">1</a></li>
                          <li class="page-item"><a class="page-link" href="#">2</a></li>
                          <li class="page-item"><a class="page-link" href="#">3</a></li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul> */}
                      </nav>
                </div>

                </div>
               
              </div>
             
            </div>
        </div>
      
                <div class="modal fade" id="adddocs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Document</h5>
                                <button type="button" class="close" data-bs-dismiss="modal"  aria-label="Close">
                                   <span aria-hidden="true" >×</span>
                                </button>
                            </div>
                            <div class="modal-body scroll-auto">
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                        {/* <Search
                                                   placeholderName="Customer name"
                                                   onSearch={value => {
                                                    searchCustomerList(value);
                                                   }}
                                                   value={search}
                                                /> */}
<input type="text" id="searchText" value={search} placeholder="Search with customer name" className="form-control searchValues" onChange={e => {
                      searchCustomerList(e.target.value);
                    }} />
<a href="#" class="search-icon"> <img src="images/icons/search.svg" alt="" /></a>
                                            {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100" />
                                            <a href="#" class="search-icon"> <img src="images/icons/search.svg" alt="" /></a> */}
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 px-3" id="customerNames">
                                    {
                                            customerList?customerList.map((data, index) => ( 
                                        <div class="form-check input-align-right align-items-center d-flex w-100  mt-3 mb-3" onClick={(e)=> handleChange(e,index,data.customerName,data.customerId) }>
                                            <input class="form-check-input" type="hidden" name="customerId" value={data.customerId} id="flexRadioDefault1"   />

                                            <div class="d-flex align-items-center" id={"selectedCustomer" + index}>
                                                <div class="add-owner-logo">
                                                    <img src="images/transport-logo.jpg" alt="" />
                                                </div>
                                               
                                               

                                               <label class="form-check-label" for="flexRadioDefault1">  <b>{data.customerName}</b> </label>
                                              
                                               
                                            </div>
                                        </div>)):"no record"
                                     }


                                    </div>
                                </div>

                                <div class="row mb-2" id="vehicleData">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                          {/* <Search
                                                    placeholderName="VIN Number"
                                                    onSearch={value => {
                                                      searchVehicleList(value);
                                                    }}
                          
                                          /> */}
                                           <input type="text" id="searchText"  value={Vehiclesearch}  placeholder="Search with VIN number" className="form-control searchValues" onChange={e => searchVehicleList(e.target.value)} />
                                          <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt="" /></a>
                                            {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100" />
                                            <a href="#" class="search-icon"> <img src="images/icons/search.svg" alt="" /></a> */}
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 px-3" id="vehicleListData">
                                    {
                                            vehicleList?vehicleList.map((data, index) => ( 
                                        <div class="form-check input-align-right align-items-center d-flex w-100  mt-3 mb-3" onClick={ (e)=>handleChange_vhicle(e,index,data.vinNumber,data.id) }>
                                            <input class="form-check-input" type="hidden" name="flexRadioDefault" value={data.id} id="flexRadioDefault1"    />
                                            <div class="d-flex align-items-center">
                                                <div class="add-driver-logo">
                                                    <img src="images/driver.png" alt="" />
                                                </div>
                                                <label class="form-check-label" for="flexRadioDefault1"><b>{data.vinNumber}</b><i class="input-helper"></i></label>
                                            </div>
                                        </div>
                                        )):"no record"
                                     }
                                  


                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <a class="btn btn-primary" onClick={() => redirectAddoDocs(customerId,vehicleId)} data-bs-dismiss="modal" >Select</a>
                                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
  </>
)};

Documents.propTypes = {};

Documents.defaultProps = {};

export default Documents;
