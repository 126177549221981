import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import $ from 'jquery';
import moment from 'moment'
import Pagination from "../../../utility/pagination";

import inspectionOrderList from "../inspection/inspectionOrderList";
import { loadingComponent } from "../../../utility/Loading";
import vehicle_details_api from "./vehicle_details_api";
import { Technician,Inspector, Manager,Owner } from "../../constant";
const InspectionOrderList = () => {

   var roles = localStorage.getItem("Userroles");

   const [vechileDetails, setVehicleDetails] = useState([]);
   const [cid, setCustomerId] = useState([]);
   const [loading, setLoading] = useState(false);

   const { id, vin } = useParams();
   var vid = id;
   const inlineCss =
   {
      textAlign: "left"
   }
   const inlineCssTd = {
      whiteSpace: "breakSpaces",
      maxWidth: "150px"
   }
   let navigation = useNavigate();
   const [totalInspectionList, setTotalInspectionList] = useState(0);
   const [totalInspectionPages, settotalinspectionPages] = useState(0);
   const [inspectioCurrentPage, setInspectionCurrentPage] = useState(0);

   const [displayPageForInspection, setdisplayPageForInspection] = useState([]);

   const [inspectionLists, setInspectionList] = useState("");

   const [ITEMS_PER_PAGE, setitemperdocs] = useState(25);
   const [inspectioname, setInspectionName] = useState("");
   const [sortingField, setSortingField] = useState("");
   const [sortingOrder, setSortingOrder] = useState("");

   const headers =
      [
         { name: "Title", field: "Document Title", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },
         { name: "Category", field: "Doc category", sortable: true, inlineStyle: inlineCss },
         { name: "Sub category", field: "Doc sub category", sortable: true, inlineStyle: inlineCss },
         { name: "Vehicle number", field: "Vehicle number", sortable: true, inlineStyle: inlineCss },
         { name: "Expiry Date", field: "Expiry Date", sortable: true, inlineStyle: inlineCss },
         // { name: "Created By", field: "Created By", sortable: true, inlineStyle: inlineCss },
         { name: "Actions" }
      ];

   const Inspectionheaders =
      [
         { name: "Customer Name", field: "Customer Name", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },
         { name: "Unit Number", field: "Unit number", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },
         { name: "VIN Number", field: "VIN Number", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },
         { name: "Inspection", field: "Inspection Title", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },

         { name: "Inspected On", field: "Inspected On", sortable: false, inlineStyle: inlineCss },
         // { name: "Inspected By", field: "Inspected By", sortable: true, inlineStyle: inlineCss },
         { name: "Status", field: "Status", sortable: false, inlineStyle: inlineCss },
         { name: "Actions" }
      ];
   const onSortingChangeDocument = (field) => {

      const order =
         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

      setSortingField(field);
      setSortingOrder(order);
      //  veh_docs(id, currentPage, ITEMS_PER_PAGE_DOCS, "", field, sortingOrder, "");

   };
   useEffect(() => {
      // setVehicleId(vid);

      // getInspectionType();
      // document_api(cid,"1",ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,vin);
      //vehicle_Details(id);
      setInspectionName("");
      //  veh_docs(id, "1", ITEMS_PER_PAGE_DOCS, sortingField, sortingOrder, "diplayItems");
      //vehicle_details_api(id, setVehicleDetails, setCustomerId);
      inspectionOrderList(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "");

   }, []);
   $('#display_pages_in_items').on('change', function () {
      setitemperdocs(this.value);
      inspectionOrderList(id, "1", this.value, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "");
   });






   /////////////////vehicle pagination////////////////////////////
   const handlePageChangeForInspection = (offset) => {

      inspectionOrderList(id, offset, ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, inspectioname);

   }

   ////////////inspection details////////////////////////////////////
   const inspection_details = (taskOrderId,inspectionTypeNname, inspectionStatus, fkAccountId, id, fkCustomerId,vid) => {
      fkAccountId = fkCustomerId;
      // vehicle_details_api(vehicleId, setVehicleDetails, setCustomerId, setLoading);
      localStorage.setItem("customerId", fkCustomerId);
      localStorage.setItem("vehicleId", vid);
      sessionStorage.setItem("naviationfrom", "addinspection");

      if (inspectionTypeNname.match("Cargo Tank Thickness Test")) {

         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/cargoTankThicknessTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
         } else {
            navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }
         // navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid);
      } else if (inspectionTypeNname.match("WET Test Certification")) {
         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/wetTestCertificationEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      } else if (inspectionTypeNname.match("Leakage Test") || inspectionTypeNname.match("LeakageTest")) {

         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/leakageTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      }else if (inspectionTypeNname.match("Leakage Test (Method 27)")) {

         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/leakageTestMethod27Edit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/leakageTestMethod27Read/" + fkAccountId + '/' + id + '/' + vid);
         }

      }
      else if (inspectionTypeNname.match("DOT Cargo Tank Test") || inspectionTypeNname.match("Ohio EPA")) {
         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/dotCargoEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid);
         }
      }
      else if (inspectionTypeNname.match("Internal Visual Inspection")) {
         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/internalVisualEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
         }
         // navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
      }
      else if (inspectionTypeNname.match("Pressure test - Retest Inspection") || inspectionTypeNname.match("Pressure Test/Retest Inspection") || inspectionTypeNname.match("Pressure Vaccum test - Certificate application")) {

         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/pressureTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      }
      else if (inspectionTypeNname.match("Carrier Equipment Inspection")) {
         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/carrierEquipmentEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/carrierEquipmentRead/" + fkAccountId + '/' + id + '/' + vid);
         }
      }
      else if (inspectionTypeNname.match("Annual Vehicle Inspection")) {
         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/annualVehicle1Edit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      }
      else if (inspectionTypeNname.match("External Visual Inspection") || inspectionTypeNname.match("External/Visual Inspection")) {
         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/editExternalVisual/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      } else if (inspectionTypeNname.match("Gassoline Delivery Tank - Pressure Vaccum Test")) {
         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/gasolineDeliveryTankPressureVacuumTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }
         //  navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
      } else if (inspectionTypeNname.match("Vapor Tightness Certification")) {
         if (roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)) {
            navigation("/inspectionVaporTightnessCertificateEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid);
         }
      }


   }

   return (
      <>


         {loadingComponent}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block;" }}>
               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <h4 class="font-weight-bold my-4 text-uppercase">Inspections to be approved</h4>
                  </div>
               </div>

               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto grid-margin stretch-card">
                     <div class="card">
                        <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">

                           {/* <select class="form-control" style={{ maxWidth: "300px" }} id="inspectionType" onChange={e => searchInspectionList(e.target.value)}>


                              <option value="">Select</option>
                              {
                                 inspectionTypes ? inspectionTypes.map((inspection, index) => (
                                    <option value={inspection.name}>{inspection.name}</option>
                                 )) : <option value=""></option>
                              }
                           </select>&nbsp;&nbsp;&nbsp; */}
                        </div>
                        <div class="card-body pt-0 pb-0">

                           <div class="row">
                              <div class="col-12">
                                 <div class="table-responsive border-top  border-bottom ">



                                    <table class="table">
                                       <thead>
                                          <tr >
                                             {Inspectionheaders.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                <th
                                                   key={name}
                                                   onClick={() =>
                                                      sortable ? onSortingChangeDocument(field) : null
                                                   }
                                                   class={thStyle} style={inlineStyle}>
                                                   {name}

                                                   {sortingField && sortingField === field && (

                                                      sortingOrder === "asc"
                                                         ? "↓"
                                                         : "↑"
                                                   )}

                                                </th>
                                             ))}
                                          </tr>
                                       </thead>
                                       <tbody>


                                          {
                                             inspectionLists? inspectionLists.map((inspection, index) => (
                                                <tr className="inspectionorderlist text-capitalize">
                                                   <td>{inspection.customerName ? inspection.customerName : "--"}</td>
                                                   <td>{inspection.vehicleDetails ? inspection.vehicleDetails.unitNumber : "--"}</td>
                                                   <td>{inspection.vehicleDetails ? inspection.vehicleDetails.vinNumber : "--"}</td>
                                                   <td style={{ maxWidth: "150px", whiteSpace: "break-spaces", lineHeight: "20px" }}>{inspection.inspectionType ? inspection.inspectionType.name : "--"}</td>

                                                   <td>{inspection.inspectedDate ? moment(inspection.inspectedDate).format('MM-DD-YYYY') : "--"}</td>
                                                   {/* <td>{inspection.inspectedBy ? inspection.inspectedBy : "--"}</td> */}
                                                   <td>
                                                      {(() => {
                                                         if (inspection.status == 'approve pending' || inspection.status == "Approved pending" || inspection.status == "APPROVED PENDING") {
                                                            return (
                                                               <span class=" status-txt approve-pending">To Be Approved</span>
                                                            )
                                                         } else if (inspection.status == 'Rejected') {
                                                            return (
                                                               <span class=" status-txt reject">{inspection.status ? inspection.status : "--"}</span>
                                                            )
                                                         } else if (inspection.status == 'draft') {
                                                            return (
                                                               <span class=" status-txt draft">{inspection.status ? inspection.status : "--"}</span>
                                                            )
                                                         } else if (inspection.status == 'approved' || inspection.status == 'approve' || inspection.status == 'APPROVED') {
                                                            return (
                                                               <span class=" status-txt approved">{inspection.status ? inspection.status : "--"}</span>
                                                            )
                                                         } else {
                                                            return (
                                                               <>{inspection.status ? inspection.status : "--"}</>
                                                            )
                                                         }
                                                      })()}
                                                      {/* {inspection.status ? inspection.status : "--"} */}
                                                   </td>
                                                   <td style={{ width: "50px" }}><a onClick={() => inspection_details(inspection.taskOrderId,inspection.inspectionType.name, inspection.status, inspection.fkAccountId, inspection.id, inspection.fkCustomerId,inspection.vehicleDetails.id)} class="btn btn-outline-primary text-uppercase">
                                                      {/* {(() => {
                                                         if (inspection.status == 'approved' || inspection.status === 'Reject' || inspection.status === 'approve' || inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status === 'Rejected' || inspection.status == 'APPROVE PENDING' || inspection.status == 'APPROVED') {
                                                            return (
                                                               <>View</>
                                                            )
                                                         } else if(inspection.status=="DRAFT") {
                                                            return (
                                                               <>Edit</>
                                                            )
                                                         }
                                                      })()} */}

{(() => {

// if (inspection.status == 'approved' || inspection.status === 'Reject' || inspection.status === 'approve' || inspection.status === 'Rejected' || inspection.status == 'APPROVED') {
// return (
//    <>View</>
// )
// }else if(inspection.status=="DRAFT"){

//    return (
//       <>Edit</>
//    )
// }
 if(roles.match(Technician)){
if (inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status == 'APPROVE PENDING') {
return (
   <>View</>
)
}
}else if(roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)){
if ( inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status == 'APPROVE PENDING' ) {
return (
   <>Edit</>
)
}
} else {
return (
   <>View</>
)
}
})()}

                                                   </a>
                                                   </td>
                                                </tr>
                                             )) : ""
                                          }



                                          {/* <tr>
                                                            <td>External Visual Inspection</td>
                                                            <td>External Visual Inspection </td>
                                                            <td>05-08-2020</td>
                                                            <td>James</td>
                                                            <td><span class=" status-txt approve-pending ">Approve Pending</span></td>
                                                            <td><a href="#" class="btn btn-outline-primary text-uppercase">View</a></td>
                                                         </tr> */}


                                       </tbody>
                                    </table>
                                 </div>
                                 {inspectionLists? "": <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span></div>}
                              </div>

                           </div>
                        </div>
                        {inspectionLists ?
                           <div class="d-flex align-items-center justify-content-between p-3">
                              <div class="d-flex">
                                 <div class="dropdown show mr-3 ">
                                    {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        25
                                                      </a>

                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item" href="#">25</a>
                                                        <a class="dropdown-item" href="#">50</a>

                                                      </div> */}
                                    <select class="btn gray-mid-color dropdown-toggle  form-control custome-select" id="display_pages_in_items" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       {
                                          displayPageForInspection.map((data, index) => (
                                             <option value={data}>{data}</option>
                                          ))
                                       }
                                    </select>
                                 </div>
                                 <span class="d-flex align-items-center pagenation-mob">{inspectioCurrentPage * ITEMS_PER_PAGE <= totalInspectionPages ? inspectioCurrentPage * ITEMS_PER_PAGE : totalInspectionPages} out of {totalInspectionPages} Records Displayed</span>
                              </div>
                              <nav aria-label="Page navigation example">
                                 <Pagination
                                    total={totalInspectionPages}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    currentPage={inspectioCurrentPage}
                                    //onPageChange={page => setCurrentPage(page)}
                                    onPageChange={handlePageChangeForInspection}
                                 />

                              </nav>
                           </div> : ""}

                     </div>

                  </div>
               </div>
            </div>

         </div>
      </>
   )
};

InspectionOrderList.propTypes = {};

InspectionOrderList.defaultProps = {};

export default InspectionOrderList;
