import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";

const PressureTest = () => {
    const {taskOrderId,fkAccountId, inspectionId, vid } = useParams();
    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [fileName, setfileName] = useState("");
    const [inspectionDetails, setInspectionDetails] = useState("");
    const [documentList, setDocumentLists] = useState([]);
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());

    $(document).ready(function () {

        $("#unitDesignText,#appliedUnitText,#MC300301002303305300,#MC304307,#MC310311312,#MC330331,#MC336,#DOT40e,#DOT407,#DOT412").keypress(function (e) {
        //     if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
        //              return false;
        //   }
        if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
        ((e.which < 48 || e.which > 57) &&
          (e.which != 0 && e.which != 8))) {
            return false;
      }
  
        var text = $(this).val();
  
    if ((text.indexOf('.') != -1) &&
      (text.substring(text.indexOf('.')).length > 2) &&
      (e.which != 0 && e.which != 8) &&
      ($(this)[0].selectionStart >= text.length - 2)) {
          return false;
    }
         });
      });

      $(document).ready(function () {

        $("#thicknessDesignPressure,#mawp").keypress(function (e) {
      
            
        if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
        ((e.which < 48 || e.which > 57) &&
          (e.which != 0 && e.which != 8))) {
            return false;
      }
  
        var text = $(this).val();
  
    if ((text.indexOf('.') != -1) &&
      (text.substring(text.indexOf('.')).length > 2) &&
      (e.which != 0 && e.which != 8) &&
      ($(this)[0].selectionStart >= text.length - 2)) {
          return false;
    }
         });
      });

      const redirect=(page)=>
      {
      
         navigate(page);
      
      }

      const autoFillUnitDesign=()=>{
        var thicknessDesignPressure = $('#thicknessDesignPressure').val();
        $("#unitDesignText").val(thicknessDesignPressure);
      }
    const handleSubmit = () => {

        let form_is_valid = true;

        var thicknessDesignPressure = $('#thicknessDesignPressure').val();
        if (!thicknessDesignPressure) {
            form_is_valid = false;
            $("#thicknessDesignPressure-message").html('*Test pressure is required');

            // $('html, body').animate({
            //     scrollTop: $("#inspectionType").offset().top
            // }, 500);
            $('html,body').animate({ scrollTop: "500px" }, 1000);
            return false

        } else {

            $("#thicknessDesignPressure-message").html('');
            form_is_valid = true;
            // $("#unitDesignText").val(thicknessDesignPressure);
        }



        var mawp = $('#mawp').val();
        if (!mawp) {
            form_is_valid = false;
            $("#mawp-message").html('*MAWP is required');
            // $('html, body').animate({
            //     scrollTop: $("#inspectionType").offset().top
            // }, 500);
            $('html,body').animate({ scrollTop: "500px" }, 1000);
            return false
        } else {
            $("#mawp-message").html('');
            form_is_valid = true;
        }

        var closeAllValves = $("input[name='closeAllValves']:checked").val();
        var internalValves = $("input[name='internalAllValves']:checked").val();
        var externalValves = $("input[name='externalvalves']:checked").val();

        var inspectallsurfaces = $("input[name='inspectallsurfaces']:checked").val();
        var wheel = $("input[name='5thwheel']:checked").val();
        var removesteam = $("input[name='removesteam']:checked").val();

        console.log(closeAllValves + '' + internalValves + '' + externalValves + '' + inspectallsurfaces + '' + wheel + '' + removesteam);

        if (closeAllValves == undefined || internalValves == undefined || externalValves == undefined || inspectallsurfaces == undefined || wheel == undefined || removesteam == undefined) {
            // $('html, body').animate({
            //     scrollTop: $("#inspectionType").offset().top
            //   }, 500);
            $('html,body').animate({ scrollTop: "600px" }, 1000);
            $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
            form_is_valid = false;

        } else {
            $('#error_message').html('');
        }

        return form_is_valid;

    }




    const save = (status) => {

        if (status == 'DRAFT') {
            var documentList=[];
  
            try
            {
              for(var i=0;i<inputList.length;i++)
              {
                  // console.log("filebase64=="+inputList[i].filetobase64);
                  var extension="";
                  var byteString="";
                  if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
                  {
                      extension="pdf";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
                  }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
                  {
                      extension="jpg";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:image/png;base64,"))
                  {
                      extension="png";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
                  {
                      extension="word";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
                  {
                      extension="bmp";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
                  {
                      extension="docx";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
                  {
                      extension="docx";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
                  {
                      extension="docx";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
                  {
                      extension="docx";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
                  {
                      extension="docx";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
                  {
                      extension="docm";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
                  {
                      extension="docx";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
                  {
                      extension="dotx";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
                  {
                      extension="dotx";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
                  }
                  else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
                  {
                      extension="dotm";
                      byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
                  }
                  else{
                     
                  }
       
       
                    documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
              }
            }catch(exception)
            {
            //   Swal.fire({  
            //       position: 'center',  
            //       icon: 'error',  
            //       title: "",  
            //       text:"Please attach document",
            //       showConfirmButton: true 
                  
            //     }).then((result) => 
            //     {
                      
            //           if(result.isConfirmed) 
            //           {
                        
                      
            //           }
            //     });
            //     return false;
            documentList.push();
            }
            if ($("#inspectedBy").val() === "") {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "Please enter inspectedBy",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {


                    }
                });
                return false;
            }

            var requestObjectInspection =
            {
                
                "type": "Pressure test - Retest Inspection",
                "inspectedBy": $("#inspectedBy").val(),
                "inspectedDate": $("#inspectedDate").val(),
                "verifiedBy": $("#verifiedBy").val(),
                "verifiedDate": $("#verifiedBy").val(),
                "workOrderNumber": $("#workOrderNumber").val(),
                "notes": $("#notes").val(),
                "status": status,
                "taskOrderId":taskOrderId,
                "inspectionType":
                {
                    "id": sessionStorage.getItem("selectedinspectionId"),
                   "name":sessionStorage.getItem("selectedinspectionType")
                },
                "result":
                {
                  "approvedCertify": "0",
                  "inspectionFailed": "0"
                },
                "dispositionOfVehicle":
                {
                  "vehicleReturnedServices": "0",
                  "vehicleWithdrawnFromService": "0",
                  "vehicleSentToAnotherRepairFacility": "0"
                },
                "vehicleDetails":
                {
                    "vinNumber": $("#vin").html(),
                    "lisencePlateNumber": $("#lisencePlateNumber").html(),
                    "id": vid,
                    "unitNumber": $("#lisencePlateNumber").html()
                },
                "pressureTest": {
                    "inspectAllSurfacesForLeaks": {
                        "closeAllValves": $("input[name='closeAllValves']:checked").val() === "1" ? "1" : "0",
                        "internalValves": $("input[name='internalAllValves']:checked").val() === "1" ? "1" : "0",
                        "externalValves": $("input[name='externalvalves']:checked").val() === "1" ? "1" : "0",
                    },
                    "reiterbeingPressureTested":
                    {
                        "inspectAllSurfacesAndComponent": $("input[name='inspectallsurfaces']:checked").val() === "1" ? "1" : "0",
                        "wheelPlateRemoved": $("input[name='5thwheel']:checked").val() === "1" ? "1" : "0"
                    },
                    "specification": {
                        "MC300301002303305300": $("#MC300301002303305300").val() ? $("#MC300301002303305300").val() : "",
                        "MC304307": $("#MC304307").val() ? $("#MC304307").val() : "",
                        "MC310311312": $("#MC310311312").val() ? $("#MC310311312").val() : "",
                        "MC330331": $("#MC330331").val() ? $("#MC330331").val() : "",
                        "MC336": $("#MC336").val() ? $("#MC336").val() : "",
                        "DOT40e": $("#DOT40e").val() ? $("#DOT40e").val() : "",
                        "DOT407": $("#DOT407").val() ? $("#DOT407").val() : "",
                        "DOT412": $("#DOT412").val() ? $("#DOT412").val() : ""
                    },
                    "systemPressureTest": {
                        "areThereAnyLeaks": $("input[name='removesteam']:checked").val()?$("input[name='removesteam']:checked").val(): ""
                    },
                    "unitdeSignPressure": {
                        "unitDesignInput": $('#unitDesignText').val()?$('#unitDesignText').val():'',
                        "unitDesign": $("input[name='unitDesign']:checked").val() === "1" ? "1" : "0"
                    },
                    "pressureAppliedToUnit": {
                        "appliedUnitText": $('#appliedUnitText').val(),
                        "appliedUnit": $("input[name='appliedUnit']:checked").val() === "1" ? "1" : "0"
                    },
                    "mawp":$('#mawp').val()?$('#mawp').val():'',
                    "testPressure":$('#thicknessDesignPressure').val()?$('#thicknessDesignPressure').val():''
                },

                "documentList": documentList

            };

            $("#loadingforsubmit").show();
            // setLoading(true);
            // const requestOptions = 
            // {
            //    method: 'POST',
            //    headers: { 'Content-Type': 'application/json'
            //             },

            //       body: JSON.stringify(requestObject)
            // };
            var requestOptions = requestObject.RequestHeader(requestObjectInspection);
            services.addInspection(requestOptions).then((res) => {
                // setLoading(false);
                $("#loadingforsubmit").hide();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: res.message,
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                    }
                });
            });
        } else {

            if (handleSubmit() == true) {


                var documentList=[];
  
                try
                {
                  for(var i=0;i<inputList.length;i++)
                  {
                      // console.log("filebase64=="+inputList[i].filetobase64);
                      var extension="";
                      var byteString="";
                      if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
                      {
                          extension="pdf";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
                      }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
                      {
                          extension="jpg";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:image/png;base64,"))
                      {
                          extension="png";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
                      {
                          extension="word";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
                      {
                          extension="bmp";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
                      {
                          extension="docx";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
                      {
                          extension="docx";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
                      {
                          extension="docx";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
                      {
                          extension="docx";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
                      {
                          extension="docx";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
                      {
                          extension="docm";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
                      {
                          extension="docx";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
                      {
                          extension="dotx";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
                      {
                          extension="dotx";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
                      }
                      else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
                      {
                          extension="dotm";
                          byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
                      }
                      else{
                         
                      }
           
           
                        documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
                  }
                }catch(exception)
                {
                //   Swal.fire({  
                //       position: 'center',  
                //       icon: 'error',  
                //       title: "",  
                //       text:"Please attach document",
                //       showConfirmButton: true 
                      
                //     }).then((result) => 
                //     {
                          
                //           if(result.isConfirmed) 
                //           {
                            
                          
                //           }
                //     });
                //     return false;
                documentList.push();
                }
                if ($("#inspectedBy").val() === "") {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "",
                        text: "Please enter inspectedBy",
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {


                        }
                    });
                    return false;
                }
                // alert($('#appliedUnitText').val()?$('#appliedUnitText').val():'');

                var requestObjectInspection =
                {
                    
                    "type": sessionStorage.getItem("selectedinspectionType"),
                    "inspectedBy": $("#inspectedBy").val(),
                    "inspectedDate": $("#inspectedDate").val(),
                    "verifiedBy": $("#verifiedBy").val(),
                    "verifiedDate": $("#verifiedBy").val(),
                    "workOrderNumber": $("#workOrderNumber").val(),
                    "notes": $("#notes").val(),
                    "status": status,
                    "taskOrderId":taskOrderId,
                    "inspectionType":
                    {
                        "id": sessionStorage.getItem("selectedinspectionId"),
                        "name":sessionStorage.getItem("selectedinspectionType")
                    },
                    "result":
                    {
                      "approvedCertify": "0",
                      "inspectionFailed": "0"
                    },
                    "dispositionOfVehicle":
                    {
                      "vehicleReturnedServices": "0",
                      "vehicleWithdrawnFromService": "0",
                      "vehicleSentToAnotherRepairFacility": "0"
                    },
                    "vehicleDetails":
                    {
                        "vinNumber": $("#vin").html(),
                        "lisencePlateNumber": $("#lisencePlateNumber").html(),
                        "id": vid,
                        "unitNumber": $("#lisencePlateNumber").html()
                    },
                    "pressureTest": {
                        "inspectAllSurfacesForLeaks": {
                            "closeAllValves": $("input[name='closeAllValves']:checked").val() === "1" ? "1" : "0",
                            "internalValves": $("input[name='internalAllValves']:checked").val() === "1" ? "1" : "0",
                            "externalValves": $("input[name='externalvalves']:checked").val() === "1" ? "1" : "0",
                        },
                        "reiterbeingPressureTested":
                        {
                            "inspectAllSurfacesAndComponent": $("input[name='inspectallsurfaces']:checked").val() === "1" ? "1" : "0",
                            "wheelPlateRemoved": $("input[name='5thwheel']:checked").val() === "1" ? "1" : "0"
                        },
                        "specification": {
                            "MC300301002303305300": $("#MC300301002303305300").val() ? $("#MC300301002303305300").val() : "",
                            "MC304307": $("#MC304307").val() ? $("#MC304307").val() : "",
                            "MC310311312": $("#MC310311312").val() ? $("#MC310311312").val() : "",
                            "MC330331": $("#MC330331").val() ? $("#MC330331").val() : "",
                            "MC336": $("#MC336").val() ? $("#MC336").val() : "",
                            "DOT40e": $("#DOT40e").val() ? $("#DOT40e").val() : "",
                            "DOT407": $("#DOT407").val() ? $("#DOT407").val() : "",
                            "DOT412": $("#DOT412").val() ? $("#DOT412").val() : ""
                        },
                        "systemPressureTest": {
                            "areThereAnyLeaks": $("input[name='removesteam']:checked").val()?$("input[name='removesteam']:checked").val(): ""
                        },
                        "unitdeSignPressure": {
                            "unitDesignInput": $('#unitDesignText').val()?$('#unitDesignText').val():'',
                            // "unitDesign": $("input[name='unitDesign']:checked").val() === "1" ? "1" : "0"
                            // "tankMaking": $("input[name='appliedUnit']:checked").val() === "1" ? "1" : "0"
                        },
                        // "tankMaking": {
                        //     "tankMaking": $("input[name='tankMaking']:checked").val() === "1" ? "1" : "0",
                        //     // "unitDesign": $("input[name='unitDesign']:checked").val() === "1" ? "1" : "0"
                        // },
                        "pressureAppliedToUnit": {
                            "appliedUnitText": $('#appliedUnitText').val()?$('#appliedUnitText').val():'',
                            "appliedUnit": $("input[name='appliedUnit']:checked").val() === "1" ? "1" : "0"
                            // "tankMaking": $("input[name='appliedUnit']:checked").val() === "1" ? "1" : "0"
                        },
                        "mawp":$('#mawp').val()?$('#mawp').val():'',
                        "testPressure":$('#thicknessDesignPressure').val()?$('#thicknessDesignPressure').val():''
                    },

                    "documentList": documentList

                };

                $("#loadingforsubmit").show();
                // setLoading(true);
                // const requestOptions = 
                // {
                //    method: 'POST',
                //    headers: { 'Content-Type': 'application/json'
                //             },

                //       body: JSON.stringify(requestObject)
                // };
                var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                services.addInspection(requestOptions).then((res) => {
                    // setLoading(false);
                    $("#loadingforsubmit").hide();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                            navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                        }
                    });
                });
            }
        }

    }
    return (
        <>


{LoadingComponentForSubmit}



            <div class="row mb-2">
                <div class="col-lg-12 col-md-12 m-auto ">
                    <div class="card">

                        <div class="row px-3">
                            <div class="w-100">
                                <h4 class="form-inspection-title text-center">Pressure Test / Retest Inspection</h4>
                                <span id="thicknessDesignPressure-message" class="error"></span>
                                <span id="mawp-message"  class="error"></span>
                                <p id="error_message" class="center error" ></p>
                            </div>
                            <WorkOrderNumberDiv />
                            <div class="">
                                <div class="inspection-wrap">
                                


                                    <div class="p-3 mb-4" style={{ backgroundColor: "#ccc" }}>
                                    <p>NOTE: FUSIBLE CAPS, PRESSURE RELIEF VALVES, VACUUM VENTS, RUPTURE DISC MUST BE REMOVED AND OUTLETS BLANKED OFF FOR THE PRESSURE TEST PROCEDURE(PROCEDURE MUST BE COMPLETED FOR EACH COMPARTMENT) </p>
                                        <p>NOTE: All reclosing pressure relief valves must be removed from the cargo tank for inspection and testing. Each reclosing pressure relief valve must open all the required set pressure and reseal to a leak tight condition at 90 percent of the of the set-to-discharge pressure prescribed for the applicable cargo tank specification.</p>
                                    </div>

                                    <div class="table-3 mb-4">
                                        <table class="table">
                                            <thead>
                                                {/* <tr>
                                                    <th scope="col">Specification</th>
                                                    <th scope="col">Test Pressure</th>
                                                </tr> */}
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-8 col-sm-12">MC 300, 301, 302, 303, 305, 306</div>
                                                            <div class="col-md-4 col-sm-12"> 
                                                            {/* <input type="text" class="form-control" id="MC300301002303305300" aria-describedby="emailHelp" width="90px" /> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>3 psig or design pressure. whichever is greater.</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-8">MC 304, 307 	                                                            </div>
                                                            <div class="col-md-4"> 
                                                            {/* <input type="text" class="form-control" id="MC304307" aria-describedby="emailHelp" width="90px" /> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>40 psig or 1.5 times the design pressure, whichever is greater.</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-8">MC 310,311,312</div>
                                                            <div class="col-md-4"> 
                                                            {/* <input type="text" class="form-control" id="MC310311312" aria-describedby="emailHelp" width="90px" /> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>3 psig, or 1.5 times the design pressure, whichever is greater.</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-8">MC 330,331</div>
                                                            <div class="col-md-4"> 
                                                            {/* <input type="text" class="form-control" id="MC330331" aria-describedby="emailHelp" width="90px" /> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>1.5 times either the MAWP or the re-rated pressure, whichever is applicable</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-8">MC 338</div>
                                                            <div class="col-md-4"> 
                                                            {/* <input type="text" class="form-control" id="MC336" aria-describedby="emailHelp" width="90px" /> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>1.25 times either the MAWP or the re-rated pressure, whichever is applicable.</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-8">DOT 406</div>
                                                            <div class="col-md-4"> 
                                                            {/* <input type="text" class="form-control" id="DOT40e" aria-describedby="emailHelp" width="90px" /> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>5 psig or 1.5 times the MAWP. whichever is greater</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-8">DOT 407</div>
                                                            <div class="col-md-4"> 
                                                            {/* <input type="text" class="form-control" id="DOT407" aria-describedby="emailHelp" width="90px" /> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>40 psig or 1.5 times the MAWP, whichever is greater</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-md-8">DOT 412</div>
                                                            <div class="col-md-4"> 
                                                            {/* <input type="text" class="form-control" id="DOT412" aria-describedby="emailHelp" width="90px" /> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>1.5 times the MAWP.</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="row align-items-center justify-content-start mb-3">
                                        <div class="col-md-6">
                                            <div className="unitDzine2">
                                            <lable>Test Pressure <span class="star">*</span></lable>
                                           <input type="text" class="form-control" id="thicknessDesignPressure" aria-describedby="emailHelp" width="90px" maxLength="7" onKeyUp={autoFillUnitDesign}/>
                                            </div>
                                            
                                          
                                        </div>
                                        <div class="col-md-6"> 
                                        <div className="unitDzine2">
                                        <lable>MAWP <span class="star">*</span></lable>
                                        <input type="text" class="form-control" id="mawp" aria-describedby="emailHelp" width="90px" maxLength="7"/>
                                        </div>
                                       
                                    </div>
                                    </div>

                                    {/* <div class="row align-items-center justify-content-start mb-3">
                                        <div class="col-md-10">MAWP</div>
                                        <div class="col-md-2"> <input type="text" class="form-control" id="mawp" aria-describedby="emailHelp" width="90px" /></div>
                                    </div> */}

                                    <div class="table-2 mb-4">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col"  className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td><p>Close all valves and fill tank with manhole cover open. Inspect all surfaces for leeks at all top and bottom component installations below water line. Are there any leaks? at the cleanouts - welds - valve sumps -nozzles.</p></td>
                                                    <td class="text-center">  <input type="radio" name="closeAllValves" aria-label="Radio button for following text input" value="1" /></td>
                                                    <td class="text-center">  <input type="radio" name="closeAllValves" value="0" aria-label="Radio button for following text input" /></td>
                                                </tr>

                                                <tr>
                                                    <td>Internal valves: open external valves - Inspect for leaks • close external valves. Are there any leaks?</td>
                                                    <td class="text-center">  <input type="radio" name="internalAllValves" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="internalAllValves" value="0" aria-label="Radio button for following text input" /></td>
                                                </tr>

                                                <tr>
                                                    <td>External valves: open Internal valves, fill piping with water • Inspect for leaks • close Internal valves. Are there any leaks?</td>
                                                    <td class="text-center">  <input type="radio" name="externalvalves" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="externalvalves" value="0" aria-label="Radio button for following text input" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="table-2 mb-4">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col"  className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>Inspect all surfaces and component installations for leaks • pay particular attention to manhole assembly area/internal valve sump. Are there any leaks?</td>
                                                    <td class="text-center">  <input type="radio" name="inspectallsurfaces" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="inspectallsurfaces" value="0" aria-label="Radio button for following text input" /></td>
                                                </tr>

                                                <tr>
                                                    <td>5th wheel plate removed & inspected</td>
                                                    <td class="text-center">  <input type="radio" name="5thwheel" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="5thwheel" value="0" aria-label="Radio button for following text input" /></td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>

                                    

                                    <div class="table-2 mb-4">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">STEAM/HEAT SYSTEM PRESSURE TEST</th>
                                                    <th scope="col"  className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                    <th scope="col"  className="text-center">N/A</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>Remove steam traps - Intransit heat lines, plug all outlets. Fill heat system with water, apply pressure (1.5 times system design pressure) hold pressure for 5 minutes for a successful test. Are there any leaks?</td>
                                                    <td class="text-center">  <input type="radio" name="removesteam" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="removesteam" value="0" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="removesteam" value="2" aria-label="Radio button for following text input" /></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="row align-items-center justify-content-start mb-3">
                                        <div class="col-md-6">
                                            <div className="unitDzine2">
                                            <lable>Unit design pressure</lable>
                                            <input type="text" class="form-control" id="unitDesignText" aria-describedby="emailHelp" width="90px" maxLength="7" />
                                            </div>
                                            
                                          
                                        </div>
                                        <div class="col-md-6"> 
                                        <div className="unitDzine2">
                                        <lable>Pressure applied to unit</lable>
                                        <input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" width="90px" maxLength="7"/>
                                        </div>
                                       
                                    </div>
                                    </div>

                                    <div class="table-2 mb-4">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col"  className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {/* <tr>
                                                    <td className="unitDzine"><span>Unit design pressure</span><input type="text" class="form-control" id="unitDesignText" aria-describedby="emailHelp" size="20" maxLength="7" /><span class="pl-3">Pressure applied to unit</span><input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" size="20" maxLength="7"/></td>
                                                    <td class="text-center"> </td>
                                                    <td class="text-center"> </td>
                                                </tr> */}

                                                <tr>
                                                    {/* <td className="unitDzine"><span>Pressure applied to unit</span><input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" width="40px" /><span></span></td> */}
                                                    <td>Tank marking: Date ("Month and Year") and service symbol (P) after all defects are corrected.</td>
                                                    <td class="text-center">  <input type="radio" name="appliedUnit" value="1" aria-label="Radio button for following text input" /></td>
                                                    <td class="text-center">  <input type="radio" name="appliedUnit" value="0" aria-label="Radio button for following text input" /></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                  
                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="row p-0">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>

                        {/* <div class="row p-3">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by<span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date<span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}
                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                        <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                                <button type="button" class="btn btn-primary mr-1" data-dismiss="modal"   onClick={() => save("DRAFT")}>Save as Draft</button>
                                {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a> */}
                                <button type="button" class="btn btn-primary mr-1" data-dismiss="modal"  onClick={() => save("APPROVED PENDING")}>Save</button>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
};

PressureTest.propTypes = {};

PressureTest.defaultProps = {};

export default PressureTest;
