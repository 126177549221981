import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
// import DatePicker from "react-datepicker";

import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const PressureTestRead = () => {
    const { fkAccountId, inspectionId, vid } = useParams();
    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [fileName, setfileName] = useState("");
    const [inspectionDetails, setInspectionDetails] = useState("");
    const [documentList, setDocumentLists] = useState([]);
    const [inspectionIds, setInspectionId] = useState('');
    const [approveCertify, setApproveCertify] = useState('');
    const navigate = useNavigate();
    const [expiryDate, setexpiryDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [verifiedDATE, setverifiedDate] = useState(new Date());
    const [roles, setRoles] = useState("");
    const [statusInspection, setStatus] = useState("");
    useEffect(() => {
        setRoles(localStorage.getItem("Userroles"));

        // vehicle_details_api(id, setVehicleDetails, setCustomerId); 

        getInpection(fkAccountId, inspectionId)
    }, []);

    const autoFillUnitDesign=()=>{
        var thicknessDesignPressure = $('#thicknessDesignPressure').val();
        $("#unitDesignText").val(thicknessDesignPressure);
      }

    function getInpection(fkAccountId, inspectionId) {
        var requestObjectForWet =
        {

            "id": inspectionId
        }

        var requestOptions = requestObject.RequestHeader(requestObjectForWet);

        services.getInspectionDetails(requestOptions).then((res) => {
            setInspectionId(res.rows.id);

            $("#mc300").html(res.rows.pressureTest ? res.rows.pressureTest.specification.MC300301002303305300 : "");
            $("#mc307").html(res.rows.pressureTest ? res.rows.pressureTest.specification.MC304307 : "");
            $("#mc310").html(res.rows.pressureTest ? res.rows.pressureTest.specification.MC310311312 : "");
            $("#mc330").html(res.rows.pressureTest ? res.rows.pressureTest.specification.MC330331 : "");
            $("#mc336").html(res.rows.pressureTest ? res.rows.pressureTest.specification.MC336 : "");
            $("#mc40").html(res.rows.pressureTest ? res.rows.pressureTest.specification.DOT40e : "");
            $("#mc407").html(res.rows.pressureTest ? res.rows.pressureTest.specification.DOT407 : "");
            $("#mc412").html(res.rows.pressureTest ? res.rows.pressureTest.specification.DOT412 : "");

            $("#unitDesignText").val(res.rows.pressureTest ? res.rows.pressureTest.unitdeSignPressure ? res.rows.pressureTest.unitdeSignPressure.unitDesignInput : "" : "");
            $("#appliedUnitText").val(res.rows.pressureTest ? res.rows.pressureTest.pressureAppliedToUnit ? res.rows.pressureTest.pressureAppliedToUnit.appliedUnitText : "" : "");

            //  $('input[name="unitDesign"][value="' + res.rows.pressureTest?res.rows.pressureTest.unitdeSignPressure?res.rows.pressureTest.unitdeSignPressure.unitDesign:"":"" + '"]').trigger("click");
            //  $('input[name="appliedUnit"][value="' + res.rows.pressureTest?res.rows.pressureTest.PressureAppliedToUnit?res.rows.pressureTest.unitdeSignPressure.appliedUnitText:"":""+ '"]').trigger("click");


            setInspectionDetails(res.rows);
            setDocumentLists(res.rows.documentList ? res.rows.documentList : "");
            $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
            //  sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
            setStatus(res.rows.status);
            if (res.rows) {
                if (res.rows.verifiedDate) {
                    setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
                }
                if (res.rows.expiryDate) {
                    setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
                }
            }
        });

    }

    const approveAndcertify = function (e) {
        setApproveCertify($("input[name='approvedCertify']:checked").val());

    }
    const redirect = (page) => {

        navigate(page);

    }
    const save = (status) => {

        // console.log("results=="+JSON.stringify(inputList));
        if ($("#inspectedBy").val() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please enter Inspected by",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }

        if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please select disposition of tank",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }
        if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please select inspection result",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }
        if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

        } else {

            status = "Rejected";
        }
        var requestObjectInspection =
        {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedByBate").val(),
            "status": status,

            "result": {
                "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
                "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
            },
            "dispositionOfVehicle": {
                "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
            }


        };

        $("#loadingforsubmit").show();
        // setLoading(true);
        // const requestOptions = 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json'
        //             },

        //       body: JSON.stringify(requestObject)
        // };
        var requestOptions = requestObject.RequestHeader(requestObjectInspection);
        services.certifyInspection(requestOptions).then((res) => {
            //   setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "",
                text: res.message,
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {
                    navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                }
            });
        });

    }
    return (
        <>
            {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>

                    <div class="row mb-0">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <div class="d-flex justify-content-between align-items-center my-4">
                            <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <HeaderBlock cids={fkAccountId} />
                        </div>
                    </div>


                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="card">

                                <div class="row mb-2">
                                    <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                        <VehicleBasicInfo customerId={vid} />
                                    </div>
                                </div>
                                <div class="divider"></div>

                                <div class="row p-3">
                                    <div class="col-lg-12">
                                        <h4 class="mb-4 form-inspection-title text-center">Pressure Test / Retest Inspection</h4>
                                    </div>
                                    <div class="col-lg-12"><WorkOrderNumberDiv status={"readOnly"} /></div>
                                    <div class="col-lg-12">
                                        <div class="inspection-wrap">

                                            <div class="p-3 mb-4" style={{ backgroundColor: "#ccc" }}>
                                                <p>NOTE: FUSIBLE CAPS, PRESSURE RELIEF VALVES, VACUUM VENTS, RUPTURE DISC MUST BE REMOVED AND OUTLETS BLANKED OFF FOR THE PRESSURE TEST PROCEDURE(PROCEDURE MUST BE COMPLETED FOR EACH COMPARTMENT) </p>
                                                <p>NOTE: All reclosing pressure relief valves must be removed from the cargo tank for inspection and testing. Each reclosing pressure relief valve must open all the required set pressure and reseal to a leak tight condition at 90 percent of the of the set-to-discharge pressure prescribed for the applicable cargo tank specification.</p>
                                            </div>

                                            <div class="table-3 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        {/* <tr>
                                                    <th scope="col">Specification.</th>
                                                    <th scope="col">Test Pressure</th>
                                                  </tr> */}
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8 col-sm-12">MC 300, 301, 302, 303, 305, 306</div>
                                                                    {/* <div class="col-md-4 col-sm-12"><b id="mc300"></b></div> */}
                                                                </div>
                                                            </td>
                                                            <td>3 psig or design pressure, whichever is greater. </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">MC 304, 307 	                                                            </div>
                                                                    {/* <div class="col-md-4 col-sm-12" id="mc307"><b></b></div> */}
                                                                </div>
                                                            </td>
                                                            <td>40 psig or 1.5 times the design pressure, whichever is greater.</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">MC 310, 311, 312</div>
                                                                    {/* <div class="col-md-4 col-sm-12"><b id="mc310"></b></div> */}
                                                                </div>
                                                            </td>
                                                            <td>3 psig, or 1.5 times the design pressure,whichever is greater.</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">MC 330,331</div>
                                                                    {/* <div class="col-md-4 col-sm-12"><b id="mc330"></b></div> */}
                                                                </div>
                                                            </td>
                                                            <td>1.5 times either the MAWP or the re-rated pressure, whichever is applicable</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">MC 338</div>
                                                                    {/* <div class="col-md-4 col-sm-12"><b id="mc336"></b></div> */}
                                                                </div>
                                                            </td>
                                                            <td>1.25 Times either lire MAWP or re-rated pressure, whichever is applicable.</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">DOT 406</div>
                                                                    {/* <div class="col-md-4 col-sm-12"><b id="mc40"></b></div> */}
                                                                </div>
                                                            </td>
                                                            <td>5 psig or 1.5 times the  MAWP, whichever is greater</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">DOT 407</div>
                                                                    {/* <div class="col-md-4 col-sm-12"><b id="mc407"></b></div> */}
                                                                </div>
                                                            </td>
                                                            <td>40 psig or 1.5 tunes the MAWP, whichever is greater</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center justify-content-between">
                                                                    <div class="col-md-8">DOT 412</div>
                                                                    {/* <div class="col-md-4 col-sm-12"><b id="mc412"></b></div> */}
                                                                </div>
                                                            </td>
                                                            <td>1.5 times the MAWP</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="row align-items-center justify-content-start mb-3">
                                                <div class="col-md-6">
                                                    <div className="unitDzine2">
                                                        <lable>Test Pressure</lable>
                                                        <input type="text" class="form-control" id="thicknessDesignPressure" value={inspectionDetails.pressureTest ? inspectionDetails.pressureTest.testPressure : ''} readOnly aria-describedby="emailHelp" width="90px" maxLength="7" onKeyUp={autoFillUnitDesign}/>
                                                    </div>


                                                </div>
                                                <div class="col-md-6">
                                                    <div className="unitDzine2">
                                                        <lable>MAWP</lable>
                                                        <input type="text" class="form-control" id="mawp" value={inspectionDetails.pressureTest ? inspectionDetails.pressureTest.mawp : ''} readOnly aria-describedby="emailHelp" width="90px" maxLength="7"/>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            {/* close all valves and fill tank with manhole cover open. Inspect all surfaces for leeks at all top and bottom component Installations below water line. Are there 
                                                        any leaks at the cleanouts - welds - valve sumps -nozzles */}
                                                            <th scope="col">Yes</th>
                                                            <th scope="col">No</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td><p>Close all valves and fill tank with manhole cover open. Inspect all surfaces for leeks at all top and bottom component installations below water line. Are there any leaks? at the cleanouts - welds - valve sumps -nozzles.</p></td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.closeAllValves == "1" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.closeAllValves == "0" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Internal valves: open external valves - Inspect for leaks • close external valves. Are there any leaks?</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.internalValves == "1" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.internalValves == "0" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>External valves: open Internal valves, fill piping with water • Inspect for leaks • close Internal valves. Are there any leaks?</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.externalValves == "1" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.inspectAllSurfacesForLeaks.externalValves == "0" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Yes</th>
                                                            <th scope="col">No</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>Inspect all surfaces and component installations for leaks • pay particular attention to manhole assembly area/internal valve sump. Are there any leaks?</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.reiterbeingPressureTested.inspectAllSurfacesAndComponent == "1" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.reiterbeingPressureTested.inspectAllSurfacesAndComponent == "0" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>5th wheel plate removed & inspected</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.reiterbeingPressureTested.wheelPlateRemoved == "1" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center">{inspectionDetails.pressureTest ? inspectionDetails.pressureTest.reiterbeingPressureTested.wheelPlateRemoved == "0" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>



                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">STEAM/HEAT SYSTEM PRESSURE TEST</th>
                                                            <th scope="col">Yes</th>
                                                            <th scope="col">No</th>
                                                            <th scope="col">N/A</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>Remove steam traps - Intransit heat lines, plug all outlets. Fill heat system with water, apply pressure (1.5 times system design pressure) hold pressure for 5 minutes for a successful test. Are there any leaks?</td>
                                                            <td class="text-center">  {inspectionDetails.pressureTest ? inspectionDetails.pressureTest.systemPressureTest.areThereAnyLeaks == "1" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center"> {inspectionDetails.pressureTest ? inspectionDetails.pressureTest.systemPressureTest.areThereAnyLeaks == "0" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center"> {inspectionDetails.pressureTest ? inspectionDetails.pressureTest.systemPressureTest.areThereAnyLeaks == "2" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="row align-items-center justify-content-start mb-3">
                                                <div class="col-md-6">
                                                    <div className="unitDzine2">
                                                        <lable>Unit design pressure</lable>
                                                        <input type="text" class="form-control" id="unitDesignText" aria-describedby="emailHelp" width="90px" maxLength="7" readOnly/>
                                                    </div>


                                                </div>
                                                <div class="col-md-6">
                                                    <div className="unitDzine2">
                                                        <lable>Pressure applied to unit</lable>
                                                        <input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" width="90px" maxLength="7" readOnly/>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Yes</th>
                                                            <th scope="col">No</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            {/* <td className="unitDzine"><span>Unit design pressure</span><input type="text" class="form-control" id="unitDesignText" aria-describedby="emailHelp" width="40px" /><span>Pressure applied to unit</span><input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" width="40px" readOnly /></td> */}
                                                            {/* <td class="text-center">  {inspectionDetails.pressureTest ? inspectionDetails.pressureTest.unitdeSignPressure.unitDesign == "1" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center"> {inspectionDetails.pressureTest ? inspectionDetails.pressureTest.unitdeSignPressure.unitDesign == "0" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td> */}
                                                            {/* <td class="text-center"> </td>
                                                            <td class="text-center"> </td> */}
                                                        </tr>

                                                        <tr>
                                                            {/* <td className="unitDzine"><span>Pressure applied to unit</span><input type="text" class="form-control" id="appliedUnitText" aria-describedby="emailHelp" width="40px" readOnly /><span></span></td> */}
                                                            <td>Tank marking: Date ("Month and Year") and service symbol (P) after all defects are corrected.</td>
                                                            <td class="text-center">  {inspectionDetails.pressureTest ? inspectionDetails.pressureTest.pressureAppliedToUnit.appliedUnit == "1" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                            <td class="text-center"> {inspectionDetails.pressureTest ? inspectionDetails.pressureTest.pressureAppliedToUnit.appliedUnit == "0" ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="divider"></div>
                                <div class="form-footer-comon-wrap">

                                    <div class="col-lg-12">
                                        {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                        <div class="row">
                                            {
                                                documentList ? documentList.map((data, index) => (
                                                    <div class="col-md-12">
                                                        <div class="d-flex align-items-center">
                                                            <img src="images/icons/pdf-icon.svg" alt="" />
                                                            <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                                {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                </div>
                                                            </a>
                                                            {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                        </div>
                                                    </div>


                                                )) : ""

                                            }
                                        </div>
                                    </div><br />
                                    {/* <div class="row documents p-3">
                                   <div class="col-lg-12">
                                  {documentList.length>0?<h4 class="mb-4">Documents</h4>:""} 
                                      <div class="row">
                                      {
                                                                                                documentList?documentList.map((data, index) => (
                                         <div class="col-md-4">
                                            <div class="d-flex align-items-center">
                                               <img src="images/icons/pdf-icon.svg" alt=""/>
                                               <a onClick={() => documentDetails_(data.filePath,data.extension)} class="ml-2"><b>{data.title}.{data.extension}</b></a>
                                            </div>
                                         </div>
                                        
                                       
                                         )):""
                                                                                               
                                       }
                                      </div>
                                   </div><br/>
                                   
                                </div> */}
                                    <div class="row documents p-3">
                                        <div class="col-lg-12">
                                            <h6 class="mb-4">Additional notes: </h6>
                                            {inspectionDetails ? inspectionDetails.notes : ''}
                                        </div>
                                    </div>
                                    <div class="divider2"></div>
                                    <div class="row performby p-4">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="d-flex flex-column">
                                                        <label for="">Test Performed By:</label>
                                                        <b>{inspectionDetails.inspectedBy}</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-flex flex-column">
                                                        <label for="">Date</label>
                                                        <b>{inspectionDetails.inspectedDate}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="divider"></div>
                                    {(() => {
                                        if (!roles.match("Technician")) {
                                            if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                                return (
                                                    <>
                                                        <div class="result-didspos">
                                                            <div class="p-4">
                                                                <span>Result</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Inspection Failed</b>
                                                                </div>
                                                            </div>
                                                            <div class="p-4">
                                                                <span>Disposition of Tank</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                                    <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                                    <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId} />
                                                        {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE}  status={"readOnly"} /> */}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        <div class="result-didspos ">
                                                            <div class="p-4">
                                                                <span>Result</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                                    <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                                    <b class="mb-0 ml-2">Inspection Failed</b>
                                                                </div>
                                                            </div>
                                                            <div class="p-4">
                                                                <span>Disposition of Tank</span>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                                    <b class="mb-0 ml-2">Tank returned to service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                                    <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                                    <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId} />
                                                        {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
                                                        {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />
                
               </div>
            </div>
         </div> */}

                                                    </>
                                                )
                                            }
                                        }
                                    })()}
                                </div>

                                <div class="divider2"></div>
                                {(() => {
                                    if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                    }
                                    else {
                                        return (<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1 ">Cancel</a>
                                            {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a> */}
                                            {roles.match("Technician") ? "" : <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                            {roles.match("Technician") ? <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a> : ""}
                                        </div></>)
                                    }
                                })()}
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        </>
    )
};

PressureTestRead.propTypes = {};

PressureTestRead.defaultProps = {};

export default PressureTestRead;
