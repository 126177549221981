import React, { history, useEffect, useState, useMemo } from "react";

const Releases = () => {

    return (
        <>

            <div class="pp w-100" style={{ marginTop: "-60px", background:"white" }}>
                <header >
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container" style={{ maxWidth: "1200px", margin: "auto" }}>
                            <a class="navbar-brand" href="/login"><img src="/img/logo-dotstow.svg" style={{ width: "90px" }} /></a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                                <a class="btn btn-outline-primary text-uppercase" href="/login" >Home</a>
                                {/* <a href="#" data-toggle="modal" data-target="#edit_Contact" >Edit</a> */}
                            </div>
                        </div>
                    </nav>
                </header>

                <section>
                    <section class="cont-head">
                        <div class="container" >
                            <div class="row">
                                <div class="col-12 m-auto"  >
                                    <div>
                                        <h2>Release Notes</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="container">
                        <h1>October 23</h1>
                        <ul>
                            <li> Hamburger menu post login doesn't work on mobile devices.</li>
                                
                            <li> Company Profile Edit pop-up is populating wrong value in to Address line 1 field.
                                This is view Company Profile Page</li>
                                
                                <li> Update Privacy Policy and Terms and conditions to the content from attached files.</li>
                                
                                <li> Entire application deployment done : www.dotstow.com</li>
                        </ul>
                        <h1>November 11</h1>
                        <ul>
                        
                            <li>TO Delete - FE need to handle scenario, BE side, Soft delete.</li> 
                            <li>Change of expiry date .</li> 
                            <li>Adding Supervisor role - Tomorrow we will add role to DB .</li> 
                        
                       </ul>
                        
                        <h1>November 27</h1>
                        <ul>
                            Below changes are push to Production,
                            <li>Customer Inactive</li>
                            <li>Vehicle inactive</li>
                            <li>Email notifications</li>
                        </ul>
                        <h1>JAN 14 2023</h1>
                        
               
                            <ul>
                            <li>From Vehicle Details to Add Task order  Popup issue with VIN number contains special characters </li>
                            
                          
                            New Implementation:
                             Customer List:
                            <li>Active/Inactive Customers</li>
                             Customer Details:
                            <li>Active/Inactive  Vehicles</li> 
                              Vehicle Lists:
                            <li>Active/Inactive Vehicles</li> 
                            
                         
                             QA Observation points :
                            
                             
                            
                            <li>While editing task order based on Vehicle type Wet Test certificate hide and show (DT-749)</li>
                            <li>Modal popup is closing while click on out side of the popup</li>
                            <li>Garage-Vehicle list tab-when we paste the vin/unit number Blank screen is displaying</li>
                            <li>Fleet-Dashboard-when user clicks on document tile navigate to appropriate page with document tab preselected.</li>
                            <li>Garage - Login as a Technician - From dashboard when we select the inspection order, not navigating to inspection screen.</li>
                            <li>Garage -  Login as a Technician - Task order list is not displaying.</li>
                            
                             
                            
                             Front changes based on new functionality implementation:
                             Garage changes:
                            <li>IsActive parameter updated in request object in below listed services:</li>
                            <li>Inspection Orders</li>
                            <li>Inspection To Be Approved</li>
                            <li>Upcoming And Overdue</li>
                            <li>Open Task Order Lists</li>
                            <li>Customer Lists</li>
                            <li>Vehicle Lists</li>
                            <li>Document Lists</li>
                            <li>User Lists</li>
                            
                            
                            Fleet changes :
                            
                            <li>Vehicle Lists:</li>
                            <li>a. Active/Inactive Vehicles</li>
                            
                             
                            
                            IsActive parameter updated in request object in below listed services:
                            <li>Overdue</li>
                            <li>Upcoming</li>
                            <li>Inprocess</li>
                            <li>Completed</li>
                            <li>Vehicle Lists</li>
                            <li>Document Lists</li>
                            <li>ager or owner shall have the ability to cancel an Inspection Order
                         
                        An inspection order may be cancelled anytime before it is fully completed (All of the inspections ordered are completed).
                        When an inspection is cancelled, change the status in fleet module, change the status of the Inspection Order on garage side (if the garage is in the system) and send an email to garage.</li>
                         
                        Edit Inspection Order: 
                         
                        <li>Editable – WO Number, Assigned Date, Notes and list of inspections.
                        Not Editable – Vendor Name (they will have to cancel the existing order if they want to assign it to another garage).</li>
                         
                       <li>Fleet Side Vehicle detail page. - Back-End & Front-End (Need to send inprocess inspection list throw vehicle details API Call)
                        Remove individual resolve buttons against each document.
                        Change “Resolve All” to “Resolve”.
                        Show all upcoming / overdue documents on white background box along with actions. (currently there is a separate box for each type and an additional one for resolve all)
                        Show the following information in the notification area, with an edit button if an inspection order is created.</li>
                         
                         
                        <li>The vehicle shall continue to be listed in Upcoming/Overdue list until all the inspections are completed. </li>
                         
                        <li>Show count of vehicles in the dashboard under Upcoming, Overdue, In process and Completed. (instead of number of documents or inspections). </li>
                         
                        <li>When a user clicks on any of the Overdue, Upcoming or In process tiles on dashboard, redirect to Reminders page – Overdue/Upcoming tab.</li>
                         
                        <li>Change “Garage Lists” on the left navigation to “Vendors”</li>
                         
                        <li>I am thinking of the following logic to manage the list of Documents/Inspections for each vehicle in the Reminders page. I am open to any suggestions to improve or cover any missing scenarios.</li> 
                         
                        Show list of documents (type and expiry date). For status, loop through list of inspections in the inspection order for this vehicle. If a matching type exists, Show as an “in process” otherwise show “Overdue” or upcoming based on the expiry date.
                        Then make a second loop through list of inspections. If a document exist in the above list, skip the row. Otherwise add a row with status “In process”. This adds rows of inspections that are added to inspections without a document expiring.
                         
                        <li>Upcoming/Overdue list in Dashboard: Change the list presentation to be similar to Reminders page, listing 5 vehicles.</li>
                         
                        <li>Reminders Page. 2nd Page through pagination control. Showing list of vehicles, but no upcoming/overdue data. Why is this empty?</li>
                        </ul>
                        <h1>JAN 31 2023</h1>
                        <ul>
                           
                            <h1>Garage changes :</h1>

 

                            <li>Remove checkbox “Modified Method 27” from the leakage test.</li>
                            <li>Annual Vehicle Inspection. Section 1. Brake à Item g.
                            Change spelling of Value to Valve.</li>

                            <li>The time zone on document now will show the system time.</li>
                            <li>OHIO EPA inspection- Every time I fill out the form, the highlighted number ALWAYS changes to 6 no matter what number I type.
                            Plugin updated for upload image cropping function.</li>
                            

                            

                            <h1>Fleet changes:</h1>

                            <li>

                            Fleet- Upcoming / Overdue dashboard tile count mismatch</li>
                            <li>Owner / Manager : Added active / inactive functionality.

                                            Checkbox for filtering inactive user added.</li>

                                            <li>Task order status will update based in Inspection status</li>
                                            <li>In key clock screen autofocus of cursor in password field </li>
                            

                            


                            <h1>Super Admin:</h1>

                            <li>Added Active / Inactive functionality at user level</li>

                            <li>Checkbox for filtering Inactive user list added</li>
                            <h1>FEB 10 2023</h1>
                           <p> New Enhancements :

"Reply To" shall be set to the Garage or Fleet user email address who are initiating the action.
Session timeout implemented in garage and fleet side.
 

Client Observations :

Garage : Show desk number in client list screen
 

QA Observations :

User privileges-role as owner/manager should user cannot able to make themselves as inactive after logged in
Garage & fleet : Add condition to check , “expiry date” should be greater than “Created date”.
Garage  :Inspections-Annual Vehicle/External visual inspection-When we select next button in inspection, scroll the page, so that section name is visible
Fleet- Driver view-show license info in driver view mode
 

Production Observation:

Production Fleet-Displaying duplicate inspection types in Edit Work order Pop-up.
 

Super Admin :

While adding client in super admin check duplicate client name</p>

<h1>FEB 22 2023</h1>
<p>Introduce a new status to designate the "cancelled and removed" state of IO. We can term this as "Deleted".Add a service to change status to Deleted. This shall only change to Deleted if it is currently in Cancelled status. It shall through an exception back if it is not in Cancelled state. Front-end shall show appropriate message to end users ("Ex. Active orders can not be deleted unless cancelled by customer.").The default view will show the cancelled IOs until they are removed by the user action.Update the services that are listing the inspection orders to consider the specific statuses depending on the context.  Default view or Dashboard or using filters, etc.</p>
<h1>FEB 28 2023</h1>
<p>1. Added Distribute button in vehicle-document section for emailing document to users.When button selected opening pop-up where user can enter multiple emails.
2. Enable Distribute button when document are selected3. Added Sentence in distribute pop-up "Please enter one or more emails to send the documents to"</p>
<h1>May 8 2023</h1>
<p>1. Simply call us number added in contactus Form</p>


                        </ul>
                 
                    </div>
                </section>

                <footer class="footer_wrap">
                    <div class="container">
                        <div class="row d-flex justify-content-between">
                            <div class="col-sm-12 col-md-6">
                                <div class="copyrights">
                                    <p>© {(new Date().getFullYear())} DotStow. All rights reserved. <a href="/privacy">Privacy Policy</a>  <a href="/terms">Terms & Conditions</a></p>
                                </div>
                            </div>
                            <div class="col-sm-auto col-md-auto ">
                                <div class="social_icons">
                                    <a href="#" class="svg_icon">
                                        <svg height="20" viewBox="0 0 9.327 20.073">
                                            <path d="M27.309,12.6H24.133V10.512a.848.848,0,0,1,.884-.965h2.242V6.108L24.171,6.1A3.909,3.909,0,0,0,19.964,10.3V12.6H17.982V16.14h1.982V26.169h4.169V16.14h2.813Z" transform="translate(-17.982 -6.096)" />
                                        </svg>
                                    </a>
                                    <a href="#" class="svg_icon">
                                        <svg width="20" viewBox="0 0 24.7 20.073">
                                            <path d="M28.274,12.7a10.083,10.083,0,0,1-2.911.8,5.077,5.077,0,0,0,2.228-2.8,10.144,10.144,0,0,1-3.218,1.23,5.072,5.072,0,0,0-8.635,4.622A14.388,14.388,0,0,1,5.294,11.254a5.074,5.074,0,0,0,1.568,6.766,5.053,5.053,0,0,1-2.3-.634c0,.021,0,.043,0,.064a5.072,5.072,0,0,0,4.065,4.969A5.1,5.1,0,0,1,7.3,22.6a5.021,5.021,0,0,1-.954-.091,5.073,5.073,0,0,0,4.734,3.519,10.226,10.226,0,0,1-7.5,2.1A14.412,14.412,0,0,0,25.76,15.981q0-.329-.014-.656A10.264,10.264,0,0,0,28.274,12.7Z" transform="translate(-3.574 -10.326)" />
                                        </svg>
                                    </a>
                                    <a href="#" class="svg_icon">
                                        <svg width="20" viewBox="0 0 20.073 20.073">
                                            <path data-name="Path 72" d="M20.58,16.7a5.18,5.18,0,1,0,5.18,5.18A5.19,5.19,0,0,0,20.58,16.7Zm0,8.5A3.319,3.319,0,1,1,23.9,21.88,3.334,3.334,0,0,1,20.58,25.2Z" transform="translate(-10.544 -11.884)" />
                                            <circle data-name="Ellipse 18" cx="1.174" cy="1.174" r="1.174" transform="translate(14.245 3.521)" />
                                            <path data-name="Path 73" d="M21.854,6.459A5.764,5.764,0,0,0,17.6,4.8H9.268A5.544,5.544,0,0,0,3.4,10.668v8.3a5.823,5.823,0,0,0,1.7,4.33,5.906,5.906,0,0,0,4.209,1.578h8.256a5.976,5.976,0,0,0,4.249-1.578A5.794,5.794,0,0,0,23.473,19V10.668A5.8,5.8,0,0,0,21.854,6.459ZM21.692,19a3.954,3.954,0,0,1-1.174,2.954,4.183,4.183,0,0,1-2.954,1.052H9.309a4.183,4.183,0,0,1-2.954-1.052,4.068,4.068,0,0,1-1.093-2.995v-8.3A4.035,4.035,0,0,1,6.354,7.714,4.115,4.115,0,0,1,9.309,6.662h8.337A4.035,4.035,0,0,1,20.6,7.754a4.176,4.176,0,0,1,1.093,2.914V19Z" transform="translate(-3.4 -4.8)" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>
            </div>
        </>)
}

Releases.propTypes = {};

Releases.defaultProps = {};

export default Releases;